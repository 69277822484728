const ENTER_KEY = 'Enter';

const onEnter = (callback: Function) => {
  return (event: React.KeyboardEvent) => {
    if (event.key === ENTER_KEY) {
      callback();
    }
  };
};

export default onEnter;
