import { useState } from 'react';

import { ReactComponent as Facebook } from '../../../../../../assets/facebook.svg';
import { ReactComponent as Linkedin } from '../../../../../../assets/linkedin.svg';
import { ReactComponent as ShareIcon } from '../../assets/share.svg';
import { ReactComponent as Twitter } from '../../../../../../assets/twitter.svg';

import { Tour } from '../../../../../../types';
import { useClipboard } from '../../../../../../hooks/useClipboard/useClipboard';
import useShareLinks from '../../../../../../hooks/useShareLinks/useShareLinks';

import styles from './ShareMenuContent.module.scss';

interface ShareMenuContentProps {
  tour: Tour;
}

export default function ShareMenuContent({ tour }: ShareMenuContentProps) {
  const [shareLinkMessage, setShareLinkMessage] = useState('Copy');
  const { encodedShareLink, shareLink } = useShareLinks(tour);
  const { isClipboardEnabled, writeTextToClipboard } = useClipboard();

  const copyToClipboard = () => {
    writeTextToClipboard?.(shareLink).then(() => {
      setShareLinkMessage('Copied');

      setTimeout(() => {
        setShareLinkMessage('Copy');
      }, 3000);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{tour.title}</div>
      <div className={styles.shareTitle}>{tour.share.title}</div>
      <div className={styles.socialMediaButtons}>
        {tour.share.button.facebook ? (
          <a
            aria-label="Share tour on Facebook"
            className={styles.socialMediaButton}
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodedShareLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.icon}>
              <Facebook />
            </div>
            <span>Facebook</span>
          </a>
        ) : null}
        {tour.share.button.linkedIn ? (
          <a
            aria-label="Share tour on LinkedIn"
            className={styles.socialMediaButton}
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedShareLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.icon}>
              <Linkedin />
            </div>
            <span>LinkedIn</span>
          </a>
        ) : null}
        {tour.share.button.twitter ? (
          <a
            aria-label="Share tour on Twitter"
            className={styles.socialMediaButton}
            href={`https://twitter.com/intent/tweet?url=${encodedShareLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.icon}>
              <Twitter />
            </div>
            <span>Twitter</span>
          </a>
        ) : null}
      </div>
      <div className={styles.copyLink}>
        <div className={styles.shareIcon}>
          <ShareIcon />
        </div>
        <div className={styles.shareLink}>{shareLink}</div>
        {isClipboardEnabled && (
          <button
            className={styles.button}
            onClick={copyToClipboard}
            title={shareLinkMessage}
          >
            {shareLinkMessage}
          </button>
        )}
      </div>
    </div>
  );
}
