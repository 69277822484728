import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './ControlGroup.module.scss';
import { ReactComponent as Up } from './assets/up.svg';
import { ReactComponent as Down } from './assets/down.svg';
import { useSpring } from '@react-spring/web';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import useIsMobile from '../../hooks/useIsMobile/useIsMobile';
import onEnter from '../../utils/onEnter/onEnter';

export interface Control {
  display: ReactNode;
  onClick(): void;
}

interface ControlsProps {
  controls: Control[];
  className?: string;
  controlClassName?: string;
  dataCy?: string;
  style?: CSSProperties;
  minHeight?: number;
  maxHeight?: number;
  padding?: number;
  id?: string;
}

const CONTROL_DESKTOP_SIZE = 55;
const SCROLLING_SPARE_SPACE = CONTROL_DESKTOP_SIZE + 15;
const SCROLLING_ENDS_TOLERANCE = 10;

export default function ControlGroup({
  controls,
  className = '',
  controlClassName = '',
  dataCy,
  style,
  minHeight,
  maxHeight,
  padding,
  id,
}: ControlsProps) {
  const [, rerender] = useState({});
  const ref = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const isMobile = useIsMobile();

  // Some calculations need to be redone when DOM elements will change, but not trigger React re-render
  useEffect(() => {
    rerender({});
  }, [windowSize]);

  const [scrollPosition, setScrollPosition] = useSpring(() => ({
    from: { y: ref?.current?.scrollTop || 0 },
    onChange: ({ value: { y } }) => {
      if (!ref || !ref.current) return;
      ref.current.scrollTop = y;
    },
  }));

  const { top, bottom, left } = useMemo(() => {
    if (!ref || !ref.current) return { top: 0, bottom: 0, left: 0 };
    const { left, bottom, top } = ref.current.getBoundingClientRect();
    return { top, bottom, left };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, windowSize]);

  const isScrollable = useMemo<boolean>(() => {
    if (!ref || !ref.current) return false;

    const height = ref.current.clientHeight;
    const contentHeight = ref.current.scrollHeight;

    return contentHeight > height;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, windowSize]);

  const visiblePartHeight = ref?.current
    ? Math.max(
        ref.current.clientHeight - SCROLLING_SPARE_SPACE,
        CONTROL_DESKTOP_SIZE
      )
    : 0;

  const onUpClick = () => {
    if (!ref || !ref.current) return;

    const targetY =
      scrollPosition.y.goal - visiblePartHeight < SCROLLING_ENDS_TOLERANCE
        ? 0
        : scrollPosition.y.goal - visiblePartHeight;

    setScrollPosition({ y: targetY });
    rerender({});
  };

  const onDownClick = () => {
    if (!ref || !ref.current) return;
    const targetY =
      scrollPosition.y.goal + visiblePartHeight <
      ref.current.scrollHeight -
        ref?.current?.offsetHeight -
        SCROLLING_ENDS_TOLERANCE
        ? scrollPosition.y.goal + visiblePartHeight
        : ref.current.scrollHeight - ref?.current?.offsetHeight;

    setScrollPosition({ y: targetY });
    rerender({});
  };

  const isScrollTop = scrollPosition.y.goal === 0;
  const isScrollDown =
    ref?.current &&
    ref.current.scrollHeight -
      scrollPosition.y.goal -
      ref.current.clientHeight <
      SCROLLING_ENDS_TOLERANCE;

  return (
    <div
      className={`${styles.container} ${
        isScrollable ? styles.scrollable : ''
      } ${className}`}
      ref={ref}
      style={{
        ...style,
        minHeight,
        maxHeight,
      }}
      id={id}
    >
      {isScrollable ? (
        <span
          className={`${styles.arrow} ${styles.arrowUp} ${
            !isScrollTop ? styles.arrowVisible : ''
          }`}
          onClick={onUpClick}
          style={{ left, top }}
          data-cy="menu-buttons-scroll-up"
        >
          <Up />
        </span>
      ) : null}
      {controls.map(({ onClick, display }, idx) => (
        <div
          role="button"
          className={`${styles.control} ${controlClassName}`}
          key={idx}
          onClick={onClick}
          onKeyDown={onEnter(onClick)}
          data-cy={dataCy}
          tabIndex={0}
        >
          {display}
        </div>
      ))}
      {isScrollable ? (
        <span
          className={`${styles.arrow} ${styles.arrowDown} ${
            !isScrollDown ? styles.arrowVisible : ''
          }`}
          onClick={onDownClick}
          style={{ left, top: bottom, transform: 'translateY(-100%)' }}
          data-cy="menu-buttons-scroll-down"
        >
          <Down />
        </span>
      ) : null}
    </div>
  );
}
