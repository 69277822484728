const FIRST_LETTER_INDEX = 0;
const REST_OF_LETTERS_INDEX = 1;

export const capitalizeWords = (text: string = '') =>
  text
    .split(' ')
    .map((word) => {
      return `${word[FIRST_LETTER_INDEX].toUpperCase()}${word.slice(
        REST_OF_LETTERS_INDEX
      )}`;
    })
    .join(' ');
