import { AnalyticsEventName } from '../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import { isIframed } from '../isIframed/isIframed';

interface CrossFrameEvent {
  fnc: 'fireEvent';
  param: {
    eventName: AnalyticsEventName;
    data: {
      tour: {
        title: string;
        id: string;
      };
      label: string;
    };
    gaEventParams: GAEventParams;
  };
}

export interface CommonGAEventParams {
  origin: 'TourBuilder';
  source: 'direct' | 'website' | string;
  source_id: string;
  tour_id?: string;
  tour_title?: string;
}

export interface GAEventParams extends CommonGAEventParams {
  [customParams: string]: any;
}

interface CrossFrameGoogleAnalyticsEvent {
  fnc: 'GA';
  param: {
    eventName: string;
    eventParams: GAEventParams;
  };
}

const crossFrameEvent = (
  event: CrossFrameEvent | CrossFrameGoogleAnalyticsEvent
) => {
  if (!isIframed()) {
    return;
  }

  try {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(JSON.stringify(event), document.referrer);
  } catch (err) {
    console.error(err);
  }
};

export default crossFrameEvent;
