import { useMemo } from 'react';

import useURLParam, {
  HIDE_BUTTONS_SEPARATOR,
  HIDE_TABS_SEPARATOR,
  OverlayURLParam,
} from '../useURLParam/useURLParam';
import { Logo, Tour } from '../../types';
import { Theme } from '../../types/theme';

import lcpLogo from './assets/lcpLogo.png';
import { ViewerURLParam } from '../../Viewer/types/ViewerURLParam';

const TABS = ['pano', 'image', 'video'] as const;

export type TabType = typeof TABS[number];

export default function useTourFunctionality(tour: Tour, theme: Theme) {
  const admin = useURLParam(OverlayURLParam.ADMIN);
  const hideButtons = useURLParam(OverlayURLParam.HIDE_BUTTONS);
  const hideFullScreen = useURLParam(OverlayURLParam.HIDE_FULL_SCREEN);
  const hideCarousel = useURLParam(OverlayURLParam.HIDE_CAROUSEL);
  const carouselIndex = useURLParam(ViewerURLParam.CAROUSEL_INDEX);

  const hideTabs = useURLParam(OverlayURLParam.HIDE_TABS);

  let availableButtons = useMemo(() => {
    const hiddenButtons = hideButtons.split(HIDE_BUTTONS_SEPARATOR);

    return (
      tour.menuButtons
        .filter((menuButton) => menuButton.show)
        .filter((menuButton) => !hiddenButtons.includes(menuButton.id)) ?? []
    );
  }, [hideButtons, tour.menuButtons]);

  const availableTabs = useMemo(() => {
    const hiddenTabs = hideTabs.split(HIDE_TABS_SEPARATOR);

    return TABS.filter((tab) => !hiddenTabs.includes(tab));
  }, [hideTabs]);

  const fullscreenButton = availableButtons.find(
    (menuButton) => menuButton.type === 'fullscreen'
  );

  if (fullscreenButton && hideFullScreen) {
    const fullscreenButtonIndex = availableButtons.indexOf(fullscreenButton);

    availableButtons.splice(fullscreenButtonIndex, 1);
  }

  const infoButton = availableButtons.find(
    (menuButton) => menuButton.id === 'info' && menuButton.show
  );

  // For unit theme, must have floorplan or maxRent or bed details for info button to show
  if (
    infoButton &&
    theme.template === 'tb-unit-default' &&
    !tour.floorplan[0] &&
    !tour.unitTour.rent?.maxRent &&
    typeof tour.unitTour.numBed !== 'number'
  ) {
    const infoButtonIndex = availableButtons.indexOf(infoButton);

    availableButtons.splice(infoButtonIndex, 1);
  }

  const logo = useMemo<Logo>(() => {
    if (theme.mainLogo) {
      return theme.mainLogo;
    }

    if (tour.logo) {
      return {
        height: '150px',
        width: 'auto',
        url: tour.logo,
      };
    }

    return {
      height: '150px',
      width: 'auto',
      url: lcpLogo,
    };
  }, [theme.mainLogo, tour.logo]);

  const analyticsEnabled =
    (process.env.NODE_ENV === 'production' || 'Cypress' in window) &&
    Boolean(tour.client?.analyticsEnabled) &&
    !Boolean(admin);

  const isCarouselEnabled =
    hideCarousel || carouselIndex || tour.carousel?.start === 'false'
      ? false
      : true;

  return useMemo(
    () => ({
      analyticsEnabled,
      availableButtons,
      availableTabs,
      hasFullscreenButton: Boolean(fullscreenButton),
      isCarouselEnabled,
      logo,
    }),
    [
      analyticsEnabled,
      availableButtons,
      availableTabs,
      fullscreenButton,
      isCarouselEnabled,
      logo,
    ]
  );
}
