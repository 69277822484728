import { Theme } from './theme';
import { Carousel, FixedSizeImageSource } from './carousel';

declare global {
  interface Window {
    theme: Theme;
    tour: Tour;
    viewer?: Panoskin.ViewerInstance;
  }
}

export interface FloorPlanHotSpot {
  offset: number;
  panoid: string;
  x?: number;
  y?: number;
}

export interface FloorPlan {
  hotspots: FloorPlanHotSpot[];
  id: number;
  image?: {
    url: string;
    bucket: string;
    height: string;
    width: string;
  };
  img?: string;
  offset: number;
  title: string;
}

export interface StartScreen {
  show: boolean;
  campusMap: {
    show: boolean;
    url: string;
  };
  introVideo: {
    campus: boolean;
    show: boolean;
    startVideoImage: string;
    video: string;
  };
  standard: {
    campusMapUrl: string;
    fullScreenButton: boolean;
    show: boolean;
    showCampusMapOnStart: boolean;
    showLogo: boolean;
    viewTourButton: boolean;
    welcomeMessage: string;
  };
}

export interface Logo {
  height: string;
  url: string;
  width: string;
}

export interface Slider {
  offset: number;
  panoid: string;
  sliderPanoid: string;
}

export interface TourHotspot
  extends Omit<
    Panoskin.Hotspot,
    | 'backgroundColor'
    | 'color'
    | 'fontSize'
    | 'hideTextBubble'
    | 'iconSize'
    | 'name'
    | 'onclick'
    | 'radius'
    | 'sceneID'
  > {
  backgroundColor?: string;
  color?: string;
  desc?: string;
  iconSize?: number;
  image?: FixedSizeImageSource;
  panoid?: string;
  radius?: number;
  sceneId: string;
  tooltipLabel?: string;
  title?: string;
  type: 'audio' | 'elevator-dwn' | 'elevator-up' | 'info' | 'url' | 'video';
  url?: string;
}

export interface TourLabel extends Omit<Panoskin.Label, 'iconSize'> {
  iconSize: number;
}

export interface TourPolygon extends Omit<Panoskin.Polygon, 'onclick'> {}

export type EditType =
  | 'campus'
  | 'companyContact'
  | 'contact'
  | 'extra'
  | 'fullscreen'
  | 'info'
  | 'list'
  | 'map'
  | 'share'
  | 'stacking';

export type MenuItemType =
  | 'full'
  | 'blank'
  | 'extended'
  | 'dialog'
  | 'fullscreen'
  | 'iframe';

export interface CallToAction {
  text: string;
  link: string;
}

interface InfoImage {
  bucket: string;
  height: string;
  url: string;
  width: string;
}

export interface Info {
  phone: string;
  description: string;
  address: {
    name: string;
    streetAddress: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
  };
  website?: string;
  image?: InfoImage;
}

export interface Share {
  link: string;
  useCustomUrl: boolean;
  title: string;
  button: {
    twitter: boolean;
    googlePlus: boolean;
    linkedIn: boolean;
    facebook: boolean;
  };
}

type ContactFieldType = 'call' | 'directions';

interface ContactField {
  show: boolean;
  text: ContactFieldType;
  conversion: boolean;
}

export interface Contact {
  directions: ContactField;
  call: ContactField;
}

export interface MenuButton {
  canDelete: boolean;
  conversion: boolean;
  editType: EditType;
  icon: string;
  id: string;
  show: boolean;
  text: string;
  type: MenuItemType;
  url: string;
}

interface TourNavLinkLinks {
  heading: number;
  id: string;
  image?: string;
  mode?: Panoskin.ArrowMode;
  pano: string;
  y?: number;
}

interface TourNavLink {
  _id: string;
  links: TourNavLinkLinks[];
}

interface SceneProperties {
  url: string;
  height: number;
  width: number;
}

interface TourIslandScene {
  _id: string;
  google: {
    connections: {
      target: {
        id: string;
      };
    }[];
    photoId?: string;
  };
  links?: {
    description: string;
    heading: number;
    id: string;
    pano: string;
  }[];
  res?: {
    dir: string;
    h: number;
    t: string;
    ts: number;
    type: string;
    w: number;
  }[];
  s3?: {
    bucket: string;
    thumb: SceneProperties;
    preview: SceneProperties;
    full: SceneProperties;
  };
}

interface TourIsland {
  name: string;
  scenes: TourIslandScene[];
}

export type UnitOfArea = 'm2' | 'ft2';

export enum UnitOfAreaNames {
  m2 = 'm2',
  ft2 = 'sq ft',
}

type UnitSize = {
  num: number | null;
  unitOfArea: UnitOfArea;
};

export interface UnitTour {
  active: boolean;
  buildingName?: string;
  floorPlanType: string;
  numBath?: number;
  numBed?: number;
  parentTour?: string;
  rent?: {
    maxRent: string;
    minRent: string;
    url: string;
  };
  tour?: string;
  unitNumber?: string;
  unitSize?: UnitSize;
}

export type MapType = 'streets' | 'light' | 'dark';

export interface Tour {
  _id: string;
  autoRotate: boolean;
  autoRotateMode?: Panoskin.RotateMode;
  carousel: Carousel;
  client: {
    _id: string;
    analyticsEnabled: boolean;
  };
  contact: Contact;
  floorplan: FloorPlan[];
  hotspots: TourHotspot[];
  info: Info;
  labels: TourLabel[];
  loc: [number, number];
  logo?: string;
  navLinks?: TourNavLink[];
  map: {
    type: MapType;
    zoom: number;
  };
  menuButtons: MenuButton[];
  navigationArrows: 'arrow' | 'decagon' | 'google' | 'halo';
  navigationArrowsMode: Panoskin.ArrowMode;
  ownerClientId?: string;
  place: {
    cid: string;
    latitude: number;
    longitude: number;
    placeId: string;
    types: string[];
    url: string;
  };
  polygons: TourPolygon[];
  poweredByLCP360: boolean;
  share: Share;
  sliders: Slider[];
  startScreen: StartScreen;
  streetViewTour: {
    _id: string;
    islands: TourIsland[];
  };
  themeId: string;
  tourSuspended: boolean;
  tourType: 'property' | 'unit' | 'amenity';
  title: string;
  unitTour: UnitTour;
  overlayVersion: number;
  viewerType: 'custom' | 'google';
}

export type PillVariant = 'scene' | 'carousel' | 'pro' | 'legacy';

export type ArrowVariant = 'bottom' | 'side-mobile' | 'side' | 'tb-pro';
