import React, { CSSProperties } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useSwipeable } from 'react-swipeable';

import { Video } from '../../../../../../types/carousel';
import useIsMobile from '../../../../../../hooks/useIsMobile/useIsMobile';
import MediaArrow from '../../../../../../components/MediaArrow/MediaArrow';

import styles from './VideoContent.module.scss';
interface VideoComponentProps {
  config: { source: Video; idx: number };
  tourId: string;
  cssVariables?: CSSProperties;
  showArrows: boolean;
  onNavigateLeft?: () => void;
  onNavigateRight?: () => void;
}

export default function VideoComponent({
  config: { source },
  tourId,
  cssVariables,
  showArrows,
  onNavigateLeft,
  onNavigateRight,
}: VideoComponentProps) {
  const TOUR_ID_PLACEHOLDER = ':tourId';
  const VIDEO_ID_PLACEHOLDER = ':videoId';

  const TourBuilderClipUrl = `https://stream.tourbuilder.cloud/clips/${TOUR_ID_PLACEHOLDER}/${VIDEO_ID_PLACEHOLDER}/master.m3u8`;
  const isMobile = useIsMobile();
  const swipeHandlers = useSwipeable({
    onSwipedRight: isMobile && onNavigateLeft ? onNavigateLeft : undefined,
    onSwipedLeft: isMobile && onNavigateRight ? onNavigateRight : undefined,
  });

  const url =
    source.type === 'tourbuilder'
      ? TourBuilderClipUrl.replace(TOUR_ID_PLACEHOLDER, tourId).replace(
          VIDEO_ID_PLACEHOLDER,
          source.videoId
        )
      : source.url;

  return (
    <div className={styles.container} style={cssVariables} {...swipeHandlers}>
      {onNavigateLeft && showArrows ? (
        <MediaArrow
          direction="left"
          arrowVariant={isMobile ? 'side-mobile' : 'side'}
          onNavigate={onNavigateLeft}
        />
      ) : null}
      <ReactPlayer controls url={url} />
      {onNavigateRight && showArrows ? (
        <MediaArrow
          direction="right"
          arrowVariant={isMobile ? 'side-mobile' : 'side'}
          onNavigate={onNavigateRight}
        />
      ) : null}
    </div>
  );
}
