import { useCallback } from 'react';

import useViewer from '../useViewer/useViewer';

const ZOOM_MIN = 1;
const ZOOM_MAX = 5;
const ZOOM_ANIMATION_MS = 500;

export default function useZoom() {
  const { viewer } = useViewer();

  const zoomIn = useCallback(() => {
    if (!viewer) {
      return;
    }

    const pov = viewer.getPov();
    const newZoom = Math.min(pov.zoom + 1, ZOOM_MAX);

    viewer.setPov({ zoom: newZoom }, { time: ZOOM_ANIMATION_MS });
  }, [viewer]);

  const zoomOut = useCallback(() => {
    if (!viewer) {
      return;
    }

    const pov = viewer.getPov();
    const newZoom = Math.max(pov.zoom - 1, ZOOM_MIN);

    viewer.setPov({ zoom: newZoom }, { time: ZOOM_ANIMATION_MS });
  }, [viewer]);

  return {
    zoomIn,
    zoomOut,
  };
}
