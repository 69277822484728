import { animated, useSprings } from '@react-spring/web';
import { Key } from 'react';

import { makeTabID } from '../../../../constants/ids';
import hexToRGB, { HEX } from '../../../../utils/hexToRGB/hexToRGB';
import onEnter from '../../../../utils/onEnter/onEnter';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';

import styles from './TabGroup.module.scss';

export interface Colors {
  default: string;
  active: string;
  background: string;
}

export interface TabConfig<T> {
  key: T;
  icon: string;
  label: string;
}

interface TabGroupProps<T> {
  tabs: TabConfig<T>[];
  value: T;
  onChange(newValue: T): void;
  colors: Colors;
}

export default function TabGroup<T extends Key>({
  tabs,
  value,
  onChange,
  colors,
}: TabGroupProps<T>) {
  const isMobilePortrait = useIsMobile('portrait');
  const tabAnimations = useSprings(
    tabs.length,
    tabs.map((p) => ({
      color: colors.default,
      backgroundColor:
        p.key === value
          ? hexToRGB(colors.background as HEX, 0.85)
          : hexToRGB(colors.active as HEX, 0.85),
    }))
  );

  if (tabs.length === 1) return null;

  return (
    <div className={styles.container}>
      <div className={styles.active} />
      {tabs.map((t, i) => (
        <animated.div
          id={makeTabID(String(t.key))}
          style={tabAnimations[i]}
          key={t.key}
          role="button"
          className={styles.tab}
          onClick={() => onChange(t.key)}
          onKeyDown={onEnter(() => {
            onChange(t.key);
          })}
          tabIndex={0}
        >
          <span className={styles[t.icon]} />
          {isMobilePortrait && value !== t.key ? null : t.label}
        </animated.div>
      ))}
    </div>
  );
}
