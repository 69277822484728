import { useState } from 'react';

import { getControlsVisible } from '../../../../stores/slices/ui';
import { MenuItemType, Tour } from '../../../../types';
import { Theme } from '../../../../types/theme';
import { useAppSelector } from '../../../../hooks/redux';
import { useFullscreen } from '../../../../hooks/useFullscreen/useFullscreen';
import { VIEW_CONTROLS_ID } from '../../../../constants/ids';
import AutoRotate from '../../../../components/AutoRotate/AutoRotate';
import Fullscreen from './Fullscreen';
import Hamburger from '../../../../components/Hamburger/Hamburger';
import MenuButtons from './MenuButtons';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import useVerticalLayout from '../../hooks/useVerticalLayout/useVerticalLayout';
import useTourFunctionality from '../../../../hooks/useTourFunctionality/useTourFunctionality';
import Zoom from './Zoom';

import styles from './ViewControls.module.scss';

interface ViewControlsProps {
  theme: Theme;
  tour: Tour;
  onMenuButtonClick(id: string, type: MenuItemType, conversion: boolean): void;
}

export default function ViewControls({
  theme,
  tour,
  onMenuButtonClick,
}: ViewControlsProps) {
  const [autoRotateEnabled, setAutoRotateEnabled] = useState(tour.autoRotate);
  const isMobile = useIsMobile();
  const isMobileLandscape = useIsMobile('landscape');
  const { availableButtons, hasFullscreenButton } = useTourFunctionality(
    tour,
    theme
  );
  const { isFullscreenEnabled } = useFullscreen();
  const hamburgerMenuIsOpen = useAppSelector(getControlsVisible);
  const { isViewportTooLow } = useVerticalLayout(tour, true);

  return (
    <div className={styles.container}>
      <div className={styles.mobileTopContainer}>
        <Hamburger />
      </div>
      <div className={styles.menuContainer} id={VIEW_CONTROLS_ID}>
        {hamburgerMenuIsOpen &&
          availableButtons.filter((b) => b.type !== 'fullscreen').length >
            0 && (
            <MenuButtons
              tour={tour}
              menuButtons={availableButtons}
              onMenuButtonClick={onMenuButtonClick}
            />
          )}

        {(!hamburgerMenuIsOpen || !isViewportTooLow) && (
          <>
            {!isMobile ? (
              <AutoRotate
                tour={tour}
                enabled={autoRotateEnabled}
                onChange={setAutoRotateEnabled}
              />
            ) : null}
            {!isMobile && hasFullscreenButton && isFullscreenEnabled ? (
              <Fullscreen />
            ) : null}
            {!isMobileLandscape && <Zoom />}
          </>
        )}
      </div>
    </div>
  );
}
