import { useMemo } from 'react';
import L, { LatLngExpression } from 'leaflet';
import {
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from 'react-leaflet';

import { ReactComponent as Directions } from './assets/directions.svg';
import { ReactComponent as Close } from '../Modal/assets/close.svg';

import { MapType, Tour } from '../../types';
import { Theme } from '../../types/theme';
import onEscape from '../../utils/onEscape/onEscape';

import styles from './Map.module.scss';

import 'leaflet/dist/leaflet.css';

interface MapProps {
  theme: Theme;
  tour: Tour;
  onClose?: () => void;
  className?: string;
}

const CUSTOM_MARKER_ICON_WIDTH = 38;
const CUSTOM_MARKER_ICON_HEIGHT = 50;

const CUSTOM_MARKER_ICON_SVG = (fill: string = 'initial') => `
  <svg width="38" height="50" viewBox="0 0 38 50" fill="${fill}" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 0C8.6625 0 0.25 8.47083 0.25 18.8854C0.25 33.6833 17.2375 48.9625 17.9604 49.6042C18.2583 49.8687 18.6292 50 19 50C19.3708 50 19.7417 49.8688 20.0396 49.6063C20.7625 48.9625 37.75 33.6833 37.75 18.8854C37.75 8.47083 29.3375 0 19 0ZM19 29.1667C13.2562 29.1667 8.58333 24.4937 8.58333 18.75C8.58333 13.0062 13.2562 8.33333 19 8.33333C24.7437 8.33333 29.4167 13.0062 29.4167 18.75C29.4167 24.4937 24.7437 29.1667 19 29.1667Z" />
  </svg>
`;

type MapBoxType = 'dark-v10' | 'light-v10' | 'streets-v11' | 'satellite-v9';

const MAP_BOX_ATTRIBUTION =
  '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';

const mapTypeToMapBoxType = (mapType: MapType): MapBoxType => {
  switch (mapType) {
    case 'dark':
      return 'dark-v10';
    case 'light':
      return 'light-v10';
    case 'streets':
      return 'streets-v11';
    default:
      return 'light-v10';
  }
};

export default function Map({
  tour,
  theme,
  className = '',
  onClose,
}: MapProps) {
  const CustomMarkerIcon = useMemo(
    () =>
      L.divIcon({
        html: CUSTOM_MARKER_ICON_SVG(theme.styles.menu.background),
        iconSize: [CUSTOM_MARKER_ICON_WIDTH, CUSTOM_MARKER_ICON_HEIGHT * 2],
        popupAnchor: [0, -CUSTOM_MARKER_ICON_HEIGHT / 2],
        className: styles.customMarkerIcon,
      }),
    [theme]
  );

  const tileLayerUrl = `https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN}`;

  const position = [...tour.loc].reverse() as LatLngExpression;

  return (
    <MapContainer
      className={`${styles.map} ${className}`}
      center={position}
      zoom={tour.map.zoom}
    >
      {onClose ? (
        <Close
          aria-label="Close map"
          className={styles.close}
          onClick={onClose}
          onKeyDown={onClose && onEscape(onClose)}
          tabIndex={0}
        />
      ) : null}
      <Marker position={position} icon={CustomMarkerIcon}>
        <Popup>
          <h3>{tour.info.address.name}</h3>
          {tour.info.address.streetAddress}
          <br />
          {`${tour.info.address.city}, ${tour.info.address.state} ${tour.info.address.postalCode}`}
          <br />
          <a
            className={styles.directions}
            href={`https://www.google.com/maps?saddr=My+Location&daddr=${tour.place.latitude},${tour.place.longitude}&q=${tour.info.address.name}`}
            target="_blank"
            rel="noreferrer"
          >
            <Directions />
            Get Directions
          </a>
        </Popup>
      </Marker>
      <LayersControl position="topleft">
        <LayersControl.BaseLayer name="Map" checked>
          <TileLayer
            attribution={MAP_BOX_ATTRIBUTION}
            url={tileLayerUrl}
            id={mapTypeToMapBoxType(tour.map.type)}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Sat">
          <TileLayer
            attribution={MAP_BOX_ATTRIBUTION}
            url={tileLayerUrl}
            id="satellite-v9"
          />
        </LayersControl.BaseLayer>
      </LayersControl>
    </MapContainer>
  );
}
