import { useCallback, useEffect, useMemo, useState } from 'react';
import { animated, useTransition } from '@react-spring/web';

import {
  Carousel as CarouselConfig,
  CarouselItemType,
  Image as SceneImage,
  Pano as ScenePano,
  Video as SceneVideo,
} from '../../../../types/carousel';
import PillGroup, {
  PillConfig,
} from '../../../../components/PillGroup/PillGroup';
import {
  Image as MediaImage,
  showImage,
  showPano,
  showVideo,
  Video as MediaVideo,
} from '../../../../stores/slices/media';
import { setSceneListVisible } from '../../../../stores/slices/ui';
import { TABS_ID } from '../../../../constants/ids';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import styles from './MediaTypeSelector.module.scss';
import useAnalyticsEvents from '../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useTheme from '../../../../hooks/useTheme/useTheme';
import useViewer from '../../../../hooks/useViewer/useViewer';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';

import { ReactComponent as PanoIcon } from './assets/pano.svg';
import { ReactComponent as PhotoIcon } from './assets/photo.svg';
import { ReactComponent as VideoIcon } from './assets/video.svg';

interface MediaTypeSelectorProps {
  availableTabs: CarouselItemType[];
  config: CarouselConfig;
}

export default function MediaTypeSelector({
  availableTabs,
  config,
}: MediaTypeSelectorProps) {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const [pillGroupKey, setPillGroupKey] = useState<number>(0);
  const { viewer, panoId } = useViewer();

  const dispatch = useAppDispatch();

  const media = useAppSelector((s) => s.media);
  const analyticsEvents = useAnalyticsEvents();

  // Mount and unmount active pill group to recalculate its dimensions on window resize
  useEffect(() => setPillGroupKey((key) => key + 1), [windowSize]);
  const pillColors = {
    default: theme.styles.carousel.tabs.icons.default.color,
    active: theme.styles.carousel.tabs.icons.active.color,
  };

  const setPano = useCallback(
    (pano: ScenePano) => {
      dispatch(showPano());
      dispatch(setSceneListVisible(false));
      if (pano) {
        if (panoId !== pano.panoid) {
          viewer?.setPano(pano.panoid, { pov: pano?.pov });
        }
      }
    },
    [dispatch, panoId, viewer]
  );

  const setImage = useCallback(
    (image: SceneImage) => {
      if (image.image === (media as MediaImage).source) {
        return;
      }

      analyticsEvents.imageGalleryView(
        image.imageId,
        image.image.url,
        image.title
      );

      dispatch(
        showImage({
          imageId: image.imageId,
          imageTitle: image.title,
          source: image.image,
        })
      );
    },
    [analyticsEvents, dispatch, media]
  );

  const setVideo = useCallback(
    (video: SceneVideo) => {
      if ((media as MediaVideo).source === video) {
        return;
      }

      analyticsEvents.videoGalleryView(video.videoId, video.title);

      dispatch(showVideo(video));
    },
    [analyticsEvents, dispatch, media]
  );

  const pills: PillConfig<CarouselItemType>[] = useMemo(
    () =>
      [
        ...(config.pano.length > 0
          ? [
              {
                key: 'pano' as CarouselItemType,
                display: <PanoIcon />,
              },
            ]
          : []),
        ...(config.image.length > 0
          ? [
              {
                key: 'image' as CarouselItemType,
                display: <PhotoIcon />,
              },
            ]
          : []),
        ...(config.video.length > 0
          ? [
              {
                key: 'video' as CarouselItemType,
                display: <VideoIcon />,
              },
            ]
          : []),
      ].filter((pill) => availableTabs.includes(pill.key)),
    [availableTabs, config]
  );

  const pillsTransitions = useTransition(true, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <div className={styles.mediaTypeSelector}>
      {pills.length > 1 && (
        <div id={TABS_ID}>
          {pillsTransitions(
            (style, item) =>
              item && (
                <animated.div style={style}>
                  <PillGroup<CarouselItemType>
                    key={pillGroupKey}
                    colors={pillColors}
                    pills={pills}
                    value={media.type}
                    pillVariant="scene"
                    onChange={(newType) => {
                      if (newType === media.type) return;
                      switch (newType) {
                        case 'pano':
                          const [firstPano] = config.pano;
                          setPano(firstPano);
                          break;
                        case 'image':
                          const [firstImage] = config.image;
                          setImage(firstImage);
                          break;
                        case 'video':
                          const [firstVideo] = config.video;
                          setVideo(firstVideo);
                          break;
                      }
                    }}
                  />
                </animated.div>
              )
          )}
        </div>
      )}
    </div>
  );
}
