/*
    For touch mobile devices it hides all the controls for amount of time
    when user navigates through viewer with touch events
 */
import { useEffect } from 'react';
import useIsMobile from '../useIsMobile/useIsMobile';
import useViewer from '../useViewer/useViewer';
import { useAppDispatch } from '../redux';
import {
  setCarouselVisible,
  setControlsVisible,
  setSidebarVisible,
} from '../../stores/slices/ui';
import { VIEWER_CONTAINER_ID } from '../../constants';

let showControlsTimeoutId: number | null;
const SHOW_CONTROLS_AFTER_RECOIL_TIMEOUT: number = 2000;

export default function useRecoil() {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const viewer = useViewer();

  useEffect(() => {
    const viewerContainer = document.getElementById(VIEWER_CONTAINER_ID);

    if (!viewerContainer || !isMobile) return;

    function handleTouchStart() {
      if (showControlsTimeoutId) {
        window.clearTimeout(showControlsTimeoutId);
        showControlsTimeoutId = null;
        return;
      }

      dispatch(setControlsVisible(false));
      dispatch(setCarouselVisible(false));
      dispatch(setSidebarVisible(false));
    }

    function handleTouchEnd() {
      showControlsTimeoutId = window.setTimeout(() => {
        dispatch(setCarouselVisible(true));
        dispatch(setSidebarVisible(true));

        showControlsTimeoutId = null;
      }, SHOW_CONTROLS_AFTER_RECOIL_TIMEOUT);
    }

    viewerContainer.addEventListener('touchstart', handleTouchStart);
    viewerContainer.addEventListener('touchend', handleTouchEnd);

    return () => {
      viewerContainer.removeEventListener('touchstart', handleTouchStart);
      viewerContainer.removeEventListener('touchend', handleTouchEnd);
    };
  }, [viewer, isMobile, dispatch]);
}
