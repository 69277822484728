import { SELECTOR_PREFIX } from './index';

export function addPrefix(name: string): string;
export function addPrefix(
  fn: (name: string) => string
): (name: string) => string;
export function addPrefix(input: any): any {
  if (typeof input === 'function') {
    return (name: string) => SELECTOR_PREFIX + input(name);
  } else {
    return SELECTOR_PREFIX + input;
  }
}

export const TABS_ID = addPrefix('tabs');
export const makeTabID = addPrefix((key) => `tab_${key}`);

export const SIDEBAR_ID = addPrefix('sidebar');

export const CAROUSEL_ID = addPrefix('carousel');

export const HAMBURGER_ID = addPrefix('hamburger');

export const VIEW_CONTROLS_ID = addPrefix('controls');
export const makeViewControlID = addPrefix((key) => `control_${key}`);
export const FULLSCREEN_ID = makeViewControlID('fullscreen');
export const ZOOM_ID = makeViewControlID('zoom');

export const SHARE_LIVE_ID = addPrefix('share-live');

export const MENU_BUTTONS_ID = addPrefix('menu');
export const makeMenuButtonID = addPrefix((key) => `menu_${key}`);

export const STATS_ID = addPrefix('stats');
export const makeStatID = addPrefix((key) => `stats_${key}`);
