import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { ReactComponent as Close } from '../../../../../../components/Modal/assets/close.svg';
import onEnter from '../../../../../../utils/onEnter/onEnter';
import useIsMobile from '../../../../../../hooks/useIsMobile/useIsMobile';
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside/useOnClickOutside';

import { MENU_BUTTONS_ID } from '../../../../../../constants/ids';

import styles from './MenuDialog.module.scss';

interface MenuDialogProps {
  backgroundColor?: string;
  onClose: () => void;
  children: ReactNode;
}

export default function MenuDialog({
  backgroundColor,
  children,
  onClose,
}: MenuDialogProps) {
  const [menuElement, setMenuElement] = useState<HTMLDivElement>();
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    const menuDOMNode = document.getElementById(
      MENU_BUTTONS_ID
    ) as HTMLDivElement;

    if (!menuDOMNode) return;

    setMenuElement(menuDOMNode);
  }, []);

  useOnClickOutside(ref, onClose, 'tb-menu-item');

  const left = useMemo<number>(() => {
    if (!menuElement) return 0;
    return menuElement.getBoundingClientRect().left;
  }, [menuElement]);

  return (
    <div
      className={`${styles.menuDialog} ${
        isMobile ? styles.menuDialogMobile : ''
      }`}
      style={{
        backgroundColor,
        left,
      }}
      ref={ref}
    >
      {isMobile ? (
        <Close
          aria-label="Close modal"
          className={styles.close}
          onClick={onClose}
          onKeyDown={onEnter(onClose)}
          data-cy="close-modal"
          tabIndex={0}
        />
      ) : null}
      {children}
    </div>
  );
}
