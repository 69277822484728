import React from 'react';
import { Tour, MenuItemType } from '../../../../types';
import Hamburger from '../../../../components/Hamburger/Hamburger';
import ControlPanel from './ControlPanel';
import styles from './ViewControls.module.scss';
import { VIEW_CONTROLS_ID } from '../../../../constants/ids';

interface ViewControlsProps {
  tour: Tour;
  onMenuButtonClick(id: string, type: MenuItemType, conversion: boolean): void;
}

export default function ViewControls({ tour }: ViewControlsProps) {
  return (
    <div className={styles.container}>
      <>
        <div className={styles.mobileTopContainer}>
          <Hamburger />
        </div>
        <div
          className={styles.menuContainer}
          data-cy="view-controls"
          id={VIEW_CONTROLS_ID}
        >
          <ControlPanel tour={tour} />
        </div>
      </>
    </div>
  );
}
