import { useEffect } from 'react';
import { Slider } from '../../types';
import useViewer from '../useViewer/useViewer';

export default function usePanosSliders(
  sliders: Slider[]
): [typeof sliderOpacity, typeof setSliderOpacity] {
  const { viewer, sliderOpacity, setSliderOpacity, isSliderAvailable } =
    useViewer();

  useEffect(() => {
    sliders.forEach((slider) => {
      viewer?.createSlider({
        offset: slider.offset,
        sceneID: slider.panoid,
        sliderSceneID: slider.sliderPanoid,
      });
    });
  }, [sliders, viewer]);

  return [sliderOpacity, isSliderAvailable ? setSliderOpacity : undefined];
}
