import React from 'react';
import { Tour } from '../../../../types';
import styles from './ViewControls.module.scss';
import AutoRotate from '../AutoRotate/AutoRotate';
import Accessibility from '../Accessibility/Accessibility';
import {
  getAccessibilityMode,
  setAccessibilityMode,
} from '../../../../stores/slices/ui';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { VIEW_CONTROLS_ID } from '../../../../constants/ids';

interface ViewControlsProps {
  tour: Tour;
}

export default function ViewControls({ tour }: ViewControlsProps) {
  const dispatch = useAppDispatch();
  const accessibilityModeEnabled = useAppSelector(getAccessibilityMode);

  const accessibilityToggle = () => {
    dispatch(setAccessibilityMode(!accessibilityModeEnabled));
  };

  return (
    <div className={styles.container} id={VIEW_CONTROLS_ID}>
      {!accessibilityModeEnabled ? <AutoRotate tour={tour} /> : null}
      <Accessibility accessibilityToggle={accessibilityToggle} />
    </div>
  );
}
