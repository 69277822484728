import { RefObject, useEffect } from 'react';

// Checks if target element doesn't have ignored CSS class.
const isClassIgnored = (node: any, ignoreClassName?: string): any => {
  if (!ignoreClassName) return false;
  if (node.classList && node.classList.contains(ignoreClassName)) return true;
  return node.parentNode && isClassIgnored(node.parentNode, ignoreClassName);
};

const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  callback: Function,
  ignoreClass?: string
) =>
  useEffect(() => {
    const handler = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      // Gives a possibility to click outside but to ignore the event.
      // Example: opening modal button. If modal is visible, the button on click will re-open it, when it should
      // just close it.
      if (isClassIgnored(event.target, ignoreClass)) {
        return;
      }

      callback(event);
    };

    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [ref, callback, ignoreClass]);

export default useOnClickOutside;
