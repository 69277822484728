import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  getCarouselVisible,
  getSidebarVisible,
  setCarouselVisible,
  setSidebarVisible,
  setTemplateUrl,
} from '../../../../stores/slices/ui';

import styles from './Close.module.scss';

export default function Close() {
  const dispatch = useAppDispatch();
  const sidebarVisible = useAppSelector(getSidebarVisible);
  const carouselVisible = useAppSelector(getCarouselVisible);

  const toggleVisible = () => {
    dispatch(setSidebarVisible(!sidebarVisible));
    dispatch(setCarouselVisible(!carouselVisible));
    dispatch(setTemplateUrl(''));
  };

  return (
    <div
      className={styles.close}
      role="button"
      onClick={toggleVisible}
      data-cy="carousel-hamburger"
    >
      <p className={styles.hamburger}>&equiv;</p>
    </div>
  );
}
