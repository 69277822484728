import styles from './HotspotCone.module.scss';

interface HotspotConeProps {
  rotationDegrees: number;
}

const HotspotCone = ({ rotationDegrees }: HotspotConeProps) => {
  return (
    <svg
      width="55px"
      height="101px"
      viewBox="0 0 55 101"
      className={styles.hotspotCone}
      style={{
        transform: `rotate(${rotationDegrees}deg)`,
      }}
      data-cy="floor-plan-hotspot-cone"
    >
      <path d="M19.667,59.417c0,0,8.918,9.833,17.917,0l0,0L53.25,84.334l0,0C27.75,116.918,3.667,84.25,3.667,84.25l0,0L19.667,59.417z"></path>
    </svg>
  );
};

export default HotspotCone;
