import { useState } from 'react';

import { ReactComponent as Facebook } from '../../../../../../assets/facebook.svg';
import { ReactComponent as Linkedin } from '../../../../../../assets/linkedin.svg';
import { ReactComponent as Twitter } from '../../../../../../assets/twitter.svg';

import { Tour } from '../../../../../../types';
import { useClipboard } from '../../../../../../hooks/useClipboard/useClipboard';
import useShareLinks from '../../../../../../hooks/useShareLinks/useShareLinks';

import styles from './ShareMenuContent.module.scss';

interface ShareMenuContentProps {
  tour: Tour;
}

export default function ShareMenuContent({ tour }: ShareMenuContentProps) {
  const { isClipboardEnabled, writeTextToClipboard } = useClipboard();
  const { encodedShareLink, shareLink } = useShareLinks(tour);
  const [shareLinkMessage, setShareLinkMessage] = useState('Copy');

  const copyToClipboard = () => {
    writeTextToClipboard?.(shareLink).then(() => {
      setShareLinkMessage('Copied');

      setTimeout(() => {
        setShareLinkMessage('Copy');
      }, 3000);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{tour.share.title}</div>
      <div className={styles.icons}>
        {tour.share.button.facebook ? (
          <a
            aria-label="Share tour on Facebook"
            className={styles.icon}
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodedShareLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <Facebook />
          </a>
        ) : null}
        {tour.share.button.linkedIn ? (
          <a
            aria-label="Share tour on LinkedIn"
            className={styles.icon}
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedShareLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <Linkedin />
          </a>
        ) : null}
        {tour.share.button.twitter ? (
          <a
            aria-label="Share tour on Twitter"
            className={styles.icon}
            href={`https://twitter.com/intent/tweet?url=${encodedShareLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <Twitter />
          </a>
        ) : null}
      </div>
      <div className={styles.linkContainer}>
        <div className={styles.link}>{shareLink}</div>
        {isClipboardEnabled && (
          <button className={styles.button} onClick={copyToClipboard}>
            {shareLinkMessage}
          </button>
        )}
      </div>
    </div>
  );
}
