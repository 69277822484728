import { isIframed } from './../../utils/isIframed/isIframed';
import { useMemo } from 'react';
import useURLParam, { OverlayURLParam } from '../useURLParam/useURLParam';

const SOURCE_ID_FORMAT = /^\d{4}$/;
export const EMBEDDED_VISIT_ID = '1000';
export const DIRECT_VISIT_ID = '5000';

export default function useSourceId() {
  const sourceId = useURLParam(OverlayURLParam.SOURCE_ID);

  return useMemo(() => {
    if (SOURCE_ID_FORMAT.test(sourceId)) {
      return sourceId;
    }

    return isIframed() ? EMBEDDED_VISIT_ID : DIRECT_VISIT_ID;
  }, [sourceId]);
}
