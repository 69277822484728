import { useDebugValue, useMemo } from 'react';
import { ViewerURLParam } from '../../Viewer/types/ViewerURLParam';

export enum OverlayURLParam {
  ADMIN = 'admin',
  COLORS_DEBUG = 'colors_debug',
  DISPLAY = 'display',
  FAMILY = 'family',
  HIDE_BUTTONS = 'hideButtons',
  HIDE_CAROUSEL = 'hideCarousel',
  HIDE_FULL_SCREEN = 'hideFullScreen',
  HIDE_RIGHT_MENU = 'hideRightMenu',
  HIDE_SHARE_LIVE = 'hideShareLive',
  HIDE_TABS = 'hideTabs',
  ID = 'id',
  SOURCE_ID = 'sourceId',
  START_SCREEN = 'startScreen',
  THEME_DEBUG = 'theme_debug',
  TOUR = 'tour',
  TRACKING_TOKEN = 'tracking_token',
}

export const HIDE_BUTTONS_SEPARATOR = ',';
export const HIDE_TABS_SEPARATOR = ',';

export default function useURLParam(name: OverlayURLParam | ViewerURLParam) {
  const value = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const param = params.get(name);

    if (!param) return '';

    return param;
  }, [name]);

  useDebugValue(value, (v) => `${name}=${v}`);

  return value;
}
