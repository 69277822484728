import { FunctionComponent, SVGProps } from 'react';

import useURLParam, {
  OverlayURLParam,
} from '../../hooks/useURLParam/useURLParam';
import { MEET_URL } from '../../constants';
import appendParamsToUrl from '../../utils/appendParamsToUrl/appendParamsToUrl';
import useSource from '../../utils/useSource/useSource';
import useUTMParams from '../../hooks/useUTMParams/useUTMParams';

import { ReactComponent as Share } from './assets/share.svg';

import styles from './ShareLive.module.scss';
import { SHARE_LIVE_ID } from '../../constants/ids';

type SVGFunctionComponent = FunctionComponent<SVGProps<SVGSVGElement>> & {
  title?: string | undefined;
};

interface ShareLiveProps {
  clientId: string;
  themeId: string;
  tourId: string;
  className?: string;
  icon?: SVGFunctionComponent;
}

export default function ShareLive({
  clientId,
  themeId,
  tourId,
  className = '',
  icon: Icon = Share,
}: ShareLiveProps) {
  const utmParams = useUTMParams();
  const { sourceId } = useSource();
  const admin = useURLParam(OverlayURLParam.ADMIN);
  const trackingToken = useURLParam(OverlayURLParam.TRACKING_TOKEN);

  const meetingUrlWithAdditionalParams = appendParamsToUrl(
    `${MEET_URL}/${themeId}/${clientId}/${tourId}`,
    {
      ...utmParams,
      sourceId,
      admin,
      tracking_token: trackingToken,
    }
  );

  return (
    <a
      data-cy="share-live"
      href={meetingUrlWithAdditionalParams.toString()}
      className={`${styles.shareLive} ${className}`}
      target="_blank"
      title="Click here to start your virtual meeting"
      rel="noreferrer"
      id={SHARE_LIVE_ID}
    >
      <Icon className={styles.shareLiveIcon} />
      Share Live
    </a>
  );
}
