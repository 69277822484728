import { Logo, StartScreen as ScreenConfig } from '../../../../types/';
import { useFullscreen } from '../../../../hooks/useFullscreen/useFullscreen';
import AccessibilityStatement from './components/AccessibilityStatement';
import Image from '../../../../components/Image/Image';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';

import poweredByLcp from './assets/poweredByLcp.png';

import styles from './StartScreen.module.scss';

interface StartScreenProps {
  config: ScreenConfig;
  onStart(): void;
  logo: Logo;
  poweredBy: boolean;
  showAccessibilityStatement?: boolean;
}

export default function StartScreen({
  config: { show, standard },
  onStart,
  poweredBy,
  logo,
  showAccessibilityStatement,
}: StartScreenProps) {
  const isMobile = useIsMobile();
  const { isFullscreenEnabled, toggleFullscreen } = useFullscreen();

  if (!show) return null;

  return (
    <div className={styles.container} data-cy="start-screen">
      <Image
        className={styles.logo}
        src={logo.url}
        height={logo.height}
        width={logo.width}
        alt="Start Screen Logo"
      />
      {standard.show && (
        <div>
          <div
            className={styles.welcomeMessage}
            dangerouslySetInnerHTML={{ __html: standard.welcomeMessage }}
          />
          <div className={styles.buttons}>
            {standard.viewTourButton && (
              <button aria-label="Start 360° Tour" onClick={onStart}>
                Start 360&deg; Tour
              </button>
            )}
            {standard.fullScreenButton && !isMobile && isFullscreenEnabled && (
              <button
                aria-label="Start 360° Tour in Full Screen"
                onClick={() => {
                  toggleFullscreen();
                  onStart();
                }}
              >
                Full Screen
              </button>
            )}
          </div>
          {showAccessibilityStatement ? <AccessibilityStatement /> : null}
        </div>
      )}
      {poweredBy && (
        <a
          href="https://www.lcpmedia.com/tourbuilder-virtual-tours?utm_source=referral&utm_medium=poweredbylogo"
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.poweredBy}>
            <Image src={poweredByLcp} alt="Powered by LCP Media" />
          </div>
        </a>
      )}
    </div>
  );
}
