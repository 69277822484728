import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styles from './ColorsDebugger.module.scss';
import useURLParam, {
  OverlayURLParam,
} from '../../hooks/useURLParam/useURLParam';

const COLORS_DEBUGGER_HIGHLIGHT_CLASSNAME = 'colors-debugger-higlight';

const COLORS_MAP = {
  '--font-family': 'fontFamily',
  '--text-color': 'startScreen.button.color',
  '--button-background': 'startScreen.button.background',
  '--menu-background': 'menu.background',
  '--menu-button-background': 'menu.menuButton.background',
  '--menu-button-color': 'menu.menuButton.color',
  '--menu-icon-color-default': 'menu.icon.color.default',
  '--menu-icon-color-active': 'menu.icon.color.active',
  '--menu-icon-color-hover': 'menu.icon.color.hover',
  '--menu-item-background': 'menu.itemBackground',
  '--menu-item-active-background': 'menu.itemActiveBackground',
  '--carousel-background': '',
  '--carousel-tabs-background': 'carousel.tabs.background',
  '--carousel-tabs-background-active': 'carousel.tabs.backgroundActive',
  '--carousel-controls-color': 'carousel.controls.color',
  '--carousel-tabs-icon-color-active': 'carousel.tabs.icons.active.color',
  '--carousel-tabs-icon-color-default': 'carousel.tabs.icons.default.color',
  '--carousel-controls-background': 'carousel.controls.background',
  '--carousel-title-color': 'carousel.title.color',
  '--carousel-title-background': 'carousel.title.background',
  '--share-live-background': 'carousel.tabs.background',
  '--share-live-background-hover': '',
  '--stats-background': 'menu.background',
  '--stats-icon-color': 'menu.icon.color.default',
  '--pill-background': 'carousel.tabs.background',
  '--pill-background-active': '',
  '--pill-icon-inactive': 'carousel.tabs.icons',
  '--modal-background': 'modal.background',
  '--modal-color': 'modal.color',
  '--modal-icon-color': 'modal.icon.color.default',
  '--modal-icon-color-hover': 'modal.icon.color.hover',
} as const;

interface ColorsDebuggerProps {
  cssVariables: CSSProperties;
}

function highlightElementsUsingVariable(selectedOption: string) {
  document
    .querySelectorAll(`.${COLORS_DEBUGGER_HIGHLIGHT_CLASSNAME}`)
    .forEach((el) => {
      el.classList.remove(COLORS_DEBUGGER_HIGHLIGHT_CLASSNAME);
    });

  if (selectedOption === 'none') return;

  const allElements = document.querySelectorAll('*:not(#app)');

  allElements.forEach((el) => {
    if ((el as HTMLElement).style.cssText.includes(selectedOption)) {
      el.classList.add(COLORS_DEBUGGER_HIGHLIGHT_CLASSNAME);
    }
  });

  for (let sheet of Array.from(document.styleSheets)) {
    try {
      for (let rule of Array.from(sheet.cssRules)) {
        // @ts-ignore
        if (rule.style && rule.style.cssText.includes(selectedOption)) {
          // @ts-ignore
          document.querySelectorAll(rule.selectorText).forEach((el) => {
            el.classList.add(COLORS_DEBUGGER_HIGHLIGHT_CLASSNAME);
          });
        }
      }
    } catch (e) {
      console.warn('Cannot access stylesheet: ', e);
    }
  }
}

export default function ColorsDebugger({ cssVariables }: ColorsDebuggerProps) {
  const enabled = useURLParam(OverlayURLParam.COLORS_DEBUG);
  const [, rerender] = useState({});
  const selectedOption = useRef<keyof CSSProperties | 'none'>('none');

  useEffect(() => {
    const observer = new MutationObserver(() => {
      highlightElementsUsingVariable(selectedOption.current);
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    highlightElementsUsingVariable(selectedOption.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  if (!enabled) return null;

  const options = ['none', ...Object.keys(cssVariables)];

  return (
    <div className={styles.colorsDebugger}>
      <div>Colors Debugger</div>
      <select
        value={selectedOption.current}
        className={styles.select}
        onChange={(e) => {
          selectedOption.current = e.target.value as keyof CSSProperties;
          highlightElementsUsingVariable(selectedOption.current);
          rerender({});
        }}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      {selectedOption.current !== 'none' ? (
        <>
          <div>Tour styles property:</div>
          <div>
            {/* @ts-ignore */}
            <b>{COLORS_MAP[selectedOption.current]}</b>
          </div>
          <div>Value:</div>
          <div>
            <b>{cssVariables[selectedOption.current]}</b>
            <div
              className={styles.colorExample}
              style={{ background: cssVariables[selectedOption.current] }}
            ></div>
          </div>
        </>
      ) : null}
    </div>
  );
}
