import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

interface AnalyticsScriptsProps {
  googleTrackingCode?: string;
}

export default function AnalyticsScripts({
  googleTrackingCode,
}: AnalyticsScriptsProps) {
  useEffect(() => {
    if (!googleTrackingCode) {
      return;
    }

    gtag('config', googleTrackingCode);
  }, [googleTrackingCode]);

  return (
    <Helmet>
      {googleTrackingCode && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleTrackingCode}`}
        ></script>
      )}
      <script
        async
        src="https://analytics.panoskin.com/client/tourAnalytics.js"
      ></script>
    </Helmet>
  );
}
