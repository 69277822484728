import useMediaQuery, { Orientation } from '../useMediaQuery/useMediaQuery';
import { useDebugValue } from 'react';

export default function useIsMobile(orientation?: Orientation) {
  const value = useMediaQuery('sm', orientation);

  // dev tools does not show hook parameters, so we have to say the orientation ourselves
  useDebugValue(value, (v) => {
    let msg = 'screen is ';
    if (!v) {
      msg += 'not ';
    }
    if (orientation) {
      msg += 'both ';
    }
    msg += 'mobile-sized';
    if (orientation) {
      msg += ` and in ${orientation} orientation`;
    }
    return msg;
  });

  return value;
}
