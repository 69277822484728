import { useCallback } from 'react';

import { Tour } from '../../types';

export enum AnalyticsEventType {
  TOUR_VISIT = 1,
  USER_NAVIGATED_TO_PANO = 2,
  USER_CLICKED_A_BUTTON = 3,
  USER_NAVIGATED_TO_IMAGE = 4,
  USER_NAVIGATED_TO_VIDEO = 5,
}

interface AnalyticsData {
  buttonid?: string;
  clientId: string;
  eventtype: AnalyticsEventType;
  ownerClientId?: string;
  panoid?: string;
  imageid?: string;
  tourid: string;
  videoid?: string;
  sourceId?: string;
}

interface TourAnalytics {
  send(data: AnalyticsData): void;
}

declare global {
  interface Window {
    tourAnalytics: TourAnalytics;
  }
}

const useTourAnalytics = (tour: Tour) => {
  return useCallback(
    (
      data: Pick<
        AnalyticsData,
        'buttonid' | 'eventtype' | 'imageid' | 'panoid' | 'videoid' | 'sourceId'
      >
    ) => {
      window.tourAnalytics.send({
        ...data,
        clientId: tour.client._id,
        ownerClientId: tour.ownerClientId,
        tourid: tour._id,
      });
    },
    [tour]
  );
};

export default useTourAnalytics;
