import { useState } from 'react';

import { Theme } from '../../../../types/theme';
import { Tour } from '../../../../types';
import { useFullscreen } from '../../../../hooks/useFullscreen/useFullscreen';
import AutoRotate from '../../../../components/AutoRotate/AutoRotate';
import useTourFunctionality from '../../../../hooks/useTourFunctionality/useTourFunctionality';

import { ReactComponent as AutoRotatePauseIcon } from './assets/autorotate-pause.svg';
import { ReactComponent as AutoRotatePlayIcon } from './assets/autorotate-play.svg';
import { ReactComponent as FullscreenIcon } from './assets/fullscreen.svg';

import styles from './FloorPlanMobileControls.module.scss';

interface FloorPlanMobileControlsProps {
  theme: Theme;
  tour: Tour;
}

export default function FloorPlanMobileControls({
  theme,
  tour,
}: FloorPlanMobileControlsProps) {
  const [autoRotateEnabled, setAutoRotateEnabled] = useState(tour.autoRotate);
  const { hasFullscreenButton } = useTourFunctionality(tour, theme);
  const { isFullscreenEnabled, toggleFullscreen } = useFullscreen();

  return (
    <div className={styles.container}>
      <AutoRotate
        className={styles.autoRotate}
        enabled={autoRotateEnabled}
        onChange={setAutoRotateEnabled}
        tour={tour}
        playIcon={AutoRotatePlayIcon}
        pauseIcon={AutoRotatePauseIcon}
      />
      {hasFullscreenButton && isFullscreenEnabled ? (
        <FullscreenIcon
          className={styles.floorPlanMobileControl}
          onClick={() => toggleFullscreen()}
          data-cy="floor-plan-fullScreen"
        />
      ) : null}
    </div>
  );
}
