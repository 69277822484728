import { ReactComponent as ShowIcon } from './assets/show.svg';

import styles from './FloorPlanControls.module.scss';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import { VisibilityState } from '../../types';
import Slider from '../Slider/Slider';
import { animated, useSpring, useTransition } from '@react-spring/web';
import { ReactNode, useLayoutEffect, useRef, useState } from 'react';

interface FloorPlanControlsProps {
  onSliderChange?(value: number): void;
  onVisibilityChange?(visibility: VisibilityState): void;
  sliderValue?: number;
  additionalControls?: ReactNode | ReactNode[];
  mapVisibility?: VisibilityState;
}

const FLOOR_PLAN_CONTROLS_HEIGHT = 298;

const FloorPlanControls = ({
  onSliderChange,
  onVisibilityChange,
  sliderValue,
  additionalControls = null,
  mapVisibility,
}: FloorPlanControlsProps) => {
  const controlsIconsRef = useRef<HTMLDivElement>(null);
  const [controlsIconsHeight, setControlsIconsHeight] = useState<number>(0);
  const isMobile = useIsMobile();

  const spring = useSpring({
    height: !!onSliderChange ? FLOOR_PLAN_CONTROLS_HEIGHT : controlsIconsHeight,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    const newIconsHeight = controlsIconsRef.current?.clientHeight;
    if (newIconsHeight && newIconsHeight !== controlsIconsHeight)
      setControlsIconsHeight(newIconsHeight);
  });

  const transitions = useTransition(!!onSliderChange, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      tension: 300,
    },
  });

  return (
    <div className={styles.container}>
      {(onSliderChange || onVisibilityChange) && (
        <animated.div
          className={styles.floorPlanControls}
          data-cy="floor-plan-controls"
          style={spring}
        >
          <div className={styles.floorPlanControlsIcons} ref={controlsIconsRef}>
            {onVisibilityChange && (
              <ShowIcon
                className={styles.floorPlanControlsIcon}
                data-cy="floor-plan-control-toggle"
                onClick={() => {
                  onVisibilityChange(
                    mapVisibility === 'hidden' ? 'visible' : 'hidden'
                  );
                }}
              />
            )}
          </div>
          {transitions(
            (style, item) =>
              item && (
                <animated.div className={styles.sliderContainer} style={style}>
                  <Slider
                    onChange={onSliderChange!}
                    text="Slide"
                    max={1}
                    step={0.01}
                    value={sliderValue}
                    vertical
                  />
                </animated.div>
              )
          )}
        </animated.div>
      )}
      {isMobile && (
        <div className={styles.additionalFloorPlanControls}>
          {additionalControls}
        </div>
      )}
    </div>
  );
};

export default FloorPlanControls;
