import { CSSProperties, useEffect, useState, useMemo } from 'react';

import useURLParam, {
  OverlayURLParam,
} from '../../hooks/useURLParam/useURLParam';
import { MenuItemContentProvider } from './hooks/useMenuItemContent/useMenuItemContent';
import { getCarouselVisible } from '../../stores/slices/ui';
import { ThemeProps } from '../Theme';
import { useAppSelector } from '../../hooks/redux';
import { useNavigateToVideo } from '../../hooks/useNavigateToVideo/useNavigateToVideo';
import { useNavigateToPhoto } from '../../hooks/useNavigateToPhoto/useNavigateToPhoto';
import Carousel from './components/Carousel/Carousel';
import FloorPlan from '../../components/FloorPlan/FloorPlan';
import FloorPlanMobileControls from './components/FloorPlanMobileControls/FloorPlanMobileControls';
import GlobalCss from './GlobalCss';
import MediaImageControls from '../../components/MediaImageControls/MediaImageControls';
import MediaImage from '../../components/MediaImage/MediaImage';
import MediaVideo from '../../components/MediaVideo/MediaVideo';
import Pano from './components/Pano/Pano';
import ShareLive from '../../components/ShareLive/ShareLive';
import StartScreen from '../../components/StartScreen/StartScreen';
import useAnalyticsEvents from '../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useIsMobile from '../../hooks/useIsMobile/useIsMobile';
import useIsViewportTooLow from './hooks/useIsViewportTooLow/useIsViewportTooLow';
import useTourFunctionality from '../../hooks/useTourFunctionality/useTourFunctionality';
import useViewer from '../../hooks/useViewer/useViewer';
import Hamburger from './components/Hamburger/Hamburger';

export default function GreystarTheme({
  cssVariables,
  theme,
  tour,
}: ThemeProps) {
  const [showStart, setShowStart] = useState(true);
  const disableStartScreen =
    useURLParam(OverlayURLParam.START_SCREEN) === 'false';
  const analyticsEvents = useAnalyticsEvents();
  const { availableTabs, logo } = useTourFunctionality(tour, theme);
  const hideRightMenu = useURLParam(OverlayURLParam.HIDE_RIGHT_MENU) === 'true';
  const hideShareLive = useURLParam(OverlayURLParam.HIDE_SHARE_LIVE) === 'true';
  const { panoId } = useViewer();
  const media = useAppSelector((s) => s.media);
  const visible = useAppSelector(getCarouselVisible);

  useEffect(() => {
    if (!panoId || (tour.startScreen.show && !disableStartScreen)) {
      return;
    }

    analyticsEvents.tourVisit();

    switch (media.type) {
      case 'pano':
        if (panoId) {
          analyticsEvents.panoramaChanged(panoId);
        }
        break;
      case 'image':
        analyticsEvents.imageGalleryView(
          media.imageId,
          media.source.url,
          media.imageTitle
        );
        break;
      case 'video':
        analyticsEvents.videoGalleryView(
          media.source.videoId,
          media.source.title
        );
        break;
    }
  }, [analyticsEvents, disableStartScreen, media, tour, panoId]);

  const { navigateToPhoto: navigateToPhotoLeft } = useNavigateToPhoto(
    'left',
    tour.carousel.image
  );
  const { navigateToPhoto: navigateToPhotoRight } = useNavigateToPhoto(
    'right',
    tour.carousel.image
  );
  const { navigateToVideo: navigateToVideoLeft } = useNavigateToVideo(
    'left',
    tour.carousel.video
  );
  const { navigateToVideo: navigateToVideoRight } = useNavigateToVideo(
    'right',
    tour.carousel.video
  );

  const isMobile = useIsMobile();
  const isLandscape = useIsMobile('landscape');
  const { isViewportTooLow } = useIsViewportTooLow(tour);

  const floorPlanPanoIds = useMemo(() => {
    return tour.floorplan.flatMap((floorplan) =>
      floorplan.hotspots.map((hotspot) => hotspot.panoid)
    );
  }, [tour.floorplan]);

  return (
    <>
      <GlobalCss />
      {isMobile ? visible && <Hamburger /> : !visible && <Hamburger />}
      <MenuItemContentProvider>
        <div
          data-cy="tb-greystar-default-theme"
          id="theme"
          style={
            {
              '--button-border-radius': '10px',
              '--button-border-radius-small': '5px',
              '--controls-border-radius': '50px',
            } as CSSProperties
          }
        >
          {media.type === 'pano' && (
            <FloorPlan
              configs={tour.floorplan}
              sliders={tour.sliders}
              additionalControls={
                isMobile || isViewportTooLow ? (
                  <FloorPlanMobileControls
                    theme={theme}
                    tour={tour}
                    isLandscape={isLandscape || isViewportTooLow}
                  />
                ) : null
              }
            />
          )}

          <Carousel
            availableTabs={availableTabs}
            config={tour.carousel}
            initialItemType={'pano'}
            pillColors={{
              default: theme.styles.carousel.tabs.icons.default.color,
              active: theme.styles.carousel.tabs.icons.active.color,
            }}
            floorPlanPanoIds={floorPlanPanoIds}
          />

          {media.type === 'pano' && (
            <Pano hideRightMenu={hideRightMenu} tour={tour} theme={theme} />
          )}
          {media.type === 'image' && (
            <>
              <MediaImage
                key={media.source.url}
                config={media}
                showArrows={tour.carousel.image.length > 1}
                arrowVariant="side"
                onNavigateLeft={navigateToPhotoLeft}
                onNavigateRight={navigateToPhotoRight}
                cssVariables={cssVariables}
              />
              <MediaImageControls tour={tour} />
            </>
          )}

          {media.type === 'video' && (
            <MediaVideo
              arrowVariant="tb-pro"
              cssVariables={cssVariables}
              onNavigateLeft={navigateToVideoLeft}
              onNavigateRight={navigateToVideoRight}
              showArrows={tour.carousel.video.length > 1}
              source={media.source}
              tourId={tour._id}
            />
          )}

          {showStart && !disableStartScreen && (
            <StartScreen
              config={tour.startScreen}
              onStart={() => {
                analyticsEvents.tourVisit();

                switch (media.type) {
                  case 'pano':
                    if (panoId) {
                      analyticsEvents.panoramaChanged(panoId);
                    }
                    break;
                  case 'image':
                    analyticsEvents.imageGalleryView(
                      media.imageId,
                      media.source.url,
                      media.imageTitle
                    );
                    break;
                  case 'video':
                    analyticsEvents.videoGalleryView(
                      media.source.videoId,
                      media.source.title
                    );
                    break;
                }

                setShowStart(false);
              }}
              logo={logo}
              poweredBy={tour.poweredByLCP360}
              showAccessibilityStatement={true}
            />
          )}
          {!hideShareLive && !isMobile && (
            <ShareLive
              clientId={tour.client._id}
              tourId={tour._id}
              themeId={tour.themeId}
            />
          )}
        </div>
      </MenuItemContentProvider>
    </>
  );
}
