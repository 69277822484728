import { useEffect, useState } from 'react';

export default function useLocalStorage(key: string) {
  const [value, setValue] = useState(() => {
    const localStorageValue = localStorage.getItem(key);
    if (!localStorageValue) return '';

    return localStorageValue;
  });

  useEffect(() => {
    localStorage.setItem(key, value);
  }, [value, setValue, key]);

  return [value, setValue] as const;
}
