import useZoom from '../../../../hooks/useZoom/useZoom';
import ControlGroup from '../../../../components/ControlGroup/ControlGroup';
import { ZOOM_ID } from '../../../../constants/ids';

export default function Zoom() {
  const { zoomIn, zoomOut } = useZoom();

  return (
    <ControlGroup
      id={ZOOM_ID}
      dataCy="zoom-controls"
      controls={[
        { display: <span aria-label="Zoom in">+</span>, onClick: zoomIn },
        { display: <span aria-label="Zoom out">-</span>, onClick: zoomOut },
      ]}
    />
  );
}
