import { Pano } from '../../../types/carousel';
import { ViewerURLParam } from '../../types/ViewerURLParam';

const MIN_CAROUSEL_INDEX = 0;

export const getCustomCarouselIndex = (carouselPanos: Pano[]) => {
  const params = new URLSearchParams(window.location.search);

  const customCarouselIndex = params.get(ViewerURLParam.CAROUSEL_INDEX);

  return customCarouselIndex === '' ||
    customCarouselIndex === null ||
    carouselPanos.length === 0
    ? Number.NaN
    : Math.min(
        Math.max(Number(customCarouselIndex), MIN_CAROUSEL_INDEX),
        carouselPanos.length - 1
      );
};
