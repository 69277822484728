import { useCallback, useEffect, useState } from 'react';

import { Theme } from '../../types/theme';
import { TourHotspot } from '../../types';
import appendParamsToUrl from '../../utils/appendParamsToUrl/appendParamsToUrl';
import useAnalyticsEvents from '../useAnalyticsEvents/useAnalyticsEvents';
import useSource from '../../utils/useSource/useSource';
import useUTMParams from '../useUTMParams/useUTMParams';
import useViewer from '../useViewer/useViewer';

const DEFAULT_HOTSPOT_BACKGROUND_COLOR = '#000000';
const DEFAULT_HOTSPOT_COLOR = '#ffffff';
const DEFAULT_HOTSPOT_FONT_SIZE = '20px';
const DEFAULT_HOTSPOT_RADIUS = 50;

const getIcon = (
  type: TourHotspot['type']
): Panoskin.Hotspot['icon'] | undefined => {
  switch (type) {
    case 'audio':
      return 'fa-play-circle-o';
    case 'elevator-dwn':
      return 'fa-arrow-down';
    case 'elevator-up':
      return 'fa-arrow-up';
    case 'info':
      return 'fa-info';
    case 'url':
      return 'fa-link';
    case 'video':
      return 'fa-youtube-play';
  }
};

export type InfoHotspotData = { type: 'info' } & Pick<
  TourHotspot,
  'desc' | 'image' | 'title'
>;

export type VideoHotspotData = { type: 'video' } & Pick<
  TourHotspot,
  'desc' | 'video' | 'title'
>;

export default function useHotspots(
  hotspots: TourHotspot[] = [],
  styles?: Theme['styles']['hotspots']
): [InfoHotspotData | VideoHotspotData | null, () => void] {
  const { viewer, panoId } = useViewer();
  const [hotspotData, setHotspotData] = useState<
    InfoHotspotData | VideoHotspotData | null
  >(null);
  const analyticsEvents = useAnalyticsEvents();
  const utmParams = useUTMParams();
  const { sourceId } = useSource();

  useEffect(() => {
    return function cleanup() {
      if (!panoId) {
        return;
      }

      hotspots.forEach((hotspot) => {
        viewer?.deleteHotspot(panoId, hotspot.id);
      });
    };
  }, [hotspots, panoId, viewer]);

  const getCallbackForHotspot = useCallback(
    (hotspot: TourHotspot) => {
      switch (hotspot.type) {
        case 'video':
          return () => {
            setHotspotData({
              type: 'video',
              desc: hotspot.desc,
              video: hotspot.video,
              title: hotspot.title,
            });
          };
        case 'info':
          return () => {
            setHotspotData({
              type: 'info',
              desc: hotspot.desc,
              image: hotspot.image,
              title: hotspot.title,
            });
          };
        case 'url':
          return (hotspot: TourHotspot) => {
            hotspot.url &&
              window.open(
                appendParamsToUrl(hotspot.url, {
                  ...utmParams,
                  sourceId,
                }).toString(),
                '_blank',
                'noopener,noreferrer'
              );
          };
        case 'elevator-dwn':
        case 'elevator-up':
          return (hotspot: TourHotspot) => {
            hotspot.panoid && viewer?.setPano?.(hotspot.panoid);
          };

        default:
          return () => {
            console.log(`TODO: Handle ${hotspot.type} hotspot`);
          };
      }
    },
    [viewer, utmParams, sourceId]
  );

  useEffect(() => {
    if (viewer && hotspots && panoId) {
      hotspots
        .filter((hotspot) => hotspot.sceneId === panoId)
        .forEach((hotspot) => {
          const viewerHotspot: Panoskin.Hotspot = {
            backgroundColor:
              hotspot.backgroundColor ||
              styles?.backgroundColor ||
              DEFAULT_HOTSPOT_BACKGROUND_COLOR,
            color: hotspot.color || styles?.color || DEFAULT_HOTSPOT_COLOR,
            fontSize: DEFAULT_HOTSPOT_FONT_SIZE,
            heading: hotspot.heading,
            hideTextBubble: true,
            icon: hotspot.icon ? hotspot.icon : getIcon(hotspot.type),
            iconSize: hotspot.iconSize ? `${hotspot.iconSize}px` : undefined,
            id: hotspot.id,
            name: hotspot.tooltipLabel || '',
            onclick: () => {
              analyticsEvents.hotspotClick(hotspot);
              getCallbackForHotspot(hotspot)(hotspot);
            },
            pitch: hotspot.pitch,
            radius: hotspot.radius || DEFAULT_HOTSPOT_RADIUS,
            sceneID: panoId,
          };

          viewer.createHotspot(viewerHotspot);
        });
    }
  }, [
    analyticsEvents,
    getCallbackForHotspot,
    hotspots,
    panoId,
    styles,
    viewer,
  ]);

  const clearHotspotData = () => {
    setHotspotData(null);
  };

  return [hotspotData, clearHotspotData];
}
