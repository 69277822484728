import { useState } from 'react';
import { animated, useTransition } from '@react-spring/web';

import { FloorPlanHotSpot } from '../../../../types';
import { SizeState, VisibilityState } from '../../types';
import Hotspot from '../Hotspot/Hotspot';
import Image from '../../../Image/Image';

import styles from './FloorPlanMap.module.scss';

interface FloorPlanMapProps {
  currentPanoId: string;
  heading: number;
  hotspots: FloorPlanHotSpot[];
  mapImageUrl: string;
  onPanoramaSelect(panoId: string): void;
  size?: SizeState;
  visibility: VisibilityState;
}

const FloorPlanMap = ({
  currentPanoId,
  heading,
  hotspots,
  mapImageUrl,
  onPanoramaSelect,
  size = 'minimized',
  visibility,
}: FloorPlanMapProps) => {
  const [isFloorPlanLoaded, setIsFloorPlanLoaded] = useState(false);
  const transitions = useTransition(visibility === 'visible', {
    from: { opacity: 0 },
    enter: { opacity: 0.8 },
    leave: { opacity: 0 },
  });

  return transitions(
    (style, item) =>
      item && (
        <animated.div
          className={styles.floorPlanMap}
          data-cy="floor-plan-map"
          style={style}
        >
          <div className={styles[size]}>
            {mapImageUrl && (
              <Image
                alt="Floor plan"
                className={styles.mapImage}
                src={mapImageUrl}
                onLoad={() => {
                  setIsFloorPlanLoaded(true);
                }}
              />
            )}
            {isFloorPlanLoaded &&
              hotspots
                ?.filter(
                  (hotspot) =>
                    typeof hotspot.x === 'number' &&
                    typeof hotspot.y === 'number'
                )
                .sort((hotspot) => (currentPanoId === hotspot.panoid ? -1 : 1))
                .map((hotspot) => (
                  <Hotspot
                    coordinates={{ x: hotspot.x!, y: hotspot.y! }}
                    isActive={currentPanoId === hotspot.panoid}
                    onClick={() => {
                      onPanoramaSelect(hotspot.panoid);
                    }}
                    key={hotspot.panoid}
                    panoId={hotspot.panoid}
                    heading={heading}
                    offset={hotspot.offset}
                  />
                ))}
          </div>
        </animated.div>
      )
  );
};

export default FloorPlanMap;
