export default function getDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  const PI_DIVIDED_BY_180 = 0.017453292519943295;
  const COS = Math.cos;
  const EARTH_R = 6371;
  const VALUE =
    0.5 -
    COS((lat2 - lat1) * PI_DIVIDED_BY_180) / 2 +
    (COS(lat1 * PI_DIVIDED_BY_180) *
      COS(lat2 * PI_DIVIDED_BY_180) *
      (1 - COS((lon2 - lon1) * PI_DIVIDED_BY_180))) /
      2;

  return 2 * EARTH_R * Math.asin(Math.sqrt(VALUE));
}
