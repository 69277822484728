import * as DOMPurify from 'dompurify';

import { Theme } from '../../../../../../types/theme';
import { Tour } from '../../../../../../types';
import AccessibilityStatement from '../../../../../../components/StartScreen/components/AccessibilityStatement';
import Image from '../../../../../../components/Image/Image';
import useAnalyticsEvents from '../../../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useTourFunctionality from '../../../../../../hooks/useTourFunctionality/useTourFunctionality';

import styles from './InfoMenuContent.module.scss';

interface InfoMenuContentProps {
  theme: Theme;
  tour: Tour;
}

export default function InfoMenuContent({ theme, tour }: InfoMenuContentProps) {
  const { logo } = useTourFunctionality(tour, theme);
  const analyticsEvents = useAnalyticsEvents();

  const logoUrl = tour.info.image?.url || logo.url;
  const websiteUrl = tour.info.website ? new URL(tour.info.website) : null;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Image alt="Main logo" src={logoUrl} className={styles.logo} />
        <div className={styles.address}>
          <div>
            {tour.info.address.streetAddress}, {tour.info.address.city},{' '}
            {tour.info.address.state} {tour.info.address.postalCode}
          </div>
          {tour.info.website && websiteUrl ? (
            <a href={tour.info.website} target="_blank" rel="noreferrer">
              {websiteUrl.host}
            </a>
          ) : null}
          <a
            className={styles.phone}
            href={`tel:${tour.info.phone}`}
            onClick={() => {
              analyticsEvents.conversion('call', 'Call', {
                conversion_phone: tour.info.phone,
              });
            }}
          >
            {tour.info.phone}
          </a>
        </div>
        <div className={styles.accessibility}>
          <AccessibilityStatement />
        </div>
      </div>

      <div
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(tour.info.description),
        }}
      ></div>
    </div>
  );
}
