import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { getTemplateUrl, setTemplateUrl } from '../../../../stores/slices/ui';
import { MenuItemType } from '../../../../types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import appendParamsToUrl from '../../../../utils/appendParamsToUrl/appendParamsToUrl';
import ContactMenuContent from './components/ContactMenuContent/ContactMenuContent';
import hexToRGB, { HEX } from '../../../../utils/hexToRGB/hexToRGB';
import InfoMenuContent from './components/InfoMenuContent/InfoMenuContent';
import MapMenuContent from './components/MapMenuContent/MapMenuContent';
import MenuDialog from './components/MenuDialog/MenuDialog';
import ShareMenuContent from './components/ShareMenuContent/ShareMenuContent';
import TemplateDrawer from '../../../../components/TemplateDrawer/TemplateDrawer';
import useAnalyticsEvents from '../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useShareLinks from '../../../../hooks/useShareLinks/useShareLinks';
import useSource from '../../../../utils/useSource/useSource';
import useTheme from '../../../../hooks/useTheme/useTheme';
import useTour from '../../../../hooks/useTour/useTour';
import useUTMParams from '../../../../hooks/useUTMParams/useUTMParams';

import styles from './useMenuItemContent.module.scss';
import useNativeShare from '../../../../hooks/useNativeShare/useNativeShare';

interface MenuItemContentContextValue {
  openMenuItem(id: string, type: MenuItemType, conversion: boolean): void;
  openedMenuItemId: string;
}
const MenuItemContentContext = createContext<MenuItemContentContextValue>({
  openMenuItem: () => {},
  openedMenuItemId: '',
});

interface ProviderProps {
  children: ReactNode;
}

export function MenuItemContentProvider({ children }: ProviderProps) {
  const tour = useTour();
  const theme = useTheme();
  const { shareLink } = useShareLinks(tour);
  const [modalContent, setModalContent] = useState('');
  const [fullscreenUrl, setFullscreenUrl] = useState('');
  const [openedMenuItemId, setOpenedMenuItemId] = useState('');
  const analyticsEvents = useAnalyticsEvents();
  const utmParams = useUTMParams();
  const { sourceId } = useSource();
  const dispatch = useAppDispatch();
  const templateUrl = useAppSelector(getTemplateUrl);
  const share = useNativeShare();

  const openMenuItem = useCallback(
    (id: string, type: MenuItemType, conversion: boolean) => {
      const button = tour.menuButtons.find(
        (menuButton) => menuButton.id === id
      );

      const buttonText = button?.text!;

      analyticsEvents.menuClick(id, buttonText);
      if (conversion) {
        analyticsEvents.conversion(id, buttonText);
      }

      if (id && id === modalContent) {
        setOpenedMenuItemId('');
        setModalContent('');
        return;
      }

      setFullscreenUrl('');
      setModalContent('');
      setOpenedMenuItemId(id);

      if (id === 'info') {
        setModalContent('info');

        return;
      }

      if (id === 'contact') {
        setModalContent('contact');

        return;
      }

      if (id === 'map') {
        setModalContent('map');

        return;
      }

      if (id === 'share') {
        share(shareLink, () => setModalContent('share'));
        return;
      }

      if (type === 'blank') {
        const menuItem = tour.menuButtons.find(
          (menuButton) => menuButton.id === id
        );

        if (!menuItem || !menuItem.url) return;

        const menuItemUrlWithCustomParams = appendParamsToUrl(menuItem.url, {
          ...utmParams,
          sourceId,
        });

        window.open(menuItemUrlWithCustomParams.toString(), '_blank');

        return;
      }

      if (type === 'extended') {
        if (templateUrl) {
          dispatch(setTemplateUrl(''));
          setModalContent('');
          setOpenedMenuItemId('');
          return;
        }

        dispatch(setTemplateUrl(button!.url));
        setModalContent('extended');
        setOpenedMenuItemId(button?.id!);

        return;
      }

      if (type === 'iframe') {
        const menuItem = tour.menuButtons.find(
          (menuButton) => menuButton.id === id
        );
        if (!menuItem || !menuItem.url) return;

        const targetUrlWithCustomParams = appendParamsToUrl(menuItem.url, {
          ...utmParams,
          sourceId,
        });
        // Can't open iframe with URL with same origin
        if (window.location.origin === targetUrlWithCustomParams.origin) {
          return;
        }

        if (fullscreenUrl === targetUrlWithCustomParams.toString()) {
          setFullscreenUrl('');
          return setModalContent('');
        }

        setModalContent('');
        setFullscreenUrl(targetUrlWithCustomParams.toString());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      analyticsEvents,
      dispatch,
      fullscreenUrl,
      modalContent,
      sourceId,
      templateUrl,
      tour,
      utmParams,
      share,
    ]
  );

  const handleClose = () => {
    setModalContent('');
    setFullscreenUrl('');
    setOpenedMenuItemId('');
  };

  return (
    <MenuItemContentContext.Provider
      value={{
        openMenuItem,
        openedMenuItemId,
      }}
    >
      {children}
      {(fullscreenUrl || modalContent) && tour ? (
        <MenuDialog
          {...(modalContent === 'extended' && {
            backgroundColor: hexToRGB('#ffffff' as HEX, 0.85),
          })}
          onClose={handleClose}
        >
          {modalContent === 'info' ? <InfoMenuContent tour={tour} /> : null}
          {modalContent === 'contact' ? (
            <ContactMenuContent tour={tour} />
          ) : null}
          {modalContent === 'share' ? <ShareMenuContent tour={tour} /> : null}
          {modalContent === 'map' ? (
            <MapMenuContent tour={tour} theme={theme} />
          ) : null}
          {modalContent === 'extended' ? (
            <TemplateDrawer tour={tour} className={styles.templateDrawer} />
          ) : null}
          {fullscreenUrl ? (
            <iframe
              src={fullscreenUrl}
              height="400"
              width="700"
              title={fullscreenUrl}
              style={{ maxWidth: '100%' }}
            />
          ) : null}
        </MenuDialog>
      ) : null}
    </MenuItemContentContext.Provider>
  );
}

export default function useMenuItemContent(): MenuItemContentContextValue {
  return useContext(MenuItemContentContext)!;
}
