import { useEffect } from 'react';

import useViewer from '../useViewer/useViewer';
import { TourLabel } from '../../types';

export default function useLabels(labels: TourLabel[] = []) {
  const { viewer, panoId } = useViewer();

  useEffect(() => {
    if (viewer) {
      labels.forEach((label) => {
        viewer.createLabel({
          ...label,
          iconSize: `${label.iconSize}px`,
        });
      });
    }

    return function cleanup() {
      if (!panoId) {
        return;
      }

      labels.forEach((label) => {
        viewer?.deleteLabel(panoId, label.id);
      });
    };
  }, [labels, viewer, panoId]);
}
