import { FunctionComponent, useMemo, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import { animated, useTransition } from '@react-spring/web';

import { makeMenuButtonID } from '../../../../constants/ids';
import {
  Tour,
  MenuButton as MenuButtonType,
  MenuItemType,
} from '../../../../types';
import { Theme } from '../../../../types/theme';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import useMenuItemContent from '../../hooks/useMenuItemContent/useMenuItemContent';
import useTourFunctionality from '../../../../hooks/useTourFunctionality/useTourFunctionality';
import useURLParam, {
  HIDE_BUTTONS_SEPARATOR,
  OverlayURLParam,
} from '../../../../hooks/useURLParam/useURLParam';
import {
  getCarouselVisible,
  setControlsVisible,
} from '../../../../stores/slices/ui';

import { ReactComponent as Contact } from './assets/contact.svg';
import { ReactComponent as Featured } from './assets/featured.svg';
import { ReactComponent as Info } from './assets/info.svg';
import { ReactComponent as Map } from './assets/map.svg';
import { ReactComponent as Menu } from './assets/menu.svg';
import { ReactComponent as Share } from './assets/share.svg';
import { ReactComponent as ShareLive } from '../BottomBar/assets/share.svg';
import Carousel from '../Carousel/Carousel';
import CarouselMobile from '../CarouselMobile/CarouselMobile';

import styles from './BottomBar.module.scss';
import 'swiper/css';
import 'swiper/css/navigation';

export const ICONS_CONFIG: Record<string, FunctionComponent> = {
  'icon-tour-info': Info,
  'icon-book-now': Info,
  'fa-info': Info,
  'icon-contact': Contact,
  'icon-floorplan': Featured,
  'icon-share': Share,
  'fa-map-marker': Map,
  'custom-share-live': ShareLive,
  'icon-tour-menu': Menu,
};

const CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT = [
  '56c3a81809a3adea4c46c794',
  '6490e2d6f6a1ae70fc12c850',
];

const CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT_ON_DESKTOP = [
  '5eed1e0243b4eb1489d7a44e',
];

interface FontAwesomeIconProps {
  icon: string;
}

function FontAwesomeIcon({ icon }: FontAwesomeIconProps) {
  return (
    <i
      className={clsx(styles.fontAwesomeIcon, 'fa', icon)}
      aria-hidden="true"
    ></i>
  );
}

interface MenuButtonProps {
  button: MenuButtonType;
  onItemClick(id: string, type: MenuItemType, conversion: boolean): void;
  openedMenuItemId: string;
}

function MenuButton({
  button,
  onItemClick,
  openedMenuItemId,
}: MenuButtonProps) {
  const icon = button.icon;
  const Icon = ICONS_CONFIG[icon] || Info;
  const isFontAwesomeIcon =
    icon.startsWith('fa-') && icon !== 'fa-map-marker' && icon !== 'fa-info';

  return (
    <div
      key={button.id}
      className={clsx(
        styles.buttonItem,
        button.id === openedMenuItemId ? styles.menuActive : ''
      )}
      data-cy={`menu-item-${button.text}`}
      onClick={() => onItemClick(button.id, button.type, button.conversion)}
      title={button.text}
      id={makeMenuButtonID(button.id)}
    >
      {isFontAwesomeIcon ? <FontAwesomeIcon icon={icon} /> : <Icon />}
    </div>
  );
}

interface BottomBarProps {
  theme: Theme;
  tour: Tour;
}

const mediaButton: MenuButtonType = {
  id: 'menu',
  conversion: false,
  canDelete: false,
  type: 'dialog',
  editType: 'extra',
  icon: 'icon-tour-menu',
  show: true,
  text: 'Media Selector',
  url: '',
};

export default function BottomBar({ theme, tour }: BottomBarProps) {
  const { availableButtons, availableTabs } = useTourFunctionality(tour, theme);
  const bottomBarRef = useRef<HTMLDivElement>(null);
  const hideButtons = useURLParam(OverlayURLParam.HIDE_BUTTONS);
  const { openMenuItem, openedMenuItemId } = useMenuItemContent();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const visible = useAppSelector(getCarouselVisible);

  let hiddenButtons = useMemo(() => {
    return hideButtons.split(HIDE_BUTTONS_SEPARATOR);
  }, [hideButtons]);

  const numberOfSlides = useMemo(() => {
    availableButtons.unshift(mediaButton);
    const numberOfAvailableButtons = availableButtons
      .filter((button) => button.type !== 'fullscreen')
      .filter((button) => !hiddenButtons.includes(button.text)).length;
    if (isMobile) {
      if (numberOfAvailableButtons >= 6) {
        return 6;
      } else {
        return numberOfAvailableButtons;
      }
    } else if (numberOfAvailableButtons >= 1 && numberOfAvailableButtons <= 4) {
      return numberOfAvailableButtons;
    } else if (numberOfAvailableButtons === 5) {
      return 4;
    } else if (numberOfAvailableButtons >= 6 && numberOfAvailableButtons < 10) {
      return numberOfAvailableButtons % 2 === 0
        ? numberOfAvailableButtons
        : numberOfAvailableButtons - 1;
    } else {
      return 10;
    }
  }, [availableButtons, hiddenButtons, isMobile]);

  useEffect(() => {
    if (tour.tourType !== 'property') {
      return;
    }

    if (
      CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT.includes(tour.client._id) ||
      (CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT_ON_DESKTOP.includes(
        tour.client._id
      ) &&
        !isMobile)
    ) {
      dispatch(setControlsVisible(true));
    }
  }, [dispatch, isMobile, tour.client._id, tour.tourType]);

  const containerTransition = useTransition(!visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <div className={styles.menuContainer} id="bottomBar">
      {isMobile
        ? containerTransition(
            (style, item) =>
              item && (
                <animated.div style={style}>
                  <CarouselMobile
                    availableTabs={availableTabs}
                    config={tour.carousel}
                    pillColors={{
                      default: theme.styles.carousel.tabs.icons.default.color,
                      active: theme.styles.carousel.tabs.icons.active.color,
                    }}
                  />
                </animated.div>
              )
          )
        : containerTransition(
            (style, item) =>
              item && (
                <animated.div className={styles.carousel} style={style}>
                  <Carousel
                    availableTabs={availableTabs}
                    config={tour.carousel}
                    pillColors={{
                      default: theme.styles.carousel.tabs.icons.default.color,
                      active: theme.styles.carousel.tabs.icons.active.color,
                    }}
                  />
                  <div className={styles.divider}> </div>
                </animated.div>
              )
          )}
      <Swiper
        className={styles.menuBar}
        ref={bottomBarRef as unknown as React.RefObject<SwiperRef>}
        slidesPerView={numberOfSlides}
        spaceBetween={2}
      >
        {availableButtons
          .filter((button) => button.type !== 'fullscreen')
          .filter((button) => !hiddenButtons.includes(button.text))
          .map((button) => (
            <SwiperSlide key={button.id} className={styles.buttonContainer}>
              <MenuButton
                button={button}
                onItemClick={openMenuItem}
                openedMenuItemId={openedMenuItemId}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}
