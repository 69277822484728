import { useMemo } from 'react';

import { isIframed } from '../../utils/isIframed/isIframed';
import { isIframedWithClientLibrary } from '../../utils/isIframedWithClientLibrary/isIframedWithClientLibrary';

export function useClipboard() {
  const isEnabled =
    !isIframed() || (isIframed() && isIframedWithClientLibrary());

  return useMemo(
    () => ({
      isClipboardEnabled:
        !isIframed() || (isIframed() && isIframedWithClientLibrary()),
      writeTextToClipboard: isEnabled
        ? (text: string) => {
            return navigator.clipboard.writeText(text);
          }
        : undefined,
    }),
    [isEnabled]
  );
}
