import { animated, useSpring } from '@react-spring/web';

interface ImageProps {
  alt?: string;
  animationDelay?: number;
  className?: string;
  dataCy?: string;
  height?: string;
  onLoad?: () => void;
  src: string;
  width?: string;
}

const handleMouseEvent = (e: { preventDefault: () => void }) => {
  e.preventDefault();
  return 'return false;';
};

export default function Image({
  alt,
  animationDelay = 0,
  className,
  dataCy,
  height,
  onLoad,
  src,
  width,
}: ImageProps) {
  const [initAnimationStyles, initAnimationApi] = useSpring(() => ({
    opacity: 0,
  }));

  return (
    <animated.img
      data-cy={dataCy}
      style={initAnimationStyles}
      alt={alt}
      draggable={false}
      onContextMenu={handleMouseEvent}
      className={className}
      height={height}
      width={width}
      onLoad={() => {
        initAnimationApi.start({
          delay: animationDelay,
          opacity: 1,
        });

        onLoad?.();
      }}
      src={src}
    />
  );
}
