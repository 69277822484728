import React from 'react';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import styles from './ScrollArrow.module.scss';
import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';

interface ScrollArrowProps {
  direction: 'left' | 'right';
  selectedType: 'image' | 'video' | 'pano';
  className?: string;
  show: boolean;
}

export default function ScrollArrow({
  className,
  direction,
  show,
}: ScrollArrowProps) {
  const left = direction === 'left';

  const animation = useSpring({
    opacity: show ? 1 : 0,
  });

  return (
    <animated.div className={clsx(styles.wrapper, className)} style={animation}>
      <ArrowIcon
        style={{
          transform: left ? 'rotate(180deg)' : 'none',
        }}
      />
    </animated.div>
  );
}
