import { Pano } from '../../../types/carousel';
import { ViewerURLParam } from '../../types/ViewerURLParam';

export const getInitialPanoId = (
  carouselIndex: number,
  startPano: string | undefined,
  carouselPanos: Pano[]
) => {
  const params = new URLSearchParams(window.location.search);

  const customInitialPanoId = params.get(ViewerURLParam.PANO);

  if (!isNaN(carouselIndex)) {
    return carouselPanos[carouselIndex].panoid;
  }

  if (carouselPanos.some((pano) => pano.panoid === customInitialPanoId)) {
    return customInitialPanoId;
  }

  if (carouselPanos.some((pano) => pano.panoid === startPano)) {
    return startPano;
  }

  const [firstPano] = carouselPanos;

  if (firstPano) {
    return firstPano.panoid;
  }
};
