import { useState } from 'react';

import { Theme } from '../../../../types/theme';
import { Tour } from '../../../../types';
import { useAppSelector } from '../../../../hooks/redux';
import { useFullscreen } from '../../../../hooks/useFullscreen/useFullscreen';
import { VIEW_CONTROLS_ID } from '../../../../constants/ids';
import AutoRotate from '../../../../components/AutoRotate/AutoRotate';
import Fullscreen from '../../../LivcorTheme/components/ViewControls/Fullscreen';
import MobileMenu from '../MobileMenu/MobileMenu';
import useTourFunctionality from '../../../../hooks/useTourFunctionality/useTourFunctionality';
import useMediaQuery from '../../../../hooks/useMediaQuery/useMediaQuery';

import { ReactComponent as AutorotatePause } from './assets/autorotatePause.svg';
import { ReactComponent as AutorotatePlay } from './assets/autorotatePlay.svg';

import styles from './ViewControls.module.scss';

interface ViewControlsProps {
  tour: Tour;
  theme: Theme;
  showHamburger: boolean;
}

export default function ViewControls({
  theme,
  tour,
  showHamburger,
}: ViewControlsProps) {
  const media = useAppSelector((s) => s.media);
  const { availableButtons, hasFullscreenButton } = useTourFunctionality(
    tour,
    theme
  );
  const [autoRotateEnabled, setAutoRotateEnabled] = useState(tour.autoRotate);
  const { isFullscreenEnabled } = useFullscreen();
  const isMobile = useMediaQuery('xs');

  return (
    <div className={styles.viewControls} id={VIEW_CONTROLS_ID}>
      {media.type === 'pano' && !isMobile ? (
        <AutoRotate
          playIcon={AutorotatePlay}
          pauseIcon={AutorotatePause}
          tour={tour}
          enabled={autoRotateEnabled}
          onChange={setAutoRotateEnabled}
        />
      ) : null}
      {!isMobile && hasFullscreenButton && isFullscreenEnabled ? (
        <Fullscreen />
      ) : null}
      {showHamburger ? (
        <MobileMenu tour={tour} menuButtons={availableButtons} />
      ) : null}
    </div>
  );
}
