import { useDebugValue, useMemo } from 'react';

import crossFrameEvent, {
  CommonGAEventParams,
  GAEventParams,
} from '../../utils/crossFrameEvent/crossFrameEvent';
import useTourAnalytics, {
  AnalyticsEventType,
} from '../useTourAnalytics/useTourAnalytics';
import { capitalizeWords } from '../../utils/capitalizeWords/capitalizeWords';
import { StatIndex } from '../../api/stats';
import { TourHotspot, TourPolygon } from '../../types';
import useSource from '../../utils/useSource/useSource';
import useTour from '../useTour/useTour';
import useTourFunctionality from '../useTourFunctionality/useTourFunctionality';
import useTourStats from '../useTourStats/useTourStats';
import useUTMParams from '../useUTMParams/useUTMParams';
import useTheme from '../useTheme/useTheme';

export enum AnalyticsEventName {
  Conversion = 'Conversion',
  TourInitialized = 'Tour Initialized',
  MenuClick = 'Menu Click',
  ImageGalleryView = 'Image Gallery View',
  VideoGalleryView = 'Video Gallery View',
  SceneView = '360 Scene View',
  HotspotClick = 'Hotspot Click',
  AreaClick = 'Area Click',
}

const useAnalyticsEvents = () => {
  const tour = useTour();
  const theme = useTheme();
  const sendTourAnalytics = useTourAnalytics(tour);
  const { increaseTourStat } = useTourStats(tour);
  const utmParams = useUTMParams();
  const { analyticsEnabled } = useTourFunctionality(tour, theme);
  const { source, sourceId } = useSource();

  useDebugValue({ analyticsEnabled, sourceId });

  return useMemo(() => {
    const commonGAEventParams: CommonGAEventParams = {
      origin: 'TourBuilder',
      source,
      source_id: sourceId,
      tour_id: tour._id,
      tour_title: tour.title,
      ...Object.entries(utmParams).reduce<Record<string, string>>(
        (params, [paramName, paramValue]) => {
          if (paramValue) {
            params[paramName] = paramValue;
          }

          return params;
        },
        {}
      ),
    };

    return {
      conversion: (
        id: string,
        text: string,
        additionalMeta: Record<string, string> = {}
      ) => {
        const label = capitalizeWords(text);

        const gaEventParams: GAEventParams = {
          ...commonGAEventParams,
          ...additionalMeta,
          conversion_id: id,
          conversion_label: label,
        };

        crossFrameEvent({
          fnc: 'fireEvent',
          param: {
            eventName: AnalyticsEventName.Conversion,
            data: {
              tour: {
                title: tour.title,
                id: tour._id,
              },
              label: label,
            },
            gaEventParams,
          },
        });

        if (!analyticsEnabled) {
          return;
        }

        increaseTourStat(StatIndex.Conversion, id);
        gtag('event', AnalyticsEventName.Conversion, gaEventParams);
        crossFrameEvent({
          fnc: 'GA',
          param: {
            eventName: AnalyticsEventName.Conversion,
            eventParams: gaEventParams,
          },
        });
      },
      tourVisit: () => {
        const gaEventParams: GAEventParams = {
          ...commonGAEventParams,
        };

        crossFrameEvent({
          fnc: 'fireEvent',
          param: {
            eventName: AnalyticsEventName.TourInitialized,
            data: {
              tour: {
                title: tour.title,
                id: tour._id,
              },
              label: tour.title,
            },
            gaEventParams,
          },
        });

        if (!analyticsEnabled) {
          return;
        }

        increaseTourStat(StatIndex.Visits);
        sendTourAnalytics({
          eventtype: AnalyticsEventType.TOUR_VISIT,
          panoid: tour.carousel.panoStart.pano,
          sourceId,
        });
        gtag('event', AnalyticsEventName.TourInitialized, gaEventParams);
        crossFrameEvent({
          fnc: 'GA',
          param: {
            eventName: AnalyticsEventName.TourInitialized,
            eventParams: gaEventParams,
          },
        });
      },
      menuClick: (buttonId: string, buttonText: string) => {
        const label = capitalizeWords(buttonText);

        const gaEventParams: GAEventParams = {
          ...commonGAEventParams,
          menu_item_id: buttonId,
          menu_item_label: label,
        };

        crossFrameEvent({
          fnc: 'fireEvent',
          param: {
            eventName: AnalyticsEventName.MenuClick,
            data: {
              tour: {
                title: tour.title,
                id: tour._id,
              },
              label,
            },
            gaEventParams,
          },
        });

        if (!analyticsEnabled) {
          return;
        }

        increaseTourStat(StatIndex.Click, buttonId);
        sendTourAnalytics({
          buttonid: buttonId,
          eventtype: AnalyticsEventType.USER_CLICKED_A_BUTTON,
          sourceId,
        });
        gtag('event', AnalyticsEventName.MenuClick, gaEventParams);
        crossFrameEvent({
          fnc: 'GA',
          param: {
            eventName: AnalyticsEventName.MenuClick,
            eventParams: gaEventParams,
          },
        });
      },
      imageGalleryView: (id: string, url: string, title?: string) => {
        const label = title ? capitalizeWords(title) : url;

        const gaEventParams: GAEventParams = {
          ...commonGAEventParams,
          image_id: id,
          image_label: label,
        };

        crossFrameEvent({
          fnc: 'fireEvent',
          param: {
            eventName: AnalyticsEventName.ImageGalleryView,
            data: {
              tour: {
                title: tour.title,
                id: tour._id,
              },
              label,
            },
            gaEventParams,
          },
        });

        if (!analyticsEnabled) {
          return;
        }

        sendTourAnalytics({
          eventtype: AnalyticsEventType.USER_NAVIGATED_TO_IMAGE,
          imageid: id,
          sourceId,
        });
        gtag('event', AnalyticsEventName.ImageGalleryView, gaEventParams);
        crossFrameEvent({
          fnc: 'GA',
          param: {
            eventName: AnalyticsEventName.ImageGalleryView,
            eventParams: gaEventParams,
          },
        });
      },
      videoGalleryView: (id: string, title?: string) => {
        const label = title ? capitalizeWords(title) : id;

        const gaEventParams: GAEventParams = {
          ...commonGAEventParams,
          video_id: id,
          video_label: label,
        };

        crossFrameEvent({
          fnc: 'fireEvent',
          param: {
            eventName: AnalyticsEventName.VideoGalleryView,
            data: {
              tour: {
                title: tour.title,
                id: tour._id,
              },
              label,
            },
            gaEventParams,
          },
        });

        if (!analyticsEnabled) {
          return;
        }

        sendTourAnalytics({
          eventtype: AnalyticsEventType.USER_NAVIGATED_TO_VIDEO,
          videoid: id,
          sourceId,
        });
        gtag('event', AnalyticsEventName.VideoGalleryView, gaEventParams);
        crossFrameEvent({
          fnc: 'GA',
          param: {
            eventName: AnalyticsEventName.VideoGalleryView,
            eventParams: gaEventParams,
          },
        });
      },
      panoramaChanged: (panoId: string) => {
        const pano = tour.carousel.pano.find((pano) => pano.panoid === panoId);
        const label = pano ? pano.title : panoId;

        const gaEventParams: GAEventParams = {
          ...commonGAEventParams,
          pano_id: panoId,
          pano_label: label,
        };

        crossFrameEvent({
          fnc: 'fireEvent',
          param: {
            eventName: AnalyticsEventName.SceneView,
            data: {
              tour: {
                title: tour.title,
                id: tour._id,
              },
              label,
            },
            gaEventParams,
          },
        });

        if (!analyticsEnabled) {
          return;
        }

        increaseTourStat(StatIndex.Views, panoId);
        sendTourAnalytics({
          eventtype: AnalyticsEventType.USER_NAVIGATED_TO_PANO,
          panoid: panoId,
          sourceId,
        });
        gtag('event', AnalyticsEventName.SceneView, gaEventParams);
        crossFrameEvent({
          fnc: 'GA',
          param: {
            eventName: AnalyticsEventName.SceneView,
            eventParams: gaEventParams,
          },
        });
      },
      hotspotClick: (hotspot: TourHotspot) => {
        const label = hotspot.tooltipLabel || hotspot.title || '';

        const gaEventParams: GAEventParams = {
          ...commonGAEventParams,
          hotspot_id: hotspot.id,
          hotspot_type: hotspot.type,
          hotspot_scene_id: hotspot.sceneId,
          hotspot_label: label,
        };

        crossFrameEvent({
          fnc: 'fireEvent',
          param: {
            eventName: AnalyticsEventName.HotspotClick,
            data: {
              tour: {
                title: tour.title,
                id: tour._id,
              },
              label,
            },
            gaEventParams,
          },
        });

        if (!analyticsEnabled) {
          return;
        }

        gtag('event', AnalyticsEventName.HotspotClick, gaEventParams);
        crossFrameEvent({
          fnc: 'GA',
          param: {
            eventName: AnalyticsEventName.HotspotClick,
            eventParams: gaEventParams,
          },
        });
      },
      areaClick: (polygon: TourPolygon) => {
        const label = polygon.label.title || '';

        const gaEventParams: GAEventParams = {
          ...commonGAEventParams,
          area_id: polygon.id,
          area_type: polygon.type,
          area_scene_id: polygon.sceneID,
          area_label: label,
        };

        crossFrameEvent({
          fnc: 'fireEvent',
          param: {
            eventName: AnalyticsEventName.AreaClick,
            data: {
              tour: {
                title: tour.title,
                id: tour._id,
              },
              label,
            },
            gaEventParams,
          },
        });

        if (!analyticsEnabled) {
          return;
        }

        gtag('event', AnalyticsEventName.AreaClick, gaEventParams);
        crossFrameEvent({
          fnc: 'GA',
          param: {
            eventName: AnalyticsEventName.AreaClick,
            eventParams: gaEventParams,
          },
        });
      },
    };
  }, [
    analyticsEnabled,
    increaseTourStat,
    sendTourAnalytics,
    source,
    sourceId,
    tour,
    utmParams,
  ]);
};

export default useAnalyticsEvents;
