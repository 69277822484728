import { useMemo } from 'react';

import { UnitTour, UnitOfAreaNames } from '../../../../types';
import { makeUnitInfoID } from '../../constants';

import { pluralize } from '../../../../utils/pluralize/pluralize';

import styles from './UnitInfo.module.scss';

interface UnitInfoProps {
  unitInfo: UnitTour;
  joinPrimaryDetails?: boolean;
  hideUnitInformation?: boolean;
}

const BEDS_AND_BATHS_SEPARATOR = ', ';
const MOBILE_PRIMARY_DETAILS_SEPARATOR = ' | ';
const ZERO_BEDS_LABEL = 'Studio';

export default function UnitInfo({
  unitInfo,
  joinPrimaryDetails,
  hideUnitInformation,
}: UnitInfoProps) {
  const bedsAndBaths = [
    ...(typeof unitInfo.numBed === 'number'
      ? unitInfo.numBed === 0
        ? [ZERO_BEDS_LABEL]
        : [`${unitInfo.numBed} ${pluralize('bed', unitInfo.numBed)}`]
      : []),

    ...(!!unitInfo.numBath
      ? [`${unitInfo.numBath} ${pluralize('bath', unitInfo.numBath)}`]
      : []),
  ];

  const floorPlanType = useMemo(() => {
    try {
      JSON.parse(unitInfo.floorPlanType);

      return '';
    } catch (error) {}

    if (typeof unitInfo.floorPlanType === 'string') {
      return unitInfo.floorPlanType;
    }
  }, [unitInfo]);

  return (
    <div className={styles.container}>
      <div className={styles.infoPod}>
        <div className={styles.topRow}>
          {joinPrimaryDetails ? (
            <div>
              {[
                ...bedsAndBaths,
                ...(!!unitInfo.unitSize && !!unitInfo.unitSize.num
                  ? [
                      `${unitInfo.unitSize.num} ${
                        UnitOfAreaNames[unitInfo.unitSize.unitOfArea]
                      }`,
                    ]
                  : []),
              ].join(MOBILE_PRIMARY_DETAILS_SEPARATOR)}
            </div>
          ) : (
            <>
              {!!bedsAndBaths && (
                <div id={makeUnitInfoID('beds-baths')}>
                  {bedsAndBaths.join(BEDS_AND_BATHS_SEPARATOR)}
                </div>
              )}
              {!!unitInfo.unitSize && !!unitInfo.unitSize.num && (
                <div id={makeUnitInfoID('size')}>
                  {unitInfo.unitSize.num}{' '}
                  {UnitOfAreaNames[unitInfo.unitSize.unitOfArea]}
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.bottomRow}>
          {!!floorPlanType && (
            <div id={makeUnitInfoID('floor-plan')}>
              Floor plan {floorPlanType}
            </div>
          )}
          {!!unitInfo.unitNumber && !hideUnitInformation && (
            <div id={makeUnitInfoID('unit-number')}>
              Unit {unitInfo.unitNumber}
            </div>
          )}
          {!!unitInfo.buildingName && !hideUnitInformation && (
            <div id={makeUnitInfoID('building-name')}>
              Building {unitInfo.buildingName}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
