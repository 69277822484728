import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getInitialMedia } from './utils/getInitialMedia/getInitialMedia';
import { showImage, showPano, showVideo } from '../../stores/slices/media';
import { useAppDispatch } from '../redux';
import useAnalyticsEvents from '../useAnalyticsEvents/useAnalyticsEvents';
import useTour from '../useTour/useTour';

interface ViewerContextValue {
  viewer?: Panoskin.ViewerInstance;
  panoId?: string;
  pov?: Panoskin.POV;
  isSliderAvailable: boolean;
  sliderOpacity: number;
  setSliderOpacity?(opacity: number): void;
  setPov?(pov: Panoskin.POV): void;
}

const INITIAL_SLIDER_OPACITY = 0;

const ViewerContext = createContext<ViewerContextValue>({
  viewer: undefined,
  isSliderAvailable: false,
  sliderOpacity: INITIAL_SLIDER_OPACITY,
});

interface ProviderProps {
  children: ReactNode;
}

export function ViewerProvider({ children }: ProviderProps) {
  const tour = useTour();
  const viewer = window.viewer;

  const [panoId, setPanoId] = useState<string | undefined>(undefined);
  const [pov, setPov] = useState<Panoskin.POV | undefined>();
  const [isSliderAvailable, setIsSliderAvailable] = useState(false);
  const [sliderOpacity, setSliderOpacity] = useState(INITIAL_SLIDER_OPACITY);

  const analyticsEvents = useAnalyticsEvents();

  const dispatch = useAppDispatch();

  const updatePov = useCallback(() => {
    const newPov = viewer?.getPov();

    setPov(newPov);
  }, [viewer]);

  const updatePanoId = useCallback(() => {
    const newPanoId = viewer?.getPano();

    const isSliderAvailable = !!viewer?.isSliderAvailable();

    setIsSliderAvailable(isSliderAvailable);
    isSliderAvailable && viewer?.setSliderOpacity(sliderOpacity);

    setPanoId(newPanoId);

    if (newPanoId !== panoId && panoId !== undefined) {
      analyticsEvents.panoramaChanged(newPanoId!);
    }
  }, [analyticsEvents, panoId, sliderOpacity, viewer]);

  useEffect(() => {
    const initialPanoId = viewer?.getPano();
    const initialMedia = getInitialMedia(initialPanoId, tour.carousel);

    setPanoId(initialPanoId);

    if (initialMedia?.mediaType === 'pano') {
      dispatch(showPano());

      return;
    }

    if (initialMedia?.mediaType === 'image') {
      dispatch(
        showImage({
          imageId: initialMedia.image.imageId,
          imageTitle: initialMedia.image.title,
          source: initialMedia.image.image,
        })
      );

      return;
    }

    if (initialMedia?.mediaType === 'video') {
      dispatch(showVideo(initialMedia.video));

      return;
    }
  }, [dispatch, tour.carousel, viewer]);

  useEffect(() => {
    viewer?.addEventListener('pano_changed', updatePanoId);
    viewer?.addEventListener('pov_changed', updatePov);

    updatePov();
    updatePanoId();

    return () => {
      viewer?.removeEventListener('pano_changed', updatePanoId);
      viewer?.removeEventListener('pov_changed', updatePov);
    };
  }, [updatePanoId, updatePov, viewer]);

  return (
    <ViewerContext.Provider
      value={{
        isSliderAvailable,
        panoId,
        pov,
        sliderOpacity,
        setSliderOpacity: (opacity: number) => {
          setSliderOpacity(opacity);
          viewer?.setSliderOpacity(opacity);
        },
        viewer,
        setPov,
      }}
    >
      {children}
    </ViewerContext.Provider>
  );
}

export default function useViewer(): ViewerContextValue {
  return useContext(ViewerContext)!;
}
