import { useState } from 'react';

import { Tour } from '../../types';
import { useFullscreen } from '../../hooks/useFullscreen/useFullscreen';
import AutoRotate from '../AutoRotate/AutoRotate';

import { ReactComponent as FullScreenIcon } from './assets/enlarge.svg';
import { ReactComponent as PauseIcon } from './assets/pause.svg';
import { ReactComponent as PlayIcon } from './assets/play.svg';
import { ReactComponent as ShrinkScreenIcon } from './assets/shrink.svg';

import styles from './MediaImageControls.module.scss';

interface MediaImageControlProps {
  tour: Tour;
}

const MediaImageControls = ({ tour }: MediaImageControlProps) => {
  const [autoPlayEnabled, setAutoPlayEnabled] = useState(tour.autoRotate);
  const { isFullscreen, toggleFullscreen, isFullscreenEnabled } =
    useFullscreen();

  const FullscreenComponent = isFullscreen ? ShrinkScreenIcon : FullScreenIcon;

  return (
    <div className={styles.container}>
      {isFullscreenEnabled && (
        <FullscreenComponent
          className={styles.fullScreen}
          onClick={() => toggleFullscreen()}
        />
      )}
      <AutoRotate
        className={styles.autoRotate}
        enabled={autoPlayEnabled}
        onChange={setAutoPlayEnabled}
        tour={tour}
        playIcon={PlayIcon}
        pauseIcon={PauseIcon}
      />
    </div>
  );
};

export default MediaImageControls;
