import { getControlsVisible, setControlsVisible } from '../../stores/slices/ui';
import { HAMBURGER_ID } from '../../constants/ids';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import ControlGroup from '../ControlGroup/ControlGroup';

import styles from './Hamburger.module.scss';

interface HamburgerIconProps {
  visible: boolean;
}

export function HamburgerIcon({ visible }: HamburgerIconProps) {
  return (
    <div
      className={`${styles.iconContainer} ${visible ? styles.visible : ''}`}
      aria-label="Toggle menu visibility"
    >
      <div className={styles.firstLine}></div>
      <div className={styles.secondLine}></div>
      <div className={styles.thirdLine}></div>
    </div>
  );
}

export default function Hamburger() {
  const dispatch = useAppDispatch();
  const visible = useAppSelector(getControlsVisible);
  const toggleVisible = () => dispatch(setControlsVisible(!visible));

  return (
    <ControlGroup
      id={HAMBURGER_ID}
      className={styles.controlGroup}
      controls={[
        {
          display: <HamburgerIcon visible={visible} />,
          onClick: toggleVisible,
        },
      ]}
      dataCy="hamburger"
    />
  );
}
