const ESCAPE_KEY = 'Escape';

const onEscape = (callback: Function) => {
  return (event: React.KeyboardEvent) => {
    if (event.key === ESCAPE_KEY) {
      callback();
    }
  };
};

export default onEscape;
