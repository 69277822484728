import { useMemo } from 'react';

import { pluralize } from '../../../../utils/pluralize/pluralize';
import { UnitOfAreaNames, UnitTour } from '../../../../types';
// import formatPrice from '../../../../utils/formatPrice/formatPrice';
// import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';

import styles from './UnitTourInfo.module.scss';
import { makeUnitInfoID, UNIT_INFO_ID } from '../../constants';

interface UnitTourInfoProps {
  className?: string;
  unitTour: UnitTour;
  // priceVisible?: boolean;
  joinPrimaryDetails?: boolean;
  hideUnitInformation?: boolean;
}

const BEDS_AND_BATHS_SEPARATOR = ', ';
const MOBILE_PRIMARY_DETAILS_SEPARATOR = ' | ';
const ZERO_BEDS_LABEL = 'Studio';

export default function UnitTourInfo({
  className,
  unitTour,
  // priceVisible = true,
  joinPrimaryDetails,
  hideUnitInformation,
}: UnitTourInfoProps) {
  // const isMobilePortrait = useIsMobile('portrait');

  const bedsAndBaths = [
    ...(typeof unitTour.numBed === 'number'
      ? unitTour.numBed === 0
        ? [ZERO_BEDS_LABEL]
        : [`${unitTour.numBed} ${pluralize('bed', unitTour.numBed)}`]
      : []),

    ...(!!unitTour.numBath
      ? [`${unitTour.numBath} ${pluralize('bath', unitTour.numBath)}`]
      : []),
  ];

  const floorPlanType = useMemo(() => {
    try {
      JSON.parse(unitTour.floorPlanType);

      return '';
    } catch (error) {}

    if (typeof unitTour.floorPlanType === 'string') {
      return unitTour.floorPlanType;
    }
  }, [unitTour]);

  return (
    <div
      className={`${styles.unitTourInfo} ${className ? className : ''}`}
      data-cy="unit-tour-info"
      id={UNIT_INFO_ID}
    >
      {/* {priceVisible && Boolean(unitTour.rent?.minRent) ? (
        <>
          <div className={styles.price}>
            {formatPrice(unitTour.rent?.minRent!)}
            <span className={styles.perMonth}>/mo</span>
            <div className={styles.asLowAs}>as low as</div>
          </div>
          {!isMobilePortrait && <div className={styles.divider} />}
        </>
      ) : null} */}
      <div
        className={`${styles.details} ${
          joinPrimaryDetails ? styles.joinPrimaryDetails : ''
        }`}
      >
        {joinPrimaryDetails ? (
          <div className={styles.primaryDetails}>
            {[
              ...bedsAndBaths,
              ...(!!unitTour.unitSize && !!unitTour.unitSize.num
                ? [
                    `${unitTour.unitSize.num} ${
                      UnitOfAreaNames[unitTour.unitSize.unitOfArea]
                    }`,
                  ]
                : []),
            ].join(MOBILE_PRIMARY_DETAILS_SEPARATOR)}
          </div>
        ) : (
          <>
            {!!bedsAndBaths && (
              <div
                className={styles.bedsAndBaths}
                id={makeUnitInfoID('beds-baths')}
              >
                {bedsAndBaths.join(BEDS_AND_BATHS_SEPARATOR)}
              </div>
            )}
            {!!unitTour.unitSize && !!unitTour.unitSize.num && (
              <div className={styles.unitSize} id={makeUnitInfoID('size')}>
                {unitTour.unitSize.num}{' '}
                {UnitOfAreaNames[unitTour.unitSize.unitOfArea]}
              </div>
            )}
          </>
        )}

        {!!floorPlanType && (
          <div
            className={`${styles.floorplanType} ${styles.secondaryDetails}`}
            id={makeUnitInfoID('floor-plan')}
          >
            Floor plan {floorPlanType}
          </div>
        )}
        {!!unitTour.unitNumber && !hideUnitInformation && (
          <div
            className={`${styles.unitNumber} ${styles.secondaryDetails}`}
            id={makeUnitInfoID('unit-number')}
          >
            Unit {unitTour.unitNumber}
          </div>
        )}
        {!!unitTour.buildingName && !hideUnitInformation && (
          <div
            className={`${styles.buildingName} ${styles.secondaryDetails}`}
            id={makeUnitInfoID('building-name')}
          >
            Building {unitTour.buildingName}
          </div>
        )}
      </div>
    </div>
  );
}
