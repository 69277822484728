import styles from './HotspotDot.module.scss';

interface HotspotDotProps {
  onClick(): void;
  panoId: string;
}

const HotspotDot = ({ onClick, panoId }: HotspotDotProps) => {
  return (
    <svg
      className={styles.hotspotDot}
      onClick={onClick}
      height="12"
      width="12"
      data-cy={`floor-plan-hotspot-dot-${panoId}`}
    >
      <circle cx="6" cy="6" r="4"></circle>
    </svg>
  );
};

export default HotspotDot;
