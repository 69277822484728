import { useState, useRef, useEffect } from 'react';
import Mustache from 'mustache';

import { getTemplateUrl } from '../../stores/slices/ui';
import { Tour } from '../../types';
import { useAppSelector } from '../../hooks/redux';

interface TemplateDrawerProps {
  tour: Tour;
  className?: string;
}

export default function TemplateDrawer({
  tour,
  className,
}: TemplateDrawerProps) {
  const templateUrl = useAppSelector(getTemplateUrl);
  const [template, setTemplate] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (templateUrl) {
      const loadTemplate = async () => {
        const templateResponse = await fetch(templateUrl);
        const templateText = await templateResponse.text();

        setTemplate(Mustache.render(templateText, tour));
      };

      loadTemplate();
    }
  }, [templateUrl, tour]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const node = document.createRange().createContextualFragment(template);

    ref.current.appendChild(node);
  }, [template]);

  return <div className={className} ref={ref}></div>;
}
