import { UnitTour } from '../../types';

const checkForUnitInfo = (unitTour: UnitTour) => {
  return (
    typeof unitTour.numBed === 'number' ||
    typeof unitTour.numBath === 'number' ||
    (typeof unitTour.floorPlanType === 'string' &&
      unitTour.floorPlanType !== '') ||
    Boolean(unitTour.buildingName) ||
    Boolean(unitTour.unitNumber) ||
    (Boolean(unitTour.unitSize) && Boolean(unitTour.unitSize?.num))
  );
};

export default checkForUnitInfo;
