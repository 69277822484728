import loadScript from '../loadScript/loadScript';

const loadScriptsSerially = async (
  scriptsGenerator: () => AsyncGenerator<string>
) => {
  for await (const script of scriptsGenerator()) {
    await loadScript(script);
  }
};

export default loadScriptsSerially;
