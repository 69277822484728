import { useMemo } from 'react';

const UTM_PARAMS = [
  'utm_id',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_source_platform',
  'utm_term',
  'utm_content',
  'utm_creative_format',
  'utm_marketing_tactic',
] as const;

type UTM_PARAM = typeof UTM_PARAMS[number];

export default function useUTMParams() {
  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    return UTM_PARAMS.reduce((utmParams, utmParam) => {
      utmParams[utmParam] = urlSearchParams.get(utmParam);

      return utmParams;
    }, {} as Record<UTM_PARAM, string | null>);
  }, []);
}
