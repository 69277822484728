const FAMILY_SEPARATOR = ':';
const WHITE_SPACE_SEPARATOR = '+';
const WHITE_SPACE = ' ';
const DEFAULT_FALLBACK = 'sans-serif';

const useGoogleFont = (font: string) => {
  if (!font) {
    return DEFAULT_FALLBACK;
  }

  const [family] = font.split(FAMILY_SEPARATOR);

  return `'${family.replace(
    WHITE_SPACE_SEPARATOR,
    WHITE_SPACE
  )}', ${DEFAULT_FALLBACK}`;
};

export default useGoogleFont;
