import { useEffect } from 'react';
import useViewer from '../../../hooks/useViewer/useViewer';
import { useAppSelector } from '../../../hooks/redux';
import { useNavigateToPhoto } from '../../../hooks/useNavigateToPhoto/useNavigateToPhoto';
import { useNavigateToVideo } from '../../../hooks/useNavigateToVideo/useNavigateToVideo';
import { Tour } from '../../../types';

let timerId: number | null = null;

const MEDIA_SHOWTIME_MILISECONDS = 3000;
const SCROLLING_MARGIN = 100;

interface useMediaAutoplayArguments {
  tour: Tour;
  enabled: boolean;
}

export default function useMediaAutoplay({
  tour,
  enabled,
}: useMediaAutoplayArguments) {
  const { viewer } = useViewer();
  const media = useAppSelector((state) => state.media);
  const autoRotateMode = tour.autoRotateMode || 'slide-show';

  const { navigateToPhoto: navigateToPhotoRight, nextImage } =
    useNavigateToPhoto('right', tour.carousel.image);
  const { navigateToVideo: navigateToVideoRight, nextVideo } =
    useNavigateToVideo('right', tour.carousel.video);

  useEffect(() => {
    if (!viewer || media.type === 'pano') return;
    if (enabled && timerId) return;
    if (enabled) {
      timerId = window.setInterval(() => {
        const carouselNode = document.getElementById('carousel');
        let nextItemId = '';
        if (media.type === 'image') {
          nextItemId = nextImage.imageId;
          navigateToPhotoRight();
        }

        if (media.type === 'video') {
          nextItemId = nextVideo.videoId;
          navigateToVideoRight();
        }

        const nextItemNode = document.getElementById(nextItemId);

        if (carouselNode && nextItemNode) {
          const itemOffset = nextItemNode.offsetLeft;
          const itemWidth = nextItemNode.clientWidth;
          const carouselWidth = carouselNode.clientWidth;

          const scrollByDistance =
            itemOffset > carouselWidth - itemWidth
              ? itemWidth + SCROLLING_MARGIN
              : -carouselWidth;

          carouselNode.scrollBy({
            left: scrollByDistance,
            behavior: 'smooth',
          });
        }
      }, MEDIA_SHOWTIME_MILISECONDS);
    }

    if (!enabled && timerId) {
      window.clearInterval(timerId);
      timerId = null;
    }

    return () => {
      if (timerId) {
        window.clearInterval(timerId);
        timerId = null;
      }
    };
  }, [
    enabled,
    viewer,
    autoRotateMode,
    tour.carousel.pano,
    media.type,
    navigateToPhotoRight,
    navigateToVideoRight,
    nextImage?.imageId,
    nextVideo?.videoId,
  ]);
}
