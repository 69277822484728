import { useRef, useCallback, useDebugValue, type RefObject } from 'react';

type NodeRef = HTMLElement | null;

// NOTE: the use of refs instead of state values is very deliberate here
// returning a straight boolean value would only tell callers when the overflow value changes
// however, we want callers to be able to check the overflow value whenever they want
// and then trigger their own layout changes accordingly

export default function useDetectOverflow(startedOverflowing: () => void) {
  const isOverflowing = useRef(false);
  const targetRef = useRef<NodeRef>(null);
  const observer = useRef<MutationObserver | null>(null);

  const checkOverflow = useCallback(() => {
    const targetNode = targetRef.current;
    if (targetNode) {
      const style = targetNode.style;
      // capture starting values
      const oldStyle = style.overflowX;
      const oldOverflow = isOverflowing.current;
      // make measurement
      style.overflowX = 'hidden';
      const newOverflow = targetNode.scrollWidth > targetNode.offsetWidth;
      // reset style
      style.overflowX = oldStyle;
      // update value and run hooks
      isOverflowing.current = newOverflow;
      if (newOverflow && !oldOverflow) {
        startedOverflowing();
      }
    }
  }, [startedOverflowing]);

  // callback ref instead of regular useRef, so we can directly observe when the caller changes the target node
  const elementRef = useCallback(
    (node: NodeRef) => {
      // old node teardown logic
      if (targetRef.current) {
        observer.current?.disconnect();

        window.removeEventListener('resize', checkOverflow);
      }
      // set new target node
      targetRef.current = node;
      if (node) {
        const obs = new MutationObserver(checkOverflow);
        obs.observe(node, { subtree: true, childList: true });
        observer.current = obs;

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
      }
    },
    [checkOverflow]
  );

  useDebugValue(isOverflowing);

  return [
    elementRef,
    isOverflowing as RefObject<boolean>, // expose non-mutable type to callers
  ] as const;
}
