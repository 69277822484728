import { animated, useSpring } from '@react-spring/web';
import { useRef } from 'react';

import { Theme } from '../../../../types/theme';
import { Tour } from '../../../../types';
import { useAppSelector } from '../../../../hooks/redux';
import hexToRGB, { HEX } from '../../../../utils/hexToRGB/hexToRGB';
import Menu from '../Menu/Menu';
import TemplateDrawer from '../../../../components/TemplateDrawer/TemplateDrawer';
import useMenuItemContent from '../../hooks/useMenuItemContent/useMenuItemContent';

import styles from './Sidebar.module.scss';

import {
  getAccessibilityMode,
  getSidebarVisible,
  getTemplateUrl,
} from '../../../../stores/slices/ui';
import Close from '../Close/Close';
import { SIDEBAR_ID } from '../../../../constants/ids';

interface SidebarProps {
  tour: Tour;
  theme: Theme;
}

export default function Sidebar({ tour, theme }: SidebarProps) {
  const sidebarVisible = useAppSelector(getSidebarVisible);
  const accessibilityModeEnabled = useAppSelector(getAccessibilityMode);
  const templateDrawerVisible = useAppSelector(getTemplateUrl);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const containerAnimation = useSpring({
    right:
      sidebarVisible || !sidebarRef.current
        ? 0
        : -sidebarRef.current?.clientWidth,
    config: { tension: 120, friction: 60 },
  });

  const { openMenuItem } = useMenuItemContent();

  const animation = useSpring({
    width: templateDrawerVisible ? 300 : 0,
    config: {
      clamp: true,
    },
  });

  const closeButtonAnimation = useSpring({
    x: templateDrawerVisible ? -300 : 0,
    config: {
      clamp: true,
    },
  });

  return (
    <animated.div
      style={{
        ...containerAnimation,
      }}
      className={styles.container}
      id={SIDEBAR_ID}
    >
      <animated.div
        className={styles.templateDrawer}
        style={{
          ...animation,
          backgroundColor: hexToRGB('#ffffff' as HEX, 0.5),
        }}
      >
        <TemplateDrawer tour={tour} className={styles.templateDrawer} />
      </animated.div>
      <animated.div style={closeButtonAnimation}>
        {!accessibilityModeEnabled ? <Close /> : null}
      </animated.div>

      <div
        ref={sidebarRef}
        className={styles.sidebar}
        style={{
          backgroundColor: hexToRGB(theme.styles.menu.background as HEX, 0.85),
        }}
      >
        <Menu theme={theme} tour={tour} onMenuButtonClick={openMenuItem} />
      </div>
    </animated.div>
  );
}
