import { animated, useSpring } from '@react-spring/web';
import { ReactNode, useEffect, useRef } from 'react';

import hexToRGB, { HEX } from '../../utils/hexToRGB/hexToRGB';
import onEnter from '../../utils/onEnter/onEnter';
import Portal from '../Portal/Portal';
import useOnClickOutside from '../../hooks/useOnClickOutside/useOnClickOutside';
import useTheme from '../../hooks/useTheme/useTheme';

import { ReactComponent as Close } from './assets/close.svg';

import styles from './Modal.module.scss';

interface ModalProps {
  backgroundColor?: string;
  closeButtonClassName?: string;
  children: ReactNode;
  onClose: () => void;
  visible: boolean;
}

export default function Modal({
  backgroundColor,
  closeButtonClassName,
  children,
  onClose,
  visible,
}: ModalProps) {
  const theme = useTheme();

  const ref = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<SVGSVGElement>(null);
  const springStyles = useSpring({
    opacity: visible ? 1 : 0,
  });

  useEffect(() => {
    closeButtonRef.current?.focus();
  }, [closeButtonRef]);

  useOnClickOutside(ref, onClose);

  return visible ? (
    <Portal className={styles.container}>
      <animated.div
        className={styles.modal}
        ref={ref}
        style={{
          ...springStyles,
          pointerEvents: visible ? 'auto' : 'none',
          backgroundColor:
            backgroundColor ??
            hexToRGB(theme.styles.modal.background as HEX, 0.9),
        }}
      >
        <Close
          aria-label="Close modal"
          className={`${styles.close} ${closeButtonClassName ?? ''}`}
          data-cy="close-modal"
          onClick={onClose}
          onKeyDown={onEnter(onClose)}
          ref={closeButtonRef}
          tabIndex={0}
        />
        {children}
      </animated.div>
    </Portal>
  ) : null;
}
