import { animated, useTransition } from '@react-spring/web';

import HotspotCone from './components/HotspotCone/HotspotCone';
import HotspotDot from './components/HotspotDot/HotspotDot';

import styles from './Hotspot.module.scss';

interface Coordinates {
  x: number;
  y: number;
}

interface HotspotProps {
  coordinates: Coordinates;
  heading: number;
  isActive?: boolean;
  offset: number;
  onClick(): void;
  panoId: string;
}

const Hotspot = ({
  coordinates,
  heading,
  isActive,
  offset,
  onClick,
  panoId,
}: HotspotProps) => {
  const transitions = useTransition(true, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
  });

  return transitions(
    (style, item) =>
      item && (
        <animated.div style={style}>
          <div
            className={`${styles.hotspot} ${
              isActive ? styles.activeHotspot : ''
            }`}
            style={{ left: coordinates.x, top: coordinates.y }}
            data-cy={`floor-plan-hotspot`}
          >
            {isActive && <HotspotCone rotationDegrees={heading + offset} />}
            <HotspotDot onClick={onClick} panoId={panoId} />
          </div>
        </animated.div>
      )
  );
};

export default Hotspot;
