import { useEffect } from 'react';
import useViewer from '../useViewer/useViewer';
import useAnalyticsEvents from '../useAnalyticsEvents/useAnalyticsEvents';

export default function usePolygons(polygons: Panoskin.Polygon[] = []) {
  const { viewer, panoId } = useViewer();
  const analyticsEvents = useAnalyticsEvents();

  useEffect(() => {
    return function cleanup() {
      if (!panoId) {
        return;
      }

      polygons.forEach((polygon) => {
        viewer?.deletePolygon(panoId, polygon.id);
      });
    };
  }, [polygons, panoId, viewer]);

  useEffect(() => {
    if (viewer) {
      polygons.forEach((polygon) => {
        const panoId = polygon.pano?.id;
        const pov = polygon.pano?.pov;

        viewer.createPolygon({
          ...polygon,
          ...(polygon.type === 'pano' &&
            panoId &&
            pov && {
              onclick: () => {
                analyticsEvents.areaClick(polygon);
                viewer.setPano(panoId, {
                  pov,
                });
              },
            }),
        });
      });
    }
  }, [polygons, viewer, panoId, analyticsEvents]);
}
