import styles from './useMediaQuery.module.scss';
import { useEffect, useRef, useState } from 'react';

type Breakpoint = 'lg' | 'md' | 'sm' | 'xs';
export type Orientation = 'landscape' | 'portrait';

export default function useMediaQuery(
  breakpoint: Breakpoint,
  orientation?: Orientation
) {
  const queryList = useRef(
    window.matchMedia(
      `(max-width: ${styles[breakpoint]}) ${
        orientation ? 'and (orientation: ' + orientation + ')' : ''
      }`
    )
  );
  const [matches, setMatches] = useState(queryList.current.matches);

  function onMediaQueryUpdate(this: MediaQueryList) {
    setMatches(this.matches);
  }

  useEffect(() => {
    const list = queryList.current;
    list.addEventListener('change', onMediaQueryUpdate);
    return () => list.removeEventListener('change', onMediaQueryUpdate);
  }, []);

  return matches;
}
