import { useCallback } from 'react';

import { Tour } from '../../types';
import {
  StatIndex,
  useGetTotalTourStatsQuery,
  useIncreaseStatMutation,
} from '../../api/stats';

export default function useTourStats(tour: Tour) {
  const [increaseStat] = useIncreaseStatMutation();
  const {
    data = {
      stats: {
        total: {
          clicks: 0,
          conversions: 0,
          crm: {
            emails: {
              clicked: 0,
              opened: 0,
              sent: 0,
              unsubscribed: 0,
            },
          },
          likes: 0,
          live: 0,
          meetings: 0,
          views: 0,
          visits: 0,
        },
      },
    },
  } = useGetTotalTourStatsQuery({ tourId: tour._id });

  const increaseTourStat = useCallback(
    (statIndex: StatIndex, statId?: string) => {
      increaseStat({
        clientId: tour.client._id,
        statIndex: statIndex,
        tourId: tour._id,
        statId,
      });
    },
    [increaseStat, tour]
  );

  return {
    increaseTourStat,
    stats: data.stats,
  };
}
