import { getCustomCarouselIndex } from './utils/getCustomCarouselIndex/getCustomCarouselIndex';
import { getInitialPanoId } from './utils/getInitialPanoId/getInitialPanoId';
import { Tour } from '../types';
import { VIEWER_CONTAINER_ID } from '../constants';
import { normalizeTour } from './utils/normalizeTour/normalizeTour';

import arrow from './assets/arrow.svg';
import decagonArrow from './assets/decagon.svg';
import googleArrow from './assets/google-arrow.svg';
import haloArrow from './assets/halo.svg';
import { ViewerURLParam } from './types/ViewerURLParam';

const ERROR_SAFE_VIEWER_FUNCTIONS = [
  'createHotspot',
  'createLabel',
  'createPolygon',
  'createSlider',
  'deleteHotspot',
  'deleteLabel',
  'deletePolygon',
  'getPano',
  'getPov',
  'isSliderAvailable',
  'setPano',
  'setPov',
  'setSliderOpacity',
] as const;

const suppressErrorsDuringTransition = function (fn: Function) {
  return function () {
    try {
      // @ts-ignore
      return fn.apply(this, arguments);
    } catch (err) {
      console.error(err);
    }
  };
};

const DEFAULT_POV: Panoskin.POV = { heading: 0, pitch: 0, zoom: 1 };

const params = new URLSearchParams(window.location.search);

const customInitialHeading = params.get(ViewerURLParam.HEADING);
const customInitialPitch = params.get(ViewerURLParam.PITCH);
const customInitialZoom = params.get(ViewerURLParam.ZOOM);

const ARROW_TO_PATH_MAP: Record<Tour['navigationArrows'], string> = {
  arrow: arrow,
  decagon: decagonArrow,
  google: googleArrow,
  halo: haloArrow,
};

const tour = window.tour;

const initViewer = () => {
  const customCarouselIndex = getCustomCarouselIndex(tour.carousel.pano);
  const initialPanoId = getInitialPanoId(
    customCarouselIndex,
    tour.carousel.panoStart.pano,
    tour.carousel.pano
  );

  if (initialPanoId) {
    window.viewer = new Panoskin.Viewer().mount({
      arrow: ARROW_TO_PATH_MAP[tour.navigationArrows],
      containerID: VIEWER_CONTAINER_ID,
      startPanoID: initialPanoId,
      datafeed: normalizeTour(tour),
    });

    ERROR_SAFE_VIEWER_FUNCTIONS.forEach((functionName) => {
      if (typeof window.viewer![functionName] === 'function') {
        window.viewer![functionName] = suppressErrorsDuringTransition(
          window.viewer![functionName]
        );
      }
    });

    window.viewer.setLinkMode(tour.navigationArrowsMode);

    const initialPov: Panoskin.POV = {
      heading:
        customInitialHeading && !isNaN(Number(customInitialHeading))
          ? Number(customInitialHeading)
          : tour.carousel.panoStart.pov?.heading ?? DEFAULT_POV.heading,
      pitch:
        customInitialPitch && !isNaN(Number(customInitialPitch))
          ? Number(customInitialPitch)
          : tour.carousel.panoStart.pov?.pitch ?? DEFAULT_POV.pitch,
      zoom:
        customInitialZoom && !isNaN(Number(customInitialZoom))
          ? Number(customInitialZoom)
          : tour.carousel.panoStart.pov?.zoom ?? DEFAULT_POV.zoom,
    };

    window.viewer.setPov(initialPov);
  }
};

export const Viewer = {
  initViewer,
};
