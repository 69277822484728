import React, { CSSProperties } from 'react';
import { Image } from '../../stores/slices/media';
import styles from './MediaImage.module.scss';
import ReactDOM from 'react-dom';
import ImageComponent from '../Image/Image';
import useIsMobile from '../../hooks/useIsMobile/useIsMobile';
import { useSwipeable } from 'react-swipeable';
import MediaArrow from '../MediaArrow/MediaArrow';
import { ArrowVariant } from '../../types/index.js';
import { Bounce } from 'react-activity';

const node = document.getElementById('image')!;

interface MediaImageProps {
  config: Image;
  arrowVariant: ArrowVariant;
  showArrows: boolean;
  onNavigateLeft?: () => void;
  onNavigateRight?: () => void;
  cssVariables?: CSSProperties;
}

export default function MediaImage({
  config: { source },
  arrowVariant,
  showArrows,
  onNavigateLeft,
  onNavigateRight,
  cssVariables = {},
}: MediaImageProps) {
  const isMobile = useIsMobile();

  const swipeHandlers = useSwipeable({
    onSwipedRight: isMobile && onNavigateLeft ? onNavigateLeft : undefined,
    onSwipedLeft: isMobile && onNavigateRight ? onNavigateRight : undefined,
  });

  return ReactDOM.createPortal(
    <div className={styles.container} {...swipeHandlers} style={cssVariables}>
      {onNavigateLeft && showArrows ? (
        <MediaArrow
          direction="left"
          arrowVariant={arrowVariant}
          onNavigate={onNavigateLeft}
        />
      ) : null}
      <div className={styles.loading}>
        <Bounce
          className={styles.bounce}
          color="#727981"
          size={32}
          speed={1}
          animating={true}
        />
      </div>
      <ImageComponent
        animationDelay={200}
        alt="currently selected carousel item"
        className={styles.image}
        src={source.url}
      />
      {onNavigateRight && showArrows ? (
        <MediaArrow
          direction="right"
          arrowVariant={arrowVariant}
          onNavigate={onNavigateRight}
        />
      ) : null}
    </div>,
    node
  );
}
