import {
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import screenfull from 'screenfull';

import useURLParam, { OverlayURLParam } from '../useURLParam/useURLParam';
import { isIframedWithClientLibrary } from '../../utils/isIframedWithClientLibrary/isIframedWithClientLibrary';

interface FullscreenEvent {
  fnc: 'fullScreen';
  param: {
    id: string;
  };
}

// if there is no nativeAPI available screenfull returns only isEnabled property https://github.com/sindresorhus/screenfull/blob/main/index.js#L156

export function useFullscreen() {
  const id = useURLParam(OverlayURLParam.ID) || 'tour';
  const hideFullScreen = useURLParam(OverlayURLParam.HIDE_FULL_SCREEN);
  const [isFullscreen, setIsFullscreen] = useState(
    Boolean(screenfull.isFullscreen)
  );

  useEffect(() => {
    const trackFullscreen = () => {
      setIsFullscreen(Boolean(screenfull.isFullscreen));
    };

    if (screenfull.isEnabled) {
      screenfull.on?.('change', trackFullscreen);

      return () => {
        screenfull.off?.('change', trackFullscreen);
      };
    }
  }, []);

  const toggleFullscreen = useCallback(() => {
    if (screenfull.isEnabled) {
      screenfull.toggle?.();

      return;
    }

    if (isIframedWithClientLibrary()) {
      const event: FullscreenEvent = {
        fnc: 'fullScreen',
        param: {
          id,
        },
      };

      window.parent.postMessage(JSON.stringify(event), document.referrer);
    }
  }, [id]);

  const value = useMemo(
    () => ({
      isFullscreen,
      isFullscreenEnabled:
        !hideFullScreen &&
        (screenfull.isEnabled || isIframedWithClientLibrary()),
      toggleFullscreen,
    }),
    [hideFullScreen, isFullscreen, toggleFullscreen]
  );

  useDebugValue(value.isFullscreenEnabled ? 'enabled' : 'disabled');

  return value;
}
