import React, { useEffect, useMemo, useState } from 'react';
import styles from './AutoRotate.module.scss';
import { ReactComponent as Play } from './rotate_play.svg';
import { ReactComponent as Pause } from './rotate_pause.svg';
import useViewer from '../../../../hooks/useViewer/useViewer';
import { Tour } from '../../../../types';
import { useAppSelector } from '../../../../hooks/redux';

const ROTATE_INTERVAL = 30;
const ROTATE_STEP = 0.03;

let timerId: number | null = null;

interface AutoRotateProps {
  tour: Tour;
}

export default function AutoRotate({ tour }: AutoRotateProps) {
  const media = useAppSelector((state) => state.media);

  const [autoRotateEnabled, setAutoRotateEnabled] = useState(tour.autoRotate);
  const autoRotateMode = tour.autoRotateMode || 'slide-show';
  const { viewer } = useViewer();

  useEffect(() => {
    if (!viewer) return;
    if (autoRotateEnabled && timerId) return;

    if (autoRotateEnabled) {
      timerId = window.setInterval(() => {
        if (media.type === 'pano') {
          const { heading, pitch, zoom } = viewer.getPov();

          viewer?.setPov({
            heading: heading + ROTATE_STEP,
            pitch,
            zoom,
          });

          return;
        }
      }, ROTATE_INTERVAL);
    }

    if (!autoRotateEnabled && timerId) {
      window.clearInterval(timerId);
      timerId = null;
    }
  }, [autoRotateEnabled, viewer, autoRotateMode, media.type]);

  const Icon = useMemo<typeof Play | typeof Pause>(() => {
    return autoRotateEnabled ? Pause : Play;
  }, [autoRotateEnabled]);

  return (
    <div
      className={`${styles.container} ${styles.className}`}
      onClick={() => setAutoRotateEnabled(!autoRotateEnabled)}
    >
      <Icon />
    </div>
  );
}
