import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  PreloadedState,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { placesApi } from '../api/places';
import { statsApi } from '../api/stats';
import uiReducer from './slices/ui';
import mediaReducer from './slices/media';

const rootReducer = combineReducers({
  [placesApi.reducerPath]: placesApi.reducer,
  [statsApi.reducerPath]: statsApi.reducer,
  ui: uiReducer,
  media: mediaReducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(placesApi.middleware, statsApi.middleware),
    preloadedState,
  });
}

export const store = setupStore();

setupListeners(store.dispatch);

export type AppDispatch = AppStore['dispatch'];
export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
