import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Place } from '../types/place';

interface GetNearbyPlacesArguments {
  latitude: number;
  longitude: number;
}

export const placesApi = createApi({
  reducerPath: 'placesApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/nearbysearch' }),
  tagTypes: ['Nearby Search'],
  endpoints: (builder) => ({
    getNearbyPlaces: builder.query<
      { food: Place[]; school: Place[]; shopping: Place[] },
      GetNearbyPlacesArguments
    >({
      query: (params) => ({
        url: '',
        params,
      }),
    }),
  }),
});

export const { useGetNearbyPlacesQuery } = placesApi;
