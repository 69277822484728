import { Pano, Image, Video } from './../../../../types/carousel';
import { Carousel } from '../../../../types/carousel';

const mediaTypes = ['pano', 'image', 'video'];

type InitialMediaType =
  | {
      mediaType: 'pano';
      pano: Pano;
    }
  | {
      mediaType: 'image';
      image: Image;
    }
  | {
      mediaType: 'video';
      video: Video;
    };

export const getInitialMedia = (
  initialPanoId: string | undefined,
  carousel: Carousel
): InitialMediaType | undefined => {
  const [firstPano] = carousel.pano;
  const [firstImage] = carousel.image;
  const [firstVideo] = carousel.video;

  const initialPano = carousel.pano.find(
    (pano) => pano.panoid === initialPanoId
  );

  const initialMedia = mediaTypes.includes(carousel.start)
    ? carousel.start
    : 'pano';
  const initialMediaIndex = mediaTypes.indexOf(initialMedia);

  const mediaToCheck = [
    ...mediaTypes.slice(initialMediaIndex),
    ...mediaTypes.slice(0, initialMediaIndex),
  ];

  for (const media of mediaToCheck) {
    if (media === 'pano' && (initialPano || firstPano)) {
      return {
        mediaType: 'pano',
        pano: initialPano ?? firstPano,
      };
    }

    if (media === 'image' && firstImage) {
      return {
        mediaType: 'image',
        image: firstImage,
      };
    }

    if (media === 'video' && firstVideo) {
      return {
        mediaType: 'video',
        video: firstVideo,
      };
    }
  }
};
