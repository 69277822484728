import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';

import { ReactComponent as ArrowIcon } from './arrow.svg';

import styles from './ScrollArrow.module.scss';

interface ScrollArrowProps {
  className?: string;
  direction: 'left' | 'right';
  accessibility: boolean;
  show: boolean;
}

export default function ScrollArrow({
  className,
  direction,
  accessibility,
  show,
}: ScrollArrowProps) {
  const left = direction === 'left';

  const animation = useSpring({
    opacity: show ? 1 : 0,
  });

  return (
    <animated.div
      style={animation}
      className={clsx(styles.wrapper, className)}
      aria-hidden={accessibility ? 'true' : 'false'}
      tabIndex={accessibility ? -1 : 0}
    >
      <ArrowIcon
        style={{
          transform: left ? 'none' : 'rotate(180deg)',
        }}
        className={styles.arrow}
      />
    </animated.div>
  );
}
