import { useCallback } from 'react';

import {
  setCarouselVisible,
  setSidebarVisible,
  setControlsVisible,
} from '../../stores/slices/ui';
import { useAppDispatch } from '../redux';

const HIDE_UI_TIMEOUT = 1000;

export default function useHideUi(keepControlsOpen?: boolean) {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    setTimeout(() => {
      dispatch(setCarouselVisible(false));
      if (!keepControlsOpen) dispatch(setControlsVisible(false));
      dispatch(setSidebarVisible(false));
    }, HIDE_UI_TIMEOUT);
  }, [dispatch, keepControlsOpen]);
}
