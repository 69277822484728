const safeURL = (url?: string) => {
  try {
    return new URL(url!);
  } catch (error) {
    console.error('Could not create an URL ');
    return null;
  }
};

export default safeURL;
