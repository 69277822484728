import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FixedSizeImageSource,
  Video as CarouselVideo,
} from '../../types/carousel';

export interface Image {
  type: 'image';
  imageId: string;
  imageTitle?: string;
  source: FixedSizeImageSource;
}

type ImagePayloadAction = Pick<Image, 'imageId' | 'imageTitle' | 'source'>;

export interface Video {
  type: 'video';
  source: CarouselVideo;
}

export interface Pano {
  type: 'pano';
}

type State = Image | Video | Pano;

const mediaSlice = createSlice({
  initialState: { type: 'pano' } as State,
  name: 'media',
  reducers: {
    showImage: (state, action: PayloadAction<ImagePayloadAction>) => ({
      type: 'image',
      imageId: action.payload.imageId,
      imageTitle: action.payload.imageTitle,
      source: action.payload.source,
    }),
    showVideo: (state, action: PayloadAction<CarouselVideo>) => ({
      type: 'video',
      source: action.payload,
    }),
    showPano: (state) => ({ type: 'pano' }),
  },
});

export const { showImage, showVideo, showPano } = mediaSlice.actions;
export default mediaSlice.reducer;
