import { Tour } from '../../../../../../types';
import useIsMobile from '../../../../../../hooks/useIsMobile/useIsMobile';
import useShareLinks from '../../../../../../hooks/useShareLinks/useShareLinks';

import styles from './ShareMenuContent.module.scss';

interface ShareMenuContentProps {
  tour: Tour;
}

export default function ShareMenuContent({ tour }: ShareMenuContentProps) {
  const { encodedShareLink, shareLink } = useShareLinks(tour);
  const isMobile = useIsMobile();
  const isLandscape = useIsMobile('landscape');

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>Share this tour...</span>
      </div>
      <div className={styles.icons}>
        {tour.share.button.facebook ? (
          <div className={styles.linkContainer}>
            <a
              className={styles.socialMediaButton}
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodedShareLink}`}
              target="_blank"
              rel="noreferrer"
              aria-label="Share tour on Facebook"
            >
              <i
                className={`${styles.fontAwesomeIcon} fa fa-facebook-square`}
              ></i>
            </a>
          </div>
        ) : null}
        {tour.share.button.linkedIn ? (
          <div className={styles.linkContainer}>
            <a
              aria-label="Share tour on LinkedIn"
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedShareLink}`}
              className={styles.socialMediaButton}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className={`${styles.fontAwesomeIcon} fa fa-linkedin-square`}
              ></i>
            </a>
          </div>
        ) : null}
        {tour.share.button.twitter ? (
          <a
            aria-label="Share tour on Twitter"
            className={styles.socialMediaButton}
            href={`https://twitter.com/intent/tweet?url=${encodedShareLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <i className={`${styles.fontAwesomeIcon} fa fa-twitter-square`}></i>
          </a>
        ) : null}
      </div>
      <div
        className={
          isMobile && isLandscape ? styles.copyLinkLandscape : styles.copyLink
        }
      >
        <input
          className={styles.shareLink}
          type="text"
          value={shareLink}
        ></input>
      </div>
    </div>
  );
}
