import { useState } from 'react';

import { ReactComponent as Facebook } from '../../../../../../assets/facebook.svg';
import { ReactComponent as Linkedin } from '../../../../../../assets/linkedin.svg';
import { ReactComponent as Twitter } from '../../../../../../assets/twitter.svg';

import { Tour } from '../../../../../../types';
import { useClipboard } from '../../../../../../hooks/useClipboard/useClipboard';
import useShareLinks from '../../../../../../hooks/useShareLinks/useShareLinks';

import styles from './ShareMenuContent.module.scss';

interface ShareMenuContentProps {
  tour: Tour;
}

export default function ShareMenuContent({ tour }: ShareMenuContentProps) {
  const [shareLinkMessage, setShareLinkMessage] = useState('Copy Link');
  const { encodedShareLink, shareLink } = useShareLinks(tour);
  const { isClipboardEnabled, writeTextToClipboard } = useClipboard();

  const copyToClipboard = () => {
    writeTextToClipboard?.(shareLink).then(() => {
      setShareLinkMessage('Copied');

      setTimeout(() => {
        setShareLinkMessage('Copy');
      }, 3000);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.icons}>
        {tour.share.button.facebook ? (
          <div className={styles.linkContainer}>
            <a
              aria-label="Share tour on Facebook"
              className={styles.socialMediaButton}
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodedShareLink}`}
              target="_blank"
              rel="noreferrer"
            >
              <Facebook />
            </a>
            <span className={styles.linkLabel}>Facebook</span>
          </div>
        ) : null}
        {tour.share.button.linkedIn ? (
          <div className={styles.linkContainer}>
            <a
              aria-label="Share tour on LinkedIn"
              className={styles.socialMediaButton}
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedShareLink}`}
              target="_blank"
              rel="noreferrer"
            >
              <Linkedin />
            </a>
            <span className={styles.linkLabel}>LinkedIn</span>
          </div>
        ) : null}
        {tour.share.button.twitter ? (
          <div className={styles.linkContainer}>
            <a
              aria-label="Share tour on Twitter"
              className={styles.socialMediaButton}
              href={`https://twitter.com/intent/tweet?url=${encodedShareLink}`}
              target="_blank"
              rel="noreferrer"
            >
              <Twitter />
            </a>
            <span className={styles.linkLabel}>Twitter</span>
          </div>
        ) : null}
      </div>
      <div className={styles.shareLinkContainer}>
        <div className={styles.shareLink}>{shareLink}</div>
        {isClipboardEnabled && (
          <button className={styles.button} onClick={copyToClipboard}>
            {shareLinkMessage}
          </button>
        )}
      </div>
    </div>
  );
}
