import { useEffect, useMemo, useReducer } from 'react';
import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';
import { debounce } from 'lodash';

import useURLParam, {
  OverlayURLParam,
} from '../../../../hooks/useURLParam/useURLParam';
import { CarouselItemType } from '../../../../types/carousel';
import { Theme } from '../../../../types/theme';
import { Tour } from '../../../../types';
import { useAppSelector } from '../../../../hooks/redux';
import checkForUnitInfo from '../../../../utils/checkForUnitInfo/checkForUnitInfo';
import hexToRGB, { HEX } from '../../../../utils/hexToRGB/hexToRGB';
import MediaTypeSelector from '../MediaTypeSelector/MediaTypeSelector';
import Menu from '../Menu/Menu';
import SceneSelector from '../SceneSelector/SceneSelector';
import ShareLive from '../../../../components/ShareLive/ShareLive';
import UnitTourInfo from '../UnitTourInfo/UnitTourInfo';
import useDetectOverflow from '../../hooks/useDetectOverflow/useDetectOverflow';
import useMediaQuery from '../../../../hooks/useMediaQuery/useMediaQuery';
import ViewControls from '../ViewControls/ViewControls';

import styles from './BottomBar.module.scss';

import { ReactComponent as ShareLiveIcon } from './assets/share.svg';

interface BottomBarProps {
  availableTabs: CarouselItemType[];
  backgroundColor: string;
  theme: Theme;
  tour: Tour;
  floorPlanModeEnabled: boolean;
}

const EmptyIcon = () => null;

enum LayoutState {
  FITS_ALL = 0,
  MOVE_SHARE,
  MOVE_SHARE_AND_BUTTONS,
  MOVE_ALL,
}
const LAYOUT_REPAINT_TIMEOUT = 10; /* ms */

export default function BottomBar({
  tour,
  theme,
  availableTabs,
  backgroundColor,
  floorPlanModeEnabled,
}: BottomBarProps) {
  const hideShareLive = useURLParam(OverlayURLParam.HIDE_SHARE_LIVE) === 'true';
  const hideCarousel = useURLParam(OverlayURLParam.HIDE_CAROUSEL);
  const hideRightMenu = Boolean(useURLParam(OverlayURLParam.HIDE_RIGHT_MENU));
  const RGBABackgroundColor = hexToRGB(backgroundColor as HEX, 0.85);
  const isMobile = useMediaQuery('xs');
  const isShareLiveIconVisible = !useMediaQuery('lg');
  const media = useAppSelector((s) => s.media);
  const isCarouselEnabled = !(hideCarousel || tour.carousel?.start === 'false');

  const bottomBarAnimation = useSpring({
    bottom: !isMobile ? (media.type === 'pano' ? 0 : -95) : isMobile ? 0 : 95,
  });

  const isUnitInfoAvailable = checkForUnitInfo(tour.unitTour);

  const bottomRowHasContent =
    !isMobile || !hideShareLive || isUnitInfoAvailable;

  const [layoutState, transitionLayout] = useReducer((l) => {
    // increment layout state, up to the maximum value
    return Math.min(LayoutState.MOVE_ALL, l + 1);
  }, LayoutState.FITS_ALL);

  const [bottomRowRef, isOverflowing] = useDetectOverflow(transitionLayout);
  const checkOverflow = useMemo(
    () =>
      debounce(() => {
        if (isOverflowing.current) {
          transitionLayout();
        }
      }, LAYOUT_REPAINT_TIMEOUT),
    [isOverflowing]
  );
  // dependency on layoutState because this should run on every transition
  // debounce makes sure the effect waits to repaint before checking again
  useEffect(() => {
    checkOverflow();
    return () => checkOverflow.cancel();
  }, [layoutState, checkOverflow]);

  return (
    <animated.div
      style={bottomBarAnimation}
      className={clsx(styles.bottomBar, {
        [styles.singleRow]: !bottomRowHasContent,
      })}
      data-cy="bottom-bar"
    >
      <div className={clsx(styles.topRow)}>
        {isCarouselEnabled && (
          <>
            <MediaTypeSelector
              availableTabs={availableTabs}
              config={tour.carousel}
            />
            {layoutState === LayoutState.MOVE_ALL && (
              <SceneSelector
                className={styles.topRowSceneSelector}
                config={tour.carousel}
              />
            )}
          </>
        )}

        {/* TODO: limit hamburger contents to only share live for LayoutState.MOVE_SHARE */}
        <ViewControls
          theme={theme}
          tour={tour}
          showHamburger={!hideRightMenu && layoutState !== LayoutState.FITS_ALL}
        />
      </div>
      {bottomRowHasContent && (
        <div
          ref={bottomRowRef}
          className={clsx(styles.bottomRow, {
            [styles.compactLayout]: layoutState === LayoutState.MOVE_ALL,
          })}
          style={{
            backgroundColor: RGBABackgroundColor,
          }}
        >
          {layoutState !== LayoutState.MOVE_ALL && (
            <div className={clsx(styles.bottomRowLeftContent)}>
              {isCarouselEnabled && (
                <SceneSelector
                  className={styles.bottomRowSceneSelector}
                  config={tour.carousel}
                />
              )}
              {layoutState < LayoutState.MOVE_SHARE_AND_BUTTONS && (
                <Menu theme={theme} tour={tour} />
              )}
            </div>
          )}
          <div className={clsx(styles.bottomRowRightContent)}>
            {!hideShareLive && layoutState < LayoutState.MOVE_SHARE ? (
              <ShareLive
                className={styles.shareLive}
                clientId={tour.client._id}
                tourId={tour._id}
                themeId={tour.themeId}
                icon={isShareLiveIconVisible ? ShareLiveIcon : EmptyIcon}
              />
            ) : null}
            {tour.tourType !== 'property' && isUnitInfoAvailable && (
              <UnitTourInfo
                unitTour={tour.unitTour}
                joinPrimaryDetails={isMobile}
                hideUnitInformation={floorPlanModeEnabled}
              />
            )}
          </div>
        </div>
      )}
    </animated.div>
  );
}
