import React, { useState } from 'react';
import { ReactComponent as ADAon } from './assets/accessibility-on.svg';
import { ReactComponent as ADAoff } from './assets/accessibility-off.svg';
import styles from './Accessibility.module.scss';
import audioOn from './assets/sound/ada-on.mp3';
import audioOff from './assets/sound/ada-off.mp3';

interface AccessibilityIconProps {
  toggled: boolean;
}

const AccessibilityIcon = ({ toggled }: AccessibilityIconProps) => {
  return (
    <>
      <ADAon className={`${styles.icon} ${!toggled ? '' : styles.off}`} />
      <ADAoff className={`${styles.icon} ${toggled ? '' : styles.off}`} />
    </>
  );
};

interface AccessibilityProps {
  accessibilityToggle: Function;
}

const Accessibility = ({ accessibilityToggle }: AccessibilityProps) => {
  const [onOff, setOnOff] = useState(true);
  const [audioPlayOn] = useState(new Audio(audioOn));
  const [audioPlayOff] = useState(new Audio(audioOff));

  const onClick = () => {
    onOff ? audioPlayOn.play() : audioPlayOff.play();
    setOnOff(!onOff);

    accessibilityToggle();
  };

  return (
    <div id="accessibility" className={styles.container} onClick={onClick}>
      <AccessibilityIcon toggled={onOff} />
    </div>
  );
};

export { Accessibility as default, AccessibilityIcon };
