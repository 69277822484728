import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface UiState {
  controlsVisible: boolean;
  carouselVisible: boolean;
  sceneListVisible?: boolean;
  accessibilityModeEnabled: boolean;
  sidebarVisible?: boolean;
  templateUrl?: string;
}

const initialState = {
  controlsVisible: false,
  carouselVisible: true,
  sceneListVisible: false,
  accessibilityModeEnabled: false,
  sidebarVisible: true,
  templateUrl: '',
} as UiState;

const sessionSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setControlsVisible(state, action: PayloadAction<boolean>) {
      state.controlsVisible = action.payload;
    },
    setCarouselVisible(state, action: PayloadAction<boolean>) {
      state.carouselVisible = action.payload;
    },
    setSceneListVisible(state, action: PayloadAction<boolean>) {
      state.sceneListVisible = action.payload;
    },
    setAccessibilityMode(state, action: PayloadAction<boolean>) {
      state.accessibilityModeEnabled = action.payload;
    },
    setSidebarVisible(state, action: PayloadAction<boolean>) {
      state.sidebarVisible = action.payload;
    },
    setTemplateUrl(state, action: PayloadAction<string>) {
      state.templateUrl = action.payload;
    },
  },
});

export const getControlsVisible = (state: RootState) =>
  state.ui.controlsVisible;

export const getCarouselVisible = (state: RootState) =>
  state.ui.carouselVisible;

export const getSceneListVisible = (state: RootState) =>
  state.ui.sceneListVisible;

export const getAccessibilityMode = (state: RootState) =>
  state.ui.accessibilityModeEnabled;

export const getSidebarVisible = (state: RootState) => state.ui.sidebarVisible;

export const getTemplateUrl = (state: RootState) => state.ui.templateUrl;

const { actions, reducer } = sessionSlice;

export const {
  setControlsVisible,
  setCarouselVisible,
  setSceneListVisible,
  setAccessibilityMode,
  setSidebarVisible,
  setTemplateUrl,
} = actions;

export default reducer;
