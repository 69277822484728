import { useCallback, useMemo } from 'react';
import { Image } from '../../../src/types/carousel';
import { useAppDispatch } from '../../hooks/redux';
import { showImage } from '../../stores/slices/media';
import { useAppSelector } from '../../hooks/redux';

export const useNavigateToPhoto = (
  direction: 'left' | 'right',
  image: Image[]
) => {
  const dispatch = useAppDispatch();
  const media = useAppSelector((s) => s.media);

  const currentImageId = media.type === 'image' ? media.imageId : '';

  const currentImageIndex = image.findIndex(
    (img) => img.imageId === currentImageId
  );
  let nextImageIndex =
    direction === 'left' ? currentImageIndex - 1 : currentImageIndex + 1;

  if (nextImageIndex > image.length - 1) {
    nextImageIndex = 0;
  }

  if (nextImageIndex < 0) {
    nextImageIndex = image.length - 1;
  }

  const nextImage = image[nextImageIndex];

  const navigateToPhoto = useCallback(() => {
    if (!nextImage) return;

    dispatch(
      showImage({
        imageId: nextImage.imageId,
        imageTitle: nextImage.title,
        source: nextImage.image,
      })
    );
  }, [dispatch, nextImage]);

  return useMemo(
    () => ({
      nextImage,
      navigateToPhoto,
    }),
    [navigateToPhoto, nextImage]
  );
};
