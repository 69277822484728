import { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  // this function must be provided to tell react to recreate component tree on error
  public static getDerivedStateFromError(_: Error) {}

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
