import { useMemo } from 'react';
import { Tour } from '../../types';

const PANOSKIN_SHARE_DOMAIN = 'https://panosk.in';
const TOURBUILDER_SHARE_DOMAIN = 'https://tour.tourbuilder.com';

function getShareDomain(tour: Tour) {
  if (tour.overlayVersion === 1) {
    return PANOSKIN_SHARE_DOMAIN;
  }

  return TOURBUILDER_SHARE_DOMAIN;
}

const SHARE_LINK_SOURCE_ID = '1001';

export default function useShareLinks(tour: Tour) {
  return useMemo(() => {
    const rawLink = tour.share.useCustomUrl
      ? tour.share.link
      : getShareDomain(tour);

    let url;

    try {
      url = new URL(rawLink);
    } catch (err) {
      console.error(err);

      url = new URL(getShareDomain(tour));
    }

    url.searchParams.set('tour', tour._id);
    url.searchParams.set('sourceId', SHARE_LINK_SOURCE_ID);

    return {
      shareLink: url.toString(),
      encodedShareLink: encodeURIComponent(url.toString()),
    };
  }, [tour]);
}
