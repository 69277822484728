import { useCallback, useMemo } from 'react';
import { Video } from '../../../src/types/carousel';
import { useAppDispatch } from '../../hooks/redux';
import { showVideo } from '../../stores/slices/media';
import { useAppSelector } from '../../hooks/redux';

export const useNavigateToVideo = (
  direction: 'left' | 'right',
  video: Video[]
) => {
  const dispatch = useAppDispatch();
  const media = useAppSelector((s) => s.media);

  const currentVideoId = media.type === 'video' ? media.source.videoId : '';

  const currentVideoIndex = video.findIndex(
    (vid) => vid.videoId === currentVideoId
  );
  let nextVideoIndex =
    direction === 'left' ? currentVideoIndex - 1 : currentVideoIndex + 1;

  if (nextVideoIndex > video.length - 1) {
    nextVideoIndex = 0;
  }

  if (nextVideoIndex < 0) {
    nextVideoIndex = video.length - 1;
  }

  const nextVideo = video[nextVideoIndex];

  const navigateToVideo = useCallback(() => {
    if (!nextVideo) return;

    dispatch(showVideo(nextVideo));
  }, [dispatch, nextVideo]);

  return useMemo(
    () => ({
      nextVideo,
      navigateToVideo,
    }),
    [nextVideo, navigateToVideo]
  );
};
