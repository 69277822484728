import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { ReactComponent as ArrowIcon } from './assets/caret.svg';
import styles from './ScrollArrow.module.scss';
import { animated, useSpring } from '@react-spring/web';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';

const SCROLL_DISTANCE = 400;

interface ScrollArrowProps {
  direction: 'left' | 'right';
  scrollRef: RefObject<HTMLElement>;
}

export default function ScrollArrow({
  direction,
  scrollRef,
}: ScrollArrowProps) {
  const windowSize = useWindowSize();
  const left = direction === 'left';

  const [show, setShow] = useState(!left);
  const animation = useSpring({
    opacity: show ? 1 : 0,
  });
  const calculateVisibility = useCallback(
    (event: Event) => {
      const el = event.target as HTMLElement;
      const position = el.scrollLeft;

      if (left) {
        setShow(position !== 0);
      } else {
        setShow(position !== el.scrollWidth - el.clientWidth);
      }
    },
    [left]
  );
  useEffect(() => {
    const el = scrollRef.current;

    el?.addEventListener('scroll', calculateVisibility);
    return () => el?.removeEventListener('scroll', calculateVisibility);
  }, [scrollRef, calculateVisibility]);

  useEffect(() => {
    if (left || !scrollRef.current) return;
    const images = scrollRef.current?.children || [];
    const totalWidth = Array.from(images).reduce(
      (acc, image) => (acc += image.getBoundingClientRect().width),
      0
    );
    setShow(totalWidth >= scrollRef.current?.getBoundingClientRect().width);
  }, [left, scrollRef, windowSize]);

  return (
    <animated.div
      style={animation}
      className={styles.wrapper}
      onClick={() => {
        scrollRef.current?.scrollBy({
          left: left ? -SCROLL_DISTANCE : SCROLL_DISTANCE,
          behavior: 'smooth',
        });
      }}
    >
      <ArrowIcon
        style={{
          transform: left ? 'rotate(180deg)' : 'none',
          marginLeft: left ? 0 : '3px',
          marginRight: left ? '3px' : 0,
        }}
      />
    </animated.div>
  );
}
