import { Tour } from '../../../../../../types';

import useIsMobile from '../../../../../../hooks/useIsMobile/useIsMobile';

import AccessibilityStatement from '../../../../../../components/StartScreen/components/AccessibilityStatement';
import Image from '../../../../../../components/Image/Image';
import { ReactComponent as Inquiry } from './assets/inquiry.svg';
import { ReactComponent as Schedule } from './assets/schedule.svg';

import { pluralize } from '../../../../../../utils/pluralize/pluralize';

import styles from './InfoMenuContent.module.scss';

interface InfoMenuContentProps {
  tour: Tour;
}

const ZERO_BEDS_LABEL = 'Studio';

export default function InfoMenuContent({ tour }: InfoMenuContentProps) {
  const floorplanConfig = tour.floorplan[0];
  const isMobile = useIsMobile();
  const isMobileLandscape = useIsMobile('landscape');
  const tourInfo = tour.unitTour;
  // this will be populated in the future
  const feauresList = false;

  const bedsAndBaths = [
    ...(typeof tourInfo.numBed === 'number'
      ? tourInfo.numBed === 0
        ? [ZERO_BEDS_LABEL]
        : [`${tourInfo.numBed} ${pluralize('bed', tourInfo.numBed)}`]
      : []),

    ...(!!tourInfo.numBath
      ? [`${tourInfo.numBath} ${pluralize('bath', tourInfo.numBath)}`]
      : []),
  ];

  return (
    <div
      className={`${styles.container} ${
        isMobileLandscape ? styles.mobileLandscape : ''
      }`}
    >
      <div className={styles.topContent}>
        <div className={styles.unitName}>{tour.title}</div>
        <div className={styles.unitInfo}>
          <p>Floorplan {tourInfo.floorPlanType}</p>
          <p>Unit {tourInfo.unitNumber}</p>
          <p>Building {tourInfo.buildingName}</p>
        </div>
        <div className={styles.unitInfo}>
          <p>{bedsAndBaths}</p>
          {tourInfo.unitSize ? (
            <p>
              {tourInfo.unitSize.num}{' '}
              {tourInfo.unitSize.unitOfArea === 'ft2' ? 'sq.ft' : 'sq.m'}
            </p>
          ) : null}
        </div>
      </div>

      <div className={styles.bottomContent}>
        {floorplanConfig ? (
          <Image
            alt="Floor plan"
            className={styles.floorplanImage}
            src={floorplanConfig.img || floorplanConfig.image!.url}
          />
        ) : null}
      </div>

      {!feauresList && (
        <div className={styles.unitFeatures}>
          <div className={styles.heading}>Unit Feautres</div>
          <div className={styles.featuresList}>
            Cable TV ready, Dishwasher, Hardwood flooring, Garbage disposal,
            Washer/dryer, Microwave oven, Refrigerator, High-speed internet
            ready, Tile flooring, Air conditioning, Patio/balcony, Range,
            Heating: Yes
          </div>
        </div>
      )}
      {isMobile && !isMobileLandscape && (
        <>
          <AccessibilityStatement />
          <div className={styles.buttonContainer}>
            <button>
              <Inquiry />
              Inquiry
            </button>
            <button>
              <Schedule />
              Schedule
            </button>
          </div>
        </>
      )}
    </div>
  );
}
