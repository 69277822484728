import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';

import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as MobileArrowIcon } from './assets/arrow_mobile.svg';

import styles from './ScrollArrow.module.scss';

interface ScrollArrowProps {
  direction: 'up' | 'down';
  className?: string;
  show: boolean;
}

export default function ScrollArrow({
  className,
  direction,
  show,
}: ScrollArrowProps) {
  const up = direction === 'up';
  const isMobile = useIsMobile();

  const animation = useSpring({
    opacity: show ? 1 : 0,
  });

  return (
    <animated.div className={clsx(styles.wrapper, className)} style={animation}>
      {!isMobile ? (
        <ArrowIcon
          style={{
            transform: up ? 'rotate(270deg)' : 'rotate(90deg)',
          }}
        />
      ) : (
        <MobileArrowIcon
          style={{
            transform: !up ? '' : 'rotate(180deg)',
          }}
        />
      )}
    </animated.div>
  );
}
