import React, { useEffect, useState, ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: ReactNode;
  className?: string;
}

const Portal = React.forwardRef<HTMLDivElement, PortalProps>(
  ({ children, className = '' }, ref) => {
    const [container] = useState(document.createElement('div'));

    useEffect(() => {
      const viewport = document.getElementById('theme');

      if (!viewport) return;

      viewport.appendChild(container);
      return () => {
        viewport.removeChild(container);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ReactDOM.createPortal(
      <div
        className={className}
        ref={ref}
        onClick={(e) => e.stopPropagation()}
        style={{ position: 'fixed' }}
      >
        {children}
      </div>,
      container
    );
  }
);

export default Portal;
