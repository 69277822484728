import useIsMobile from '../useIsMobile/useIsMobile';
import { useCallback } from 'react';

export default function useNativeShare() {
  const isMobile = useIsMobile();

  return useCallback(
    (url: string, fallback: () => void | Promise<void>) => {
      const shareData = { url };
      // the optional chaining is because this method only exists in localhost and https
      if (isMobile && navigator.canShare?.(shareData)) {
        return navigator.share(shareData);
      }
      return Promise.resolve(fallback());
    },
    [isMobile]
  );
}
