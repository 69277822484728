import { isIframed } from './../../utils/isIframed/isIframed';

export default function getSource() {
  if (!isIframed()) {
    return 'direct';
  }

  try {
    const referrerUrl = new URL(document.referrer);

    return referrerUrl.hostname;
  } catch (error) {}

  return 'website';
}
