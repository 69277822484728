import { pluralize } from '../../../../utils/pluralize/pluralize';

import styles from './Stats.module.scss';

import { ReactComponent as Likes } from './assets/heart.svg';
import { ReactComponent as Visits } from './assets/visits.svg';

import Image from '../../../../components/Image/Image';
import useTourStats from '../../../../hooks/useTourStats/useTourStats';
import { Tour } from '../../../../types';
import useLocalStorage from '../../../../hooks/useLocalStorage/useLocalStorage';
import { StatIndex } from '../../../../api/stats';
import { makeStatID, STATS_ID } from '../../../../constants/ids';

interface StatProps {
  icon: React.ReactNode;
  label: string;
  indicatorSrc?: string;
  disabled?: boolean;
  onClick?: () => void;
  id?: string;
}

const NOOP = () => {};

const Stat = ({
  icon,
  label,
  indicatorSrc,
  onClick,
  disabled,
  id,
}: StatProps) => {
  return (
    <div className={styles.stat} id={id}>
      <div className={styles.statDetails}>
        <div
          className={`${styles.statIcon} ${onClick ? styles.clickable : ''} ${
            disabled ? styles.disabled : ''
          }`}
          onClick={disabled ? NOOP : onClick}
          title={disabled ? 'It has already been clicked' : ''}
        >
          {icon}
        </div>
        <div className={styles.statLabel}>{label}</div>
      </div>
      {indicatorSrc && (
        <div className={styles.statIndicator}>
          <Image dataCy="stat-indicator" src={indicatorSrc} />
        </div>
      )}
    </div>
  );
};

interface StatsProps {
  tour: Tour;
}

const LOCAL_STORAGE_LIKES_KEY = 'likes-clicked';

export default function Stats({ tour }: StatsProps) {
  const {
    increaseTourStat,
    stats: {
      total: { likes, visits },
    },
  } = useTourStats(tour);
  const [isLikesClicked, setIsLikesClicked] = useLocalStorage(
    `${LOCAL_STORAGE_LIKES_KEY}-${tour._id}`
  );

  function handleLikesClick() {
    increaseTourStat(StatIndex.Likes);
    setIsLikesClicked('true');
  }

  return (
    <div className={styles.stats} id={STATS_ID}>
      <Stat
        id={makeStatID('visits')}
        icon={<Visits />}
        label={`${visits} ${pluralize('Visit', visits)}`}
      />
      <Stat
        id={makeStatID('saves')}
        icon={<Likes />}
        label={`${likes} ${pluralize('Save', likes)}`}
        onClick={handleLikesClick}
        disabled={Boolean(isLikesClicked)}
      />
    </div>
  );
}
