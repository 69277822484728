import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './ControlGroup.module.scss';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import onEnter from '../../../../utils/onEnter/onEnter';

export interface Control {
  display: ReactNode;
  onClick(): void;
}

interface ControlsProps {
  controls: Control[];
  className?: string;
  controlClassName?: string;
  dataCy?: string;
  style?: CSSProperties;
  accessibility?: boolean;
}

export default function ControlGroup({
  controls,
  className = '',
  controlClassName = '',
  dataCy,
  style,
}: ControlsProps) {
  const [, rerender] = useState({});
  const ref = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const isMobile = useIsMobile();

  // Some calculations need to be redone when DOM elements will change, but not trigger React re-render
  useEffect(() => {
    rerender({});
  }, [windowSize]);

  const isScrollable = useMemo<boolean>(() => {
    if (!ref || !ref.current) return false;

    const height = ref.current.clientHeight;
    const contentHeight = ref.current.scrollHeight;

    return contentHeight > height;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, windowSize]);

  return (
    <div
      className={`${styles.container} ${
        isScrollable ? styles.scrollable : ''
      } ${className}`}
      ref={ref}
      style={style}
    >
      {controls.map(({ onClick, display }, idx) => {
        if (display === 'map') {
          return (
            <div
              role="button"
              className={`${styles.control} ${controlClassName}`}
              key={idx}
              onClick={onClick}
              onKeyDown={onEnter(onClick)}
              data-cy={dataCy}
              tabIndex={0}
            >
              {display}
            </div>
          );
        } else {
          return (
            <div
              role="button"
              className={`${styles.control} ${controlClassName}`}
              key={idx}
              onClick={onClick}
              onKeyDown={onEnter(onClick)}
              data-cy={dataCy}
              tabIndex={0}
            >
              {display}
            </div>
          );
        }
      })}
    </div>
  );
}
