import React, { useMemo } from 'react';
import clsx from 'clsx';

import { getAccessibilityMode } from '../../../../stores/slices/ui';
import { MenuItemType } from '../../../../types';
import { Theme } from '../../../../types/theme';
import { Tour } from '../../../../types';
import { useAppSelector } from '../../../../hooks/redux';
import { useFullscreen } from '../../../../hooks/useFullscreen/useFullscreen';
import ControlGroup from '../ControlGroup/ControlGroup';
import useTourFunctionality from '../../../../hooks/useTourFunctionality/useTourFunctionality';

import styles from './Menu.module.scss';

interface MenuButtonProps {
  children?: React.ReactNode;
  text: string;
  icon: string;
  id: string;
}

const FullscreenButton = ({
  text,
  icon,
  id,
}: Omit<MenuButtonProps, 'children'>) => {
  const { isFullscreen } = useFullscreen();

  return (
    <MenuButton text={text} icon={icon} id={id}>
      <span
        className={
          isFullscreen
            ? styles['icon-closefullscreen']
            : styles['icon-fullscreen']
        }
      />
    </MenuButton>
  );
};

const MenuButton = ({ children, text, icon, id }: MenuButtonProps) => {
  return (
    <div key={id} className={`${styles.button} legacy-menu-item`}>
      {Boolean(children) ? (
        children
      ) : (
        <i
          className={clsx('fa', icon, styles.icon, styles[icon])}
          style={{ fontSize: '1.1em' }}
          aria-hidden="true"
        ></i>
      )}
      <div className={styles.text}>{text}</div>
    </div>
  );
};

interface MenuProps {
  theme: Theme;
  tour: Tour;
  onMenuButtonClick(id: string, type: MenuItemType, conversion: boolean): void;
}

export default function Menu({ theme, tour, onMenuButtonClick }: MenuProps) {
  const { availableButtons } = useTourFunctionality(tour, theme);

  const accessibilityModeEnabled = useAppSelector(getAccessibilityMode);

  const menuControls = useMemo(() => {
    return availableButtons
      .filter((button) => !accessibilityModeEnabled || button.id !== 'map')
      .map((button) => {
        const Button =
          button.id === 'fullscreen' ? FullscreenButton : MenuButton;

        return {
          display: (
            <Button text={button.text} icon={button.icon} id={button.id} />
          ),
          onClick: () =>
            onMenuButtonClick(button.id, button.type, button.conversion),
        };
      });
  }, [availableButtons, onMenuButtonClick, accessibilityModeEnabled]);

  const isSingle = menuControls.length === 1;

  return (
    <ControlGroup
      className={`${styles.menuButtons} ${
        isSingle ? styles.menuButtonsSingle : ''
      }`}
      controlClassName={styles.menuButtonControl}
      controls={menuControls}
      style={{ maxHeight: 'var(--menu-buttons-max-height)' }}
    />
  );
}
