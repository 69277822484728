const appendParamsToUrl = (
  url: string | URL,
  params: Record<string, string | null>
) => {
  const parsedUrl = new URL(
    url.toString().startsWith('http') ? url : `https://${url}`
  );

  Object.entries(params).forEach(([param, value]) => {
    if (value) {
      parsedUrl.searchParams.set(param, value);
    }
  });

  return parsedUrl;
};

export default appendParamsToUrl;
