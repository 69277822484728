import { useMemo } from 'react';
import clsx from 'clsx';

import { ReactComponent as SideArrow } from './assets/sideArrow.svg';
import { ReactComponent as BottomArrow } from './assets/bottomArrow.svg';
import { ReactComponent as SideMobileArrow } from './assets/sideMobileArrow.svg';

import { ArrowVariant } from '../../types';

import styles from './MediaArrow.module.scss';

interface MediaArrowProps {
  direction: 'right' | 'left';
  arrowVariant: ArrowVariant;
  onNavigate?: () => void;
  className?: string;
}

export default function MediaArrow({
  direction,
  arrowVariant,
  onNavigate,
  className = '',
}: MediaArrowProps) {
  const ArrowImage = useMemo(() => {
    switch (arrowVariant) {
      case 'side':
        return SideArrow;
      case 'side-mobile':
        return SideMobileArrow;
      case 'bottom':
        return BottomArrow;
      default:
        return SideArrow;
    }
  }, [arrowVariant]);

  return (
    <div
      onClick={onNavigate}
      className={clsx(
        styles['circle-' + arrowVariant],
        direction === 'left'
          ? styles['circleLeft-' + arrowVariant]
          : styles['circleRight-' + arrowVariant],
        className
      )}
    >
      {direction === 'left' && (
        <ArrowImage
          className={clsx(
            styles['arrow-' + arrowVariant],
            styles['arrowLeft-' + arrowVariant]
          )}
          data-cy="left-nav-arrow"
        />
      )}
      {direction === 'right' && (
        <ArrowImage
          className={clsx(
            styles['arrow-' + arrowVariant],
            styles['arrowRight-' + arrowVariant]
          )}
          data-cy="right-nav-arrow"
        />
      )}
    </div>
  );
}
