import React from 'react';
import useHotspots from '../../../../hooks/useHotspots/useHotspots';
import usePolygons from '../../../../hooks/usePolygons/usePolygons';
import FloorPlan from '../../../../components/FloorPlan/FloorPlan';
import { Tour } from '../../../../types';
import useRecoil from '../../../../hooks/useRecoil/useRecoil';
import InfoHotspotModal from '../../../../components/InfoHotspotModal/InfoHotspotModal';
import VideoHotspotModal from '../../../../components/VideoHotspotModal/VideoHotspotModal';
import { Theme } from '../../../../types/theme';

interface PanoProps {
  hideRightMenu?: boolean;
  tour: Tour;
  theme: Theme;
}

export default function Pano({ hideRightMenu, tour, theme }: PanoProps) {
  useRecoil();
  const [hotspotData, clearHotspotData] = useHotspots(
    tour.hotspots,
    theme.styles.hotspots
  );
  usePolygons(tour.polygons);

  return (
    <>
      <FloorPlan configs={tour.floorplan} sliders={tour.sliders} />
      {hotspotData?.type === 'info' ? (
        <InfoHotspotModal data={hotspotData} onClose={clearHotspotData} />
      ) : null}
      {hotspotData?.type === 'video' ? (
        <VideoHotspotModal data={hotspotData} onClose={clearHotspotData} />
      ) : null}
    </>
  );
}
