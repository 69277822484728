import React from 'react';
import styles from './AccessibilityStatement.module.scss';
import { ReactComponent as AccessibilityIcon } from '../assets/accessibility-icon.svg';

export default function AccessibilityStatement() {
  return (
    <>
      <a
        className={styles.accessibilityStatement}
        href="https://www.tourbuilder.com/accessibility-statement"
        target="_blank"
        rel="noreferrer"
      >
        <AccessibilityIcon />
        Accessibility Statement
      </a>
    </>
  );
}
