import { animated, useSpring } from '@react-spring/web';
import { ReactNode, useRef } from 'react';

import hexToRGB, { HEX } from '../../../../../../utils/hexToRGB/hexToRGB';
import onEnter from '../../../../../../utils/onEnter/onEnter';
import useIsMobile from '../../../../../../hooks/useIsMobile/useIsMobile';
import useTheme from '../../../../../../hooks/useTheme/useTheme';

import styles from './MenuDialog.module.scss';

interface MenuDialogProps {
  onClose: () => void;
  children: ReactNode;
}

export default function MenuDialog({ children, onClose }: MenuDialogProps) {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const containerAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <>
      <animated.div style={containerAnimation} className={styles.container}>
        <div
          className={`${styles.menuDialog} ${
            isMobile ? styles.menuDialogMobile : ''
          }`}
          ref={ref}
          style={{
            backgroundColor: hexToRGB(
              theme.styles.modal.background as HEX,
              !isMobile ? 0.85 : 0.6
            ),
          }}
        >
          <i
            className={`${styles.close} fa fa-times active`}
            aria-label="Close modal"
            onClick={onClose}
            onKeyDown={onEnter(onClose)}
            data-cy="close-modal"
            tabIndex={0}
          ></i>
          {children}
        </div>
      </animated.div>
    </>
  );
}
