import { useState } from 'react';

import { getControlsVisible } from '../../../../stores/slices/ui';
import { Tour } from '../../../../types';
import { useAppSelector } from '../../../../hooks/redux';
import { useFullscreen } from '../../../../hooks/useFullscreen/useFullscreen';
import AutoRotate from '../../../../components/AutoRotate/AutoRotate';
import Fullscreen from './Fullscreen';
import MenuButtons from './MenuButtons';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import useVerticalLayout from '../../hooks/useVerticalLayout/useVerticalLayout';
import useMenuItemContent from '../../hooks/useMenuItemContent/useMenuItemContent';
import useTheme from '../../../../hooks/useTheme/useTheme';
import useTourFunctionality from '../../../../hooks/useTourFunctionality/useTourFunctionality';
import Zoom from './Zoom';

interface ControlPanelProps {
  tour: Tour;
}

export default function ControlPanel({ tour }: ControlPanelProps) {
  const theme = useTheme();
  const [autoRotateEnabled, setAutoRotateEnabled] = useState(tour.autoRotate);
  const { availableButtons, hasFullscreenButton } = useTourFunctionality(
    tour,
    theme
  );
  const { isFullscreenEnabled } = useFullscreen();
  const { openMenuItem } = useMenuItemContent();
  const isMobileLandscape = useIsMobile('landscape');
  const isMobile = useIsMobile();
  const { isViewportTooLow } = useVerticalLayout(tour, true);
  const hamburgerMenuIsOpen = useAppSelector(getControlsVisible);

  return (
    <>
      {hamburgerMenuIsOpen &&
        availableButtons.filter((b) => b.type !== 'fullscreen').length > 0 && (
          <MenuButtons
            tour={tour}
            menuButtons={availableButtons}
            onMenuButtonClick={openMenuItem}
          />
        )}

      {(!hamburgerMenuIsOpen || !isViewportTooLow) && (
        <>
          {!isMobileLandscape && <Zoom />}

          {!isMobile ? (
            <AutoRotate
              tour={tour}
              enabled={autoRotateEnabled}
              onChange={setAutoRotateEnabled}
            />
          ) : null}

          {!isMobile && hasFullscreenButton && isFullscreenEnabled ? (
            <Fullscreen />
          ) : null}
        </>
      )}
    </>
  );
}
