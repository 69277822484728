import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Carousel as Config,
  CarouselItemType,
  Pano as CarouselPano,
  Image as CarouselImage,
  Video as CarouselVideo,
} from '../../../../types/carousel';
import PillGroup, {
  Colors,
  PillConfig,
} from '../../../../components/PillGroup/PillGroup';
import useViewer from '../../../../hooks/useViewer/useViewer';
import { ReactComponent as PanoIcon } from './assets/3d.svg';
import { ReactComponent as ImageIcon } from './assets/photo.svg';
import { ReactComponent as VideoIcon } from './assets/camera.svg';
import { ReactComponent as Caret } from './assets/caret.svg';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  showImage,
  showPano,
  showVideo,
  Image,
  Video,
} from '../../../../stores/slices/media';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import { animated, useSpring, useTransition } from '@react-spring/web';
import ImageComponent from '../../../../components/Image/Image';
import ScrollArrow from './ScrollArrow';
import {
  getCarouselVisible,
  setCarouselVisible,
} from '../../../../stores/slices/ui';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';
import useAnalyticsEvents from '../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import onEnter from '../../../../utils/onEnter/onEnter';
import useHideUi from '../../../../hooks/useHideUi/useHideUi';
import { ReactComponent as FloorPlanIndicator } from './floorplan-indicator.svg';
import { CAROUSEL_ID, TABS_ID } from '../../../../constants/ids';

import styles from './Carousel.module.scss';

interface TabIconProps {
  mobile: ReactNode;
  desktop: ReactNode;
}
function TabIcon({ mobile, desktop }: TabIconProps) {
  const isMobile = useIsMobile();

  return <>{isMobile ? mobile : desktop}</>;
}

interface CarouselItemProps {
  title: string;
  thumbnail: string;
  onClick(): void;
  active: boolean;
  hasFloorplan?: boolean;
}
const CarouselItem = React.memo(
  ({ onClick, thumbnail, title, active, hasFloorplan }: CarouselItemProps) => (
    <figure
      className={styles.item}
      onClick={onClick}
      onKeyDown={onEnter(onClick)}
      data-cy="carousel-item"
      tabIndex={0}
    >
      {hasFloorplan && (
        <FloorPlanIndicator className={styles.floorPlanIndicator} />
      )}
      <ImageComponent src={thumbnail} alt="Carousel item" />
      {active && (
        <div
          className={styles.active}
          data-cy="carousel-active-item-indicator"
        />
      )}
      <figcaption className={styles.title}>
        <span title={title}>{title}</span>
      </figcaption>
    </figure>
  )
);

interface CarouselProps {
  availableTabs: CarouselItemType[];
  config: Config;
  pillColors: Colors;
  floorPlanPanoIds: string[];
}

export default function Carousel({
  availableTabs,
  config,
  pillColors,
  floorPlanPanoIds,
}: CarouselProps) {
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const [pillGroupKey, setPillGroupKey] = useState<number>(0);
  const media = useAppSelector((state) => state.media);
  const visible = useAppSelector(getCarouselVisible);
  const toggleVisible = () => dispatch(setCarouselVisible(!visible));
  const { panoId, viewer } = useViewer();
  const analyticsEvents = useAnalyticsEvents();
  const hideUi = useHideUi();

  // Mount and unmount active pill group to recalculate its dimensions on window resize
  useEffect(() => setPillGroupKey((key) => key + 1), [windowSize]);

  const setPano = useCallback(
    (pano: CarouselPano) => {
      dispatch(showPano());

      if (panoId !== pano.panoid) {
        viewer?.setPano(pano.panoid, { pov: pano.pov });
      }
    },
    [dispatch, panoId, viewer]
  );

  const setImage = useCallback(
    (image: CarouselImage) => {
      if (image.image === (media as Image).source) {
        return;
      }

      analyticsEvents.imageGalleryView(
        image.imageId,
        image.image.url,
        image.title
      );

      dispatch(
        showImage({
          imageId: image.imageId,
          imageTitle: image.title,
          source: image.image,
        })
      );
    },
    [analyticsEvents, dispatch, media]
  );

  const setVideo = useCallback(
    (video: CarouselVideo) => {
      if ((media as Video).source === video) {
        return;
      }

      analyticsEvents.videoGalleryView(video.videoId, video.title);

      dispatch(showVideo(video));
    },
    [analyticsEvents, dispatch, media]
  );

  const carouselRef = useRef<HTMLDivElement>(null);
  const containerAnimation = useSpring({
    translateY: visible ? 0 : carouselRef.current?.clientHeight,
  });

  const pillsTransitions = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const pills: PillConfig<CarouselItemType>[] = useMemo(
    () =>
      [
        ...(config.pano.length > 0
          ? [
              {
                key: 'pano' as CarouselItemType,
                display: (
                  <TabIcon
                    mobile={<PanoIcon aria-label="360° Tours" />}
                    desktop="360° Tours"
                  />
                ),
              },
            ]
          : []),
        ...(config.image.length > 0
          ? [
              {
                key: 'image' as CarouselItemType,
                display: (
                  <TabIcon
                    mobile={<ImageIcon aria-label="Photos" />}
                    desktop="Photos"
                  />
                ),
              },
            ]
          : []),
        ...(config.video.length > 0
          ? [
              {
                key: 'video' as CarouselItemType,
                display: (
                  <TabIcon
                    mobile={<VideoIcon aria-label="Videos" />}
                    desktop="Videos"
                  />
                ),
              },
            ]
          : []),
      ].filter((pill) => availableTabs.includes(pill.key)),
    [availableTabs, config]
  );

  return (
    <animated.div
      style={containerAnimation}
      className={styles.container}
      data-cy="carousel"
      id={CAROUSEL_ID}
    >
      <div className={styles.controls}>
        {pillsTransitions(
          (style, item) =>
            item && (
              <animated.div style={style} className={styles.pills} id={TABS_ID}>
                <PillGroup<CarouselItemType>
                  key={pillGroupKey}
                  colors={pillColors}
                  pills={pills}
                  value={media.type}
                  pillVariant="carousel"
                  onChange={(newItem) => {
                    if (newItem === media.type) return;
                    switch (newItem) {
                      case 'pano':
                        const [firstPano] = config.pano;
                        carouselRef?.current?.scroll(0, 0);
                        setPano(firstPano);
                        break;
                      case 'image':
                        const [firstImage] = config.image;
                        carouselRef?.current?.scroll(0, 0);
                        setImage(firstImage);
                        hideUi();
                        break;
                      case 'video':
                        const [firstVideo] = config.video;
                        carouselRef?.current?.scroll(0, 0);
                        setVideo(firstVideo);
                        hideUi();
                        break;
                    }
                  }}
                />
              </animated.div>
            )
        )}
        <div
          className={styles.close}
          role="button"
          onClick={toggleVisible}
          data-cy="carousel-hamburger"
        >
          <Caret className={visible ? styles.caret : styles.caretOpen} />
        </div>
      </div>
      <div
        className={styles.arrows}
        style={{
          height: carouselRef.current?.clientHeight,
        }}
      >
        <ScrollArrow direction="left" scrollRef={carouselRef} />
        <ScrollArrow direction="right" scrollRef={carouselRef} />
      </div>
      <div ref={carouselRef} className={styles.carousel}>
        {media.type === 'pano' &&
          config.pano
            .filter((p) => p.carousel)
            .map((pano) => (
              <CarouselItem
                key={pano.panoid}
                active={media.type === 'pano' && panoId === pano.panoid}
                title={pano.title}
                thumbnail={pano.thumbnail.url}
                onClick={() => setPano(pano)}
                hasFloorplan={floorPlanPanoIds.includes(pano.panoid)}
              />
            ))}
        {media.type === 'image' &&
          config.image.map((image) => (
            <CarouselItem
              key={image.imageId}
              active={(media as Image).source === image.image}
              title={image.title}
              thumbnail={image.thumbnail.url}
              onClick={() => setImage(image)}
            />
          ))}
        {media.type === 'video' &&
          config.video.map((video) => (
            <CarouselItem
              key={video.videoId}
              active={(media as Video).source === video}
              title={video.title}
              thumbnail={
                video.type === 'tourbuilder' ? video.thumbnail.url : video.thumb
              }
              onClick={() => setVideo(video)}
            />
          ))}
      </div>
    </animated.div>
  );
}
