import React, { CSSProperties } from 'react';
import ReactPlayer from 'react-player/lazy';

import styles from './VideoContent.module.scss';
import { Video } from '../../../../../../types/carousel';

interface VideoComponentProps {
  config: { source: Video; idx: number };
  tourId: string;
  cssVariables?: CSSProperties;
  showArrows: boolean;
}

export default function VideoComponent({
  config: { source },
  tourId,
  cssVariables,
  showArrows,
}: VideoComponentProps) {
  const TOUR_ID_PLACEHOLDER = ':tourId';
  const VIDEO_ID_PLACEHOLDER = ':videoId';

  const TourBuilderClipUrl = `https://stream.tourbuilder.cloud/clips/${TOUR_ID_PLACEHOLDER}/${VIDEO_ID_PLACEHOLDER}/master.m3u8`;

  const url =
    source.type === 'tourbuilder'
      ? TourBuilderClipUrl.replace(TOUR_ID_PLACEHOLDER, tourId).replace(
          VIDEO_ID_PLACEHOLDER,
          source.videoId
        )
      : source.url;

  return (
    <div className={styles.container} style={cssVariables}>
      <ReactPlayer controls url={url} />
    </div>
  );
}
