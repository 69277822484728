import RcSlider from 'rc-slider';

import 'rc-slider/assets/index.css';

import styles from './Slider.module.scss';

interface SliderProps {
  onChange(value: number): void;
  max?: number;
  step?: number;
  text?: string;
  value?: number;
  vertical?: boolean;
}

const Slider = ({
  onChange,
  text,
  max,
  step,
  value,
  vertical,
}: SliderProps) => {
  return (
    <div className={styles.slider} data-cy="slider">
      {text && <div className={styles.sliderText}>{text}</div>}
      <RcSlider
        vertical={vertical}
        onChange={(value) => {
          Array.isArray(value) ? onChange(value[0]) : onChange(value);
        }}
        handleStyle={{
          width: 21,
          height: 21,
          marginLeft: -8,
        }}
        trackStyle={{ marginLeft: -4, width: 13 }}
        railStyle={{ marginLeft: -4, width: 13 }}
        max={max}
        step={step}
        tabIndex={-1}
        value={value}
      />
    </div>
  );
};

export default Slider;
