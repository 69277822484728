import { CSSProperties, useEffect, useState } from 'react';

import useURLParam, {
  OverlayURLParam,
} from '../../hooks/useURLParam/useURLParam';
import { MenuItemContentProvider } from './hooks/useMenuItemContent/useMenuItemContent';
import { ThemeProps } from '../Theme';
import { useAppSelector } from '../../hooks/redux';
import Carousel from './components/Carousel/Carousel';
import FloorPlan from '../../components/FloorPlan/FloorPlan';
import FloorPlanMobileControls from './components/FloorPlanMobileControls/FloorPlanMobileControls';
import GlobalCss from './GlobalCss';
import Pano from './components/Pano/Pano';
import ShareLive from '../../components/ShareLive/ShareLive';
import StartScreen from '../../components/StartScreen/StartScreen';
import useAnalyticsEvents from '../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useIsMobile from '../../hooks/useIsMobile/useIsMobile';
import useIsViewportTooLow from './hooks/useIsViewportTooLow/useIsViewportTooLow';
import useTourFunctionality from '../../hooks/useTourFunctionality/useTourFunctionality';
import useViewer from '../../hooks/useViewer/useViewer';
import Hamburger from './components/Hamburger/Hamburger';
import { getCarouselVisible } from '../../stores/slices/ui';

export default function GreystarSimpleTheme({ theme, tour }: ThemeProps) {
  const isMobile = useIsMobile();
  const isLandscape = useIsMobile('landscape');
  const { isViewportTooLow } = useIsViewportTooLow(tour);
  const [showStart, setShowStart] = useState(true);
  const disableStartScreen =
    useURLParam(OverlayURLParam.START_SCREEN) === 'false';
  const analyticsEvents = useAnalyticsEvents();
  const { logo } = useTourFunctionality(tour, theme);
  const hideRightMenu = useURLParam(OverlayURLParam.HIDE_RIGHT_MENU) === 'true';
  const hideShareLiveURLParam =
    useURLParam(OverlayURLParam.HIDE_SHARE_LIVE) === 'true';
  const hideShareLive = isMobile ? true : hideShareLiveURLParam;
  const { panoId } = useViewer();
  const media = useAppSelector((s) => s.media);
  const visible = useAppSelector(getCarouselVisible);

  useEffect(() => {
    if (!panoId || (tour.startScreen.show && !disableStartScreen)) {
      return;
    }

    analyticsEvents.tourVisit();

    switch (media.type) {
      case 'pano':
        if (panoId) {
          analyticsEvents.panoramaChanged(panoId);
        }
        break;
      case 'image':
        analyticsEvents.imageGalleryView(
          media.imageId,
          media.source.url,
          media.imageTitle
        );
        break;
      case 'video':
        analyticsEvents.videoGalleryView(
          media.source.videoId,
          media.source.title
        );
        break;
    }
  }, [analyticsEvents, disableStartScreen, media, tour, panoId]);

  return (
    <>
      <GlobalCss />
      {!visible && <Hamburger />}
      <MenuItemContentProvider>
        <div
          data-cy="tb-greystar-default-theme"
          id="theme"
          style={
            {
              '--button-border-radius': '10px',
              '--button-border-radius-small': '5px',
              '--controls-border-radius': '50px',
            } as CSSProperties
          }
        >
          <FloorPlan
            configs={tour.floorplan}
            sliders={tour.sliders}
            additionalControls={
              isMobile || isViewportTooLow ? (
                <FloorPlanMobileControls
                  theme={theme}
                  tour={tour}
                  isLandscape={isLandscape || isViewportTooLow}
                />
              ) : null
            }
          />
          <Carousel config={tour.carousel} />
          <Pano hideRightMenu={hideRightMenu} tour={tour} theme={theme} />
          {showStart && !disableStartScreen && (
            <StartScreen
              config={tour.startScreen}
              onStart={() => {
                analyticsEvents.tourVisit();

                switch (media.type) {
                  case 'pano':
                    if (panoId) {
                      analyticsEvents.panoramaChanged(panoId);
                    }
                    break;
                  case 'image':
                    analyticsEvents.imageGalleryView(
                      media.imageId,
                      media.source.url,
                      media.imageTitle
                    );
                    break;
                  case 'video':
                    analyticsEvents.videoGalleryView(
                      media.source.videoId,
                      media.source.title
                    );
                    break;
                }

                setShowStart(false);
              }}
              logo={logo}
              poweredBy={tour.poweredByLCP360}
              showAccessibilityStatement={true}
            />
          )}
          {!hideShareLive && (
            <ShareLive
              clientId={tour.client._id}
              tourId={tour._id}
              themeId={tour.themeId}
            />
          )}
        </div>
      </MenuItemContentProvider>
    </>
  );
}
