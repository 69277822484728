import { Helmet } from 'react-helmet';

const GlobalCss = () => {
  return (
    <Helmet>
      <style type="text/css">{`
        /* tinyscrollbar.js fix */
        .scrollbar.disable {
          display: none;
        }

        /* scrollbar fix */
        .scroll {
          box-sizing: border-box;
          height: 100%;
          overflow: hidden;
          padding: 0 20px 0 0;
          position: relative;
          width: 100%;

          .viewport {
            height: inherit;
            overflow: hidden !important;
            position: relative;
            width: 100%;
          }

          .overview {
            height: inherit;
            left: 0;
            list-style: none;
            margin: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }

          .scrollbar {
            background: #939393;
            border-radius: 15px;
            opacity: 0.3;
            position: absolute;
            right: 0;
            top: 0;
            width: 10px;
            z-index: 1003;
          }

          .thumb {
            background: #000;
            border-radius: 15px;
            box-sizing: border-box;
            cursor: pointer;
            left: 0;
            overflow: hidden;
            position: absolute;
            width: 10px;
          }

          .disable {
            display: none;
          }
        }

        /* floorplan fix */
        .floorplan {
          padding: 20px 20px 0 !important;
        }

        #floorplan-list {
          padding-inline-start: 0;
        }

        .details {
          display: flex;
          justify-content: space-between;
        }

        .floorplan-details > ul {
          padding-inline-start: 0;
        }
        
        .floorplan-details > ul::after {
          content: "";
          display: table;
          clear: both;
        }

        .floorplan-details > ul > li:first-child {
          float: left;
        }

        .unit-detail-img {
          max-width: 300px;
        }

        .floorplan-details a {
          width: auto;
        }
    `}</style>
    </Helmet>
  );
};

export default GlobalCss;
