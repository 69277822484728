import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import {
  setControlsVisible,
  setTemplateUrl,
} from '../../../../stores/slices/ui';
import { MenuItemType } from '../../../../types';
import { useAppDispatch } from '../../../../hooks/redux';
import appendParamsToUrl from '../../../../utils/appendParamsToUrl/appendParamsToUrl';
import ContactMenuContent from './components/ContactMenuContent/ContactMenuContent';
import FullscreenContent from './components/FullscreenContent/FullscreenContent';
import InfoMenuContent from './components/InfoMenuContent/InfoMenuContent';
import Map from '../../../../components/Map/Map';
import MenuDialog from './components/MenuDialog/MenuDialog';
import ShareMenuContent from './components/ShareMenuContent/ShareMenuContent';
import TemplateDrawer from '../../../../components/TemplateDrawer/TemplateDrawer';
import useAnalyticsEvents from '../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useShareLinks from '../../../../hooks/useShareLinks/useShareLinks';
import useSource from '../../../../utils/useSource/useSource';
import useTheme from '../../../../hooks/useTheme/useTheme';
import useTour from '../../../../hooks/useTour/useTour';
import useUTMParams from '../../../../hooks/useUTMParams/useUTMParams';

import styles from './useMenuItemContent.module.scss';
import useNativeShare from '../../../../hooks/useNativeShare/useNativeShare';

interface MenuItemContentContextValue {
  openMenuItem(id: string, type: MenuItemType, conversion: boolean): void;
  openedMenuItemId: string;
}
const MenuItemContentContext = createContext<MenuItemContentContextValue>({
  openMenuItem: () => {},
  openedMenuItemId: '',
});

interface ProviderProps {
  children: ReactNode;
}

export function MenuItemContentProvider({ children }: ProviderProps) {
  const tour = useTour();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { shareLink } = useShareLinks(tour);
  const [fullscreenUrl, setFullscreenUrl] = useState<string>('');
  const [modalContent, setModalContent] = useState<string>('');
  const [openedMenuItemId, setOpenedMenuItemId] = useState('');
  const [showMap, setShowMap] = useState(false);
  const analyticsEvents = useAnalyticsEvents();
  const utmParams = useUTMParams();
  const { sourceId } = useSource();
  const share = useNativeShare();

  const openMenuItem = useCallback(
    (id: string, type: MenuItemType, conversion: boolean) => {
      const button = tour.menuButtons.find(
        (menubutton) => menubutton.id === id
      );

      const buttonText = button?.text!;

      analyticsEvents.menuClick(id, buttonText);

      if (conversion) {
        analyticsEvents.conversion(id, buttonText);
      }

      setFullscreenUrl('');
      setModalContent('');
      setShowMap(false);

      if (id && id === modalContent) {
        setOpenedMenuItemId('');
        setModalContent('');

        return;
      }

      setModalContent('');
      setOpenedMenuItemId(id);

      if (id === 'info') {
        setModalContent('info');

        return;
      }
      if (id === 'contact') {
        setModalContent('contact');
      }

      if (id === 'share') {
        share(shareLink, () => setModalContent('share'));
        return;
      }

      if (id === 'map') {
        setShowMap(!showMap);

        return;
      }

      if (type === 'blank') {
        const menuItem = tour.menuButtons.find(
          (menuButton) => menuButton.id === id
        );

        if (!menuItem || !menuItem.url) return;

        const menuItemUrlWithUtmParams = appendParamsToUrl(menuItem.url, {
          ...utmParams,
          sourceId,
        });

        window.open(menuItemUrlWithUtmParams.toString(), '_blank');

        return;
      }

      if (type === 'extended') {
        dispatch(setTemplateUrl(button!.url));
        setModalContent('extended');

        return;
      }

      if (type === 'iframe') {
        const menuItem = tour.menuButtons.find(
          (menuButton) => menuButton.id === id
        );
        if (!menuItem || !menuItem.url) return;

        const targetUrlWithUtmParams = appendParamsToUrl(menuItem.url, {
          ...utmParams,
          sourceId,
        });

        // Can't open iframe with URL with same origin
        if (window.location.origin === targetUrlWithUtmParams.origin) {
          return;
        }

        if (fullscreenUrl === targetUrlWithUtmParams.toString()) {
          dispatch(setControlsVisible(true));
          return;
        }

        dispatch(setControlsVisible(false));
        setFullscreenUrl(targetUrlWithUtmParams.toString());
      }
    },
    [
      analyticsEvents,
      dispatch,
      fullscreenUrl,
      modalContent,
      shareLink,
      showMap,
      sourceId,
      tour.menuButtons,
      utmParams,
      share,
    ]
  );

  const handleClose = () => {
    setModalContent('');
    setOpenedMenuItemId('');
  };

  return (
    <MenuItemContentContext.Provider
      value={{
        openMenuItem,
        openedMenuItemId,
      }}
    >
      {children}
      {modalContent && tour ? (
        <MenuDialog onClose={handleClose} modal={modalContent}>
          {modalContent === 'info' ? (
            <InfoMenuContent theme={theme} tour={tour} />
          ) : null}
          {modalContent === 'contact' ? (
            <ContactMenuContent tour={tour} />
          ) : null}
          {modalContent === 'share' ? <ShareMenuContent tour={tour} /> : null}
          {modalContent === 'extended' ? (
            <TemplateDrawer tour={tour} className={styles.templateDrawer} />
          ) : null}
        </MenuDialog>
      ) : null}
      {showMap && (
        <Map
          tour={tour}
          theme={theme}
          onClose={() => {
            setShowMap(false);
            setModalContent('');
          }}
        />
      )}
      {fullscreenUrl ? (
        <FullscreenContent
          url={fullscreenUrl}
          onClose={() => {
            setFullscreenUrl('');
            dispatch(setControlsVisible(true));
          }}
        />
      ) : null}
    </MenuItemContentContext.Provider>
  );
}

export default function useMenuItemContent(): MenuItemContentContextValue {
  return useContext(MenuItemContentContext)!;
}
