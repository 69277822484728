import { Theme } from '../../../../types/theme';
import { Tour } from '../../../../types';
import FloorPlan from '../../../../components/FloorPlan/FloorPlan';
import InfoHotspotModal from '../../../../components/InfoHotspotModal/InfoHotspotModal';
import useHotspots from '../../../../hooks/useHotspots/useHotspots';
import useLabels from '../../../../hooks/useLabels/useLabels';
import useMenuItemContent from '../../hooks/useMenuItemContent/useMenuItemContent';
import usePolygons from '../../../../hooks/usePolygons/usePolygons';
import useRecoil from '../../../../hooks/useRecoil/useRecoil';
import useWheelZoom from '../../../../hooks/useWheelZoom/useWheelZoom';
import VideoHotspotModal from '../../../../components/VideoHotspotModal/VideoHotspotModal';
import ViewControls from '../ViewControls/ViewControls';

interface PanoProps {
  hideRightMenu?: boolean;
  tour: Tour;
  theme: Theme;
}

export default function Pano({ hideRightMenu, tour, theme }: PanoProps) {
  useRecoil();
  const [hotspotData, clearHotspotData] = useHotspots(
    tour.hotspots,
    theme.styles.hotspots
  );
  useLabels(tour.labels);
  usePolygons(tour.polygons);
  useWheelZoom();
  const { openMenuItem } = useMenuItemContent();

  return (
    <>
      {!hideRightMenu && (
        <ViewControls
          theme={theme}
          tour={tour}
          onMenuButtonClick={openMenuItem}
        />
      )}
      <FloorPlan configs={tour.floorplan} sliders={tour.sliders} />
      {hotspotData?.type === 'info' ? (
        <InfoHotspotModal data={hotspotData} onClose={clearHotspotData} />
      ) : null}
      {hotspotData?.type === 'video' ? (
        <VideoHotspotModal data={hotspotData} onClose={clearHotspotData} />
      ) : null}
    </>
  );
}
