import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import {
  getTemplateUrl,
  setControlsVisible,
  setTemplateUrl,
} from '../../../../stores/slices/ui';
import { MenuItemType } from '../../../../types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { useFullscreen } from '../../../../hooks/useFullscreen/useFullscreen';
import appendParamsToUrl from '../../../../utils/appendParamsToUrl/appendParamsToUrl';
import ContactMenuContent from './components/ContactMenuContent/ContactMenuContent';
import FullscreenContent from './components/FullscreenContent/FullscreenContent';
import InfoModal from './components/InfoModal/InfoModal';
import Map from '../../../../components/Map/Map';
import MenuDialog from './components/MenuDialog/MenuDialog';
import ShareMenuContent from './components/ShareMenuContent/ShareMenuContent';
import useAnalyticsEvents from '../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useShareLinks from '../../../../hooks/useShareLinks/useShareLinks';
import useSource from '../../../../utils/useSource/useSource';
import useTheme from '../../../../hooks/useTheme/useTheme';
import useTour from '../../../../hooks/useTour/useTour';
import useUTMParams from '../../../../hooks/useUTMParams/useUTMParams';
import useNativeShare from '../../../../hooks/useNativeShare/useNativeShare';

interface MenuItemContentContextValue {
  openMenuItem(id: string, type: MenuItemType, conversion: boolean): void;
  openedMenuItemId: string;
}

const MenuItemContentContext = createContext<MenuItemContentContextValue>({
  openMenuItem: () => {},
  openedMenuItemId: '',
});
interface ProviderProps {
  children: ReactNode;
}

export function MenuItemContentProvider({ children }: ProviderProps) {
  const tour = useTour();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { shareLink } = useShareLinks(tour);
  const [fullscreenUrl, setFullscreenUrl] = useState<string>('');
  const [modalContent, setModalContent] = useState<string>('');
  const [openedMenuItemId, setOpenedMenuItemId] = useState<string>('');
  const [showMap, setShowMap] = useState(false);
  const analyticsEvents = useAnalyticsEvents();
  const utmParams = useUTMParams();
  const { sourceId } = useSource();
  const { toggleFullscreen } = useFullscreen();
  const templateUrl = useAppSelector(getTemplateUrl);
  const share = useNativeShare();

  const openMenuItem = useCallback(
    async (id: string, type: MenuItemType, conversion: boolean) => {
      const button = tour.menuButtons.find(
        (menuButton) => menuButton.id === id
      );
      const buttonText = button?.text!;

      analyticsEvents.menuClick(id, buttonText);

      if (conversion) {
        analyticsEvents.conversion(id, buttonText);
      }

      if (id === 'fullscreen' && type === 'fullscreen') {
        toggleFullscreen();
      }

      if (id && id === openedMenuItemId && type !== 'extended') {
        setOpenedMenuItemId('');
        setModalContent('');

        return;
      }

      setFullscreenUrl('');
      setModalContent('');
      setOpenedMenuItemId(id);

      if (id === 'info' || id === 'map') {
        setModalContent('');
      }

      if (id === 'contact') {
        setModalContent('contact');
      }

      if (id === 'map') {
        setShowMap(!showMap);

        return;
      }

      if (type === 'extended') {
        if (templateUrl === '') {
          dispatch(setTemplateUrl(button!.url));
          return;
        }

        dispatch(setTemplateUrl(''));

        return;
      }

      if (id === 'share') {
        share(shareLink, () => setModalContent('share'));
        return;
      }

      if (type === 'blank') {
        const menuItem = tour.menuButtons.find(
          (menuButton) => menuButton.id === id
        );
        if (!menuItem || !menuItem.url) return;

        const menuItemUrlWithCustomParams = appendParamsToUrl(menuItem.url, {
          ...utmParams,
          sourceId,
        });

        window.open(menuItemUrlWithCustomParams.toString(), '_blank');

        return;
      }

      if (type === 'iframe') {
        const menuItem = tour.menuButtons.find(
          (menuButton) => menuButton.id === id
        );

        if (!menuItem || !menuItem.url) return;

        const targetUrlWithCustomParams = appendParamsToUrl(menuItem.url, {
          ...utmParams,
          sourceId,
        });

        // Can't open iframe with URL with same origin
        if (window.location.origin === targetUrlWithCustomParams.origin) return;

        if (fullscreenUrl === targetUrlWithCustomParams.toString()) {
          dispatch(setControlsVisible(true));
        }

        dispatch(setControlsVisible(false));
        setFullscreenUrl(targetUrlWithCustomParams.toString());
      }
    },
    [
      analyticsEvents,
      dispatch,
      fullscreenUrl,
      openedMenuItemId,
      shareLink,
      showMap,
      sourceId,
      templateUrl,
      toggleFullscreen,
      tour.menuButtons,
      utmParams,
      share,
    ]
  );

  const handleClose = () => {
    setModalContent('');
    setOpenedMenuItemId('');
  };

  return (
    <MenuItemContentContext.Provider value={{ openMenuItem, openedMenuItemId }}>
      {children}
      {modalContent && tour ? (
        <MenuDialog onClose={handleClose}>
          {modalContent === 'contact' ? (
            <ContactMenuContent tour={tour} />
          ) : null}
          {modalContent === 'share' ? <ShareMenuContent tour={tour} /> : null}
        </MenuDialog>
      ) : null}
      {openedMenuItemId === 'info' && tour ? (
        <InfoModal onClose={handleClose} theme={theme} tour={tour} />
      ) : null}
      {openedMenuItemId === 'map' && (
        <Map
          tour={tour}
          theme={theme}
          onClose={() => {
            setShowMap(false);
            setModalContent('');
          }}
        />
      )}
      {fullscreenUrl ? (
        <FullscreenContent
          url={fullscreenUrl}
          onClose={() => {
            setFullscreenUrl('');
            dispatch(setControlsVisible(true));

            handleClose();
          }}
        />
      ) : null}
    </MenuItemContentContext.Provider>
  );
}

export default function useMenuItemContent(): MenuItemContentContextValue {
  return useContext(MenuItemContentContext)!;
}
