import { useMemo } from 'react';

import getSource from '../getSource/getSource';
import useSourceId from '../../hooks/useSourceId/useSourceId';

export default function useSource() {
  const sourceId = useSourceId();
  const source = getSource();

  return useMemo(() => ({ source, sourceId }), [source, sourceId]);
}
