import { useEffect } from 'react';

import useIsMobile from '../useIsMobile/useIsMobile';
import { VIEWER_CONTAINER_ID } from '../../constants';
import useZoom from '../useZoom/useZoom';

export default function useWheelZoom() {
  const isMobile = useIsMobile();
  const { zoomIn, zoomOut } = useZoom();

  useEffect(() => {
    const viewerContainer = document.getElementById(VIEWER_CONTAINER_ID);

    if (!viewerContainer || isMobile) {
      return;
    }

    function zoomInOut(event: WheelEvent) {
      if (event.deltaY < 0) {
        zoomIn();

        return;
      }

      zoomOut();
    }

    viewerContainer.addEventListener('wheel', zoomInOut);

    return () => {
      viewerContainer.removeEventListener('wheel', zoomInOut);
    };
  }, [isMobile, zoomIn, zoomOut]);
}
